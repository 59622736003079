import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import DateFormat from '../common/dateFormat/DateFormat';

import { setModoTela, getListaTanque, getListaCombustivel, getListaUsuario } from './combustivelAjusteActions';

class CombustivelAjusteForm extends Component {

    componentWillMount() {
		
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>

					<Row>
						<Field
							name='datahora_ajuste'
							component={LabelAndInputMask}
							label='Data e Hora *'
							placeholder='Informe a Data e Hora'
							mask="99/99/9999 99:99"
							maskChar=" "
							cols='12 6 4 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_tanque'
							component={Select}
							options={this.props.listaTanque}
							label='Tanque *'
							cols='12 12 4 4'
							placeholder='Selecione o Tanque'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_combustivel'
							component={Select}
							options={this.props.listaCombustivel}
							label='Combustível *'
							cols='12 12 4 4'
							placeholder='Selecione o Combustível'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_responsavel'
							component={Select}
							options={this.props.listaUsuario}
							label='Responsável *'
							cols='12 12 4 4'
							placeholder='Selecione o Responsável'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='quantidade_sistema'
							component={LabelAndInputNumber}
							label='Quantidade no Sistema *' placeholder='0,0000'
							cols='6 6 3 3'
							casas={4}
							readOnly={readOnly} />

						<Field
							name='quantidade_fisica'
							component={LabelAndInputNumber}
							label='Quantidade Física *' placeholder='0,0000'
							cols='6 6 3 3'
							casas={4}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

CombustivelAjusteForm = reduxForm({form: 'combustivelAjusteForm', destroyOnUnmount: false})(CombustivelAjusteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.combustivelAjuste.registro,
	listaTanque: state.combustivelAjuste.listaTanque,
	listaCombustivel: state.combustivelAjuste.listaCombustivel,
	listaUsuario: state.combustivelAjuste.listaUsuario
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaTanque, getListaCombustivel, getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CombustivelAjusteForm);
