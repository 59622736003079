import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './entradaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaFornecedor, getListaUsuario, getListaProduto } from './entradaActions';

class Entrada extends Component {

	state = {
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa);
		this.props.getListaFornecedor(this.props.sessao.id_empresa);
		this.props.getListaUsuario(this.props.sessao.id_empresa);
		this.props.getListaProduto(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Entrada' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>
				<Row>
					<LabelAndInputMask
						label='Data Inicial'
						placeholder='Informe a Data Inicial'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataInicial}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataInicial: data.target.value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />
					<LabelAndInputMask
						label='Data Final'
						placeholder='Informe a Data Final'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataFinal}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataFinal: data.target.value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />
				</Row>
				<input className='form-control'
	                 placeholder='Pesquisar'
	                 type='text'
					 value={this.state.pesquisar}
					 onChange={e => {
						 this.setState({ ...this.state, pesquisar: e.target.value });
					 }} />
				<Table>
					<THead>
						<Tr>
							<Th>Data</Th>
							<Th>Número NFe</Th>
							<Th>Fornecedor</Th>
							<Th>Responsável</Th>
							<Th alignRight>Valor NFe</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.lista.filter(item => {
							return !this.state.pesquisar
								|| (`${DateFormat.formatarDataSqlParaTela(item.data_entrada)} ${item.numero_nfe} ${item.nome_fornecedor} ${item.nome_responsavel} ${String(item.valor_nfe, 0).replace('.', ',')}`).toUpperCase().includes(this.state.pesquisar.toUpperCase());
						}).map(item => (
							<Tr key={item.id}>
								<Td>{DateFormat.formatarDataSqlParaTela(item.data_entrada)}</Td>
								<Td>{item.numero_nfe}</Td>
								<Td>{item.nome_fornecedor}</Td>
								<Td>{item.nome_responsavel}</Td>
								<Td alignRight>{String(item.valor_nfe, 0).replace('.', ',')}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', {
												...item,
												valor_nfe: String(item.valor_nfe, 0).replace('.', ',')
											});
											this.props.initForm({
												...item,
												valor_nfe: String(item.valor_nfe, 0).replace('.', ',')
											});
										}} />
									<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', {
												...item,
												valor_nfe: String(item.valor_nfe, 0).replace('.', ',')
											});
											this.props.initForm({
												...item,
												valor_nfe: String(item.valor_nfe, 0).replace('.', ',')
											});
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.entrada.modoTela,
	lista: state.entrada.lista,
	listaEntradaTipo: state.entrada.listaEntradaTipo,
	filtro: state.entrada.filtro,
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaFornecedor, getListaUsuario, getListaProduto }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Entrada);
