import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormBomba from './bombaForm';

import {
	setModoTela, initForm, salvar,
	setModoBomba, initFormBomba, salvarBomba, excluirBomba, getListaBomba
} from './tanqueActions';

class TanqueForm extends Component {

	state = {}

    componentWillMount() {
		this.props.getListaBomba(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='identificacao'
								component={LabelAndInput}
								label='Identificação *'
								placeholder='Informe a identificação'
								cols='12 10 6 4'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='capacidade'
								component={LabelAndInputNumber}
								label='Capacidade'
								placeholder='Informe a capacidade'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Cancelar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.modoTela == 'cadastro' && this.props.formularioValues.id ? (
					<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 40, marginLeft: 0, marginRight: 0 }}>
						<Grid cols='12 12 12 12'>
							{this.props.modoTelaBomba == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Bomba' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoBomba('cadastro', { id_tanque: this.props.formularioValues.id });
												this.props.initFormBomba({ id_tanque: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Identificação</Th>
												<Th>Casas Decimais</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaBomba.map(item => (
												<Tr key={item.id}>
													<Td>{item.identificacao}</Td>
													<Td>{item.casas_decimais}</Td>
													<Td alignCenter>
														<ButtonTable
															type={'warning'}
															icon={'fa fa-pencil'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoBomba('cadastro', item);
																this.props.initFormBomba(item);
															}} />
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash-o'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoBomba('exclusao', item);
																this.props.initFormBomba(item);
															}} />
													</Td>
												</Tr>
											))}
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaBomba == 'cadastro' ? (
								<FormBomba onSubmit={this.props.salvarBomba} />
							) : (this.props.modoTelaBomba == 'exclusao' ? (
								<FormBomba excluir onSubmit={this.props.excluirBomba} />
							) : null))}
						</Grid>
					</Row>
				) : null}
			</div>
        )
    }


}

TanqueForm = reduxForm({form: 'tanqueForm', destroyOnUnmount: false})(TanqueForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.tanque.modoTela,
	modoTelaBomba: state.tanque.modoTelaBomba,
	listaBomba: state.tanque.listaBomba,
	registro: state.tanque.registro,
	formularioValues: getFormValues('tanqueForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar,
	setModoBomba, initFormBomba, salvarBomba, excluirBomba, getListaBomba
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TanqueForm);
