import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './tanqueForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './tanqueActions';

class Tanque extends Component {

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Tanque' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'descarte' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>
				<Table>
					<THead>
						<Tr>
							<Th>Identificação</Th>
							<Th alignRight>Capacidade</Th>
							<Th>Bombas</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{(this.props.lista).map(item => {

							let bombas = '';
							(item.bombas || []).filter(bomba => bomba && bomba.ativo).forEach((bomba, i) => {
								bombas += `${i > 0 ? ', ' : ''}${bomba.identificacao}`;
							});

							return (
								<Tr key={item.id}>
									<Td>{item.identificacao}</Td>
									<Td alignRight>{String(item.capacidade || 0).replace('.', ',')}</Td>
									<Td>{bombas}</Td>
									<Td alignCenter>
										<ButtonTable
											type={'warning'}
											icon={'fa fa-pencil'}
											event={() => {
												this.props.setModoTela('cadastro', item);
												this.props.initForm(item);
											}} />
										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											event={() => {
												this.props.setModoTela('exclusao', item);
												this.props.initForm(item);
											}} />
									</Td>
								</Tr>
							)
						})}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.tanque.modoTela,
	lista: state.tanque.lista,
	listaTanqueTipo: state.tanque.listaTanqueTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Tanque);
