import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return (dispatch, getState) => {
		dispatch({
	        type: 'COMBUSTIVEL_TRANSFERENCIA_MODO_TELA',
	        payload: {
				modo: modo,
				registro: registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			}
		});
	}
}
export function initForm(registro = { datahora_transferencia: DateFormat.getDataHoraAtual() }, formatarData = true) {
    return (dispatch, getState) => {
		dispatch([
	        initialize('combustivelTransferenciaForm', {
				...registro,
				datahora_transferencia: formatarData ? (registro.datahora_transferencia ? DateFormat.formatarDataHoraSqlParaTela(registro.datahora_transferencia) : null) : registro.datahora_transferencia,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			})
		]);
	}
}

export function setFiltro(filtro) {
    return {
        type: 'COMBUSTIVEL_TRANSFERENCIA_FILTRO',
        payload: filtro
    };
}

export function getLista(id_empresa) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/combustivelTransferencia?id_empresa=${id_empresa}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(getState().combustivelTransferencia.filtro.dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(getState().combustivelTransferencia.filtro.dataFinal)}
			&id_usuario=${getState().combustivelTransferencia.filtro.id_usuario}`)
		.then(resp => {
            dispatch({
				type: 'COMBUSTIVEL_TRANSFERENCIA_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function getListaTanque(id_empresa) {
    const request = axios.get(`${consts.API_URL}/tanque/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_TRANSFERENCIA_TANQUE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaBomba(id_empresa) {
    const request = axios.get(`${consts.API_URL}/bomba/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_TRANSFERENCIA_BOMBA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCombustivel(id_empresa) {
    const request = axios.get(`${consts.API_URL}/combustivel/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_TRANSFERENCIA_COMBUSTIVEL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_TRANSFERENCIA_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/combustivelTransferencia`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_transferencia: registro.datahora_transferencia ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_transferencia) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/combustivelTransferencia?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
