import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';

import { setModoTela, getListaTanque, getListaFornecedor, getListaCombustivel, getListaUsuario } from './combustivelEntradaActions';

class CombustivelEntradaForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>

					<Row>
						<Field
							name='datahora_entrada'
							component={LabelAndInputMask}
							label='Data e Hora *'
							placeholder='Informe a Data e Hora'
							mask="99/99/9999 99:99"
							maskChar=" "
							cols='12 6 4 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_tanque'
							component={Select}
							options={this.props.listaTanque}
							label='Tanque *'
							cols='12 12 4 4'
							placeholder='Selecione o Tanque'
							readOnly={readOnly} />

						<Field
							name='id_fornecedor'
							component={Select}
							options={this.props.listaFornecedor}
							label='Fornecedor *'
							cols='12 12 4 4'
							placeholder='Selecione o Fornecedor'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_combustivel'
							component={Select}
							options={this.props.listaCombustivel}
							label='Combustível *'
							cols='12 12 4 4'
							placeholder='Selecione o Combustível'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_responsavel'
							component={Select}
							options={this.props.listaUsuario}
							label='Responsável *'
							cols='12 12 4 4'
							placeholder='Selecione o Responsável'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade *' placeholder='0,0000'
							cols='6 6 3 3'
							casas={4}
							readOnly={readOnly} />

						<Field
							name='valor'
							component={LabelAndInputNumber}
							label='Valor' placeholder='0,00'
							cols='6 6 3 3'
							casas={2}
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='numero_nfe'
							component={LabelAndInput}
							label='Número NF-e' placeholder='Informe o Número da NF-e'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>

					{this.props.registro && this.props.registro.imagem_nfe ? (
						<Row>
							<div class="col-md-12">
								<label>Foto NF-e</label>
							</div>
							<div class="col-md-12">
								<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.registro.imagem_nfe}`} />
							</div>
						</Row>
					) : null}

					<BoxButtonFooter>
						{this.props.excluir && this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro  ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (!this.props.registro.id || (this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro) ? (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						) : null)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

CombustivelEntradaForm = reduxForm({form: 'combustivelEntradaForm', destroyOnUnmount: false})(CombustivelEntradaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.combustivelEntrada.registro,
	listaTanque: state.combustivelEntrada.listaTanque,
	listaFornecedor: state.combustivelEntrada.listaFornecedor,
	listaCombustivel: state.combustivelEntrada.listaCombustivel,
	listaUsuario: state.combustivelEntrada.listaUsuario,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0]
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaTanque, getListaFornecedor, getListaCombustivel, getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CombustivelEntradaForm);
