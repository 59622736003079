import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaTanque: [],
	listaUsuario: [],
	listaCombustivel: [],
	listaFornecedor: [],
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMBUSTIVEL_ENTRADA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		case 'COMBUSTIVEL_ENTRADA_FILTRO':
			return { ...state, filtro: action.payload };
        case 'COMBUSTIVEL_ENTRADA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'COMBUSTIVEL_ENTRADA_TANQUE_SELECT_LISTADO':
            return { ...state, listaTanque: action.payload.data };
		case 'COMBUSTIVEL_ENTRADA_COMBUSTIVEL_SELECT_LISTADO':
            return { ...state, listaCombustivel: action.payload.data };
		case 'COMBUSTIVEL_ENTRADA_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
		case 'COMBUSTIVEL_ENTRADA_FORNECEDOR_SELECT_LISTADO':
            return { ...state, listaFornecedor: action.payload.data };
        default:
            return state;
    }
}
