import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';

import {
	setModoItem, initFormItem
} from './ordemServicoActions';

class OrdemServicoItemForm extends Component {

	state = {
	}

    componentWillMount() {

    }

	calcularTotal(registro) {

		let quantidade = parseFloat(String(registro.quantidade || '0').replace(',', '.'));
		let valor_hora = parseFloat(String(registro.valor_hora || '0').replace(',', '.'));
		let valor_unitario = parseFloat(String(registro.valor_unitario || '0').replace(',', '.'));
		let valor_km = parseFloat(String(registro.valor_km || '0').replace(',', '.'));
		let valor_diaria = parseFloat(String(registro.valor_diaria || '0').replace(',', '.'));

		let valorTotal = 0;
		valorTotal += valor_hora * (registro.horimetro_fim - registro.horimetro_inicio > 0
			? registro.horimetro_fim - registro.horimetro_inicio : 0);
		valorTotal += valor_unitario * (quantidade > 0 ? quantidade : 0);
		valorTotal += valor_km * (registro.hodometro_fim - registro.hodometro_inicio > 0
			? registro.hodometro_fim - registro.hodometro_inicio : 0);
		valorTotal += valor_diaria * (quantidade > 0 ? quantidade : 0);

		this.props.initFormItem({
			...registro,
			valor_total: valorTotal
		});

	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let isHora = parseFloat(this.props.formularioValues.valor_hora || 0) > 0;
		let isUnitario = parseFloat(this.props.formularioValues.valor_unitario || 0) > 0;
		let isKm = parseFloat(this.props.formularioValues.valor_km || 0) > 0;
		let isDiaria = parseFloat(this.props.formularioValues.valor_diaria || 0) > 0;

        return (
			<ContentCard>
				<ContentCardHeader title='Item' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Select
							name='id_servico'
							options={this.props.listaServico}
							value={this.props.formularioValues.id_servico}
							onChange={value => {
								let servico = this.props.listaServico.filter(item => item.id == value)[0];

								let isHora = servico && parseFloat(servico.valor_hora) > 0;
								let isUnitario = servico && parseFloat(servico.valor_unitario) > 0;
								let isKm = servico && parseFloat(servico.valor_km) > 0;
								let isDiaria = this.props.formularioValues.diaria || false;

								let valor_hora = (isHora && servico ? servico.valor_hora || 0 : 0);
								let valor_unitario = (isUnitario && servico ? servico.valor_unitario || 0 : 0);
								let valor_km = (isKm && servico ? servico.valor_km || 0 : 0);
								let valor_diaria = (isDiaria && servico ? servico.valor_diaria || 0 : 0);

								this.calcularTotal({
									...this.props.formularioValues,
									id_servico: servico ? servico.id : null,
									valor_hora: valor_hora,
									valor_unitario: valor_unitario,
									valor_km: valor_km,
									valor_diaria: valor_diaria
								});

							}}
							label='Serviço'
							cols='12 12 12 12'
							placeholder='Selecione o Serviço'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_equipamento'
							component={Select}
							options={this.props.listaEquipamento}
							label='Equipamento *'
							cols='12 12 12 12'
							placeholder='Selecione o Equipamento'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='diaria'
							component={Select}
							options={this.props.listaDiaria}
							label='Diária *'
							cols='12 12 12 12'
							placeholder='Selecione se é Diária'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='observacao'
							component={LabelAndInput}
							label='Observação'
							placeholder='Informe a Observação'
							cols='12 12 12 12'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='datahora_inicio'
							component={LabelAndInputDateTime}
							label='Data e Hora Inicial'
							placeholder='Informe o início'
							cols='12 6 6 4'
							readOnly={readOnly} />

						<Field
							name='datahora_fim'
							component={LabelAndInputDateTime}
							label='Data e Hora Final'
							placeholder='Informe o final'
							cols='12 6 6 4'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='hodometro_inicio'
							component={LabelAndInputNumber}
							label='Odômetro Inícial'
							placeholder='Informe o Odômetro Inicial'
							cols='12 6 4 2'
							casas={0}
							readOnly={readOnly} />

						<Field
							name='hodometro_fim'
							component={LabelAndInputNumber}
							label='Odômetro Final'
							placeholder='Informe o Odômetro Final'
							cols='12 6 4 2'
							casas={0}
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='horimetro_inicio'
							component={LabelAndInputNumber}
							label='Horímetro Inícial'
							placeholder='Informe o Horímetro Inicial'
							cols='12 6 4 2'
							casas={1}
							readOnly={readOnly} />

						<Field
							name='horimetro_fim'
							component={LabelAndInputNumber}
							label='Horímetro Final'
							placeholder='Informe o Horímetro Final'
							cols='12 6 4 2'
							casas={1}
							readOnly={readOnly} />
					</Row>

					<Row>
						{isUnitario || isDiaria ? (
							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade'
								placeholder='Informe a quantidade'
								cols='12 6 6 4'
								casas={2}
								readOnly={readOnly}
								onChange={data => {

									this.calcularTotal({
										...this.props.formularioValues,
										quantidade:  parseFloat(String(data.target.value || '0').replace(',', '.'))
									});

								}} />
						) : null}

						{isHora ? (
							<Field
								name='valor_hora'
								component={LabelAndInputNumber}
								label='Valor por Hora'
								placeholder='Informe o valor por hora'
								cols='12 6 6 4'
								casas={2}
								readOnly={readOnly}
								onChange={data => {

									this.calcularTotal({
										...this.props.formularioValues,
										valor_hora: parseFloat(String(data.target.value || '0').replace(',', '.'))
									});

								}} />
						) : null}

						{isUnitario ? (
							<Field
								name='valor_unitario'
								component={LabelAndInputNumber}
								label='Valor Unitário'
								placeholder='Informe o valor unitário'
								cols='12 6 6 4'
								casas={2}
								readOnly={readOnly}
								onChange={data => {

									this.calcularTotal({
										...this.props.formularioValues,
										valor_unitario: parseFloat(String(data.target.value || '0').replace(',', '.'))
									});

								}} />
						) : null}

						{isKm ? (
							<Field
								name='valor_km'
								component={LabelAndInputNumber}
								label='Valor Km'
								placeholder='Informe o valor por Km'
								cols='12 6 6 4'
								casas={2}
								readOnly={readOnly}
								onChange={data => {

									this.calcularTotal({
										...this.props.formularioValues,
										valor_km: parseFloat(String(data.target.value || '0').replace(',', '.'))
									});

								}} />
						) : null}

						{isDiaria ? (
							<Field
								name='valor_diaria'
								component={LabelAndInputNumber}
								label='Valor da Diária'
								placeholder='Informe o valor da Diária'
								cols='12 6 6 4'
								casas={2}
								readOnly={readOnly}
								onChange={data => {

									this.calcularTotal({
										...this.props.formularioValues,
										valor_diaria: parseFloat(String(data.target.value || '0').replace(',', '.'))
									});

								}} />
						) : null}

						<Field
							name='valor_total'
							component={LabelAndInputNumber}
							label='Valor Total'
							placeholder='Informe o valor Total'
							cols='12 6 6 4'
							casas={2}
							readOnly={'readOnly'}
							onChange={data => {


							}} />

					</Row>
					
					<Row>
						{this.props.formularioValues && this.props.formularioValues.imagem_acumulador_inicio ? (
							<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
								<div class="col-md-12">
									<label>Acumulador Inicial</label>
								</div>
								<div class="col-md-12">
									<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.formularioValues.imagem_acumulador_inicio}`} />
								</div>
							</div>
						) : null}

						{this.props.formularioValues && this.props.formularioValues.imagem_acumulador_fim ? (
							<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
								<div class="col-md-12">
									<label>Acumulador Final</label>
								</div>
								<div class="col-md-12">
									<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.formularioValues.imagem_acumulador_fim}`} />
								</div>
							</div>
						) : null}
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoItem('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

OrdemServicoItemForm = reduxForm({form: 'ordemServicoItemForm', destroyOnUnmount: false})(OrdemServicoItemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.ordemServico.modoTela,
	listaServico: state.ordemServico.listaServico,
	listaEquipamento: state.ordemServico.listaEquipamento,
	listaUsuario: state.ordemServico.listaUsuario,
	listaDiaria: state.ordemServico.listaDiaria,
	registro: state.ordemServico.registro,
	formularioValues: getFormValues('ordemServicoItemForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoItem, initFormItem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoItemForm);
