import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import DateFormat from '../common/dateFormat/DateFormat';

import {
	setModoItem, getListaProduto, initFormItem, getUltimasCompras
} from './entradaActions';

class EntradaItemForm extends Component {

	state = {
	}

    componentWillMount() {
		this.props.getUltimasCompras(0);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Item' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='id_produto'
							component={Select}
							options={this.props.listaProduto}
							value={this.props.formularioValues.id_produto}
							onChange={(data, value) => {
								this.props.initFormItem({
									...this.props.formularioValues,
									id_produto: value
								});
								this.props.getUltimasCompras(value || 0);
							}}
							label='Produto'
							cols='12 12 8 6'
							placeholder='Selecione o Produto'
							readOnly={readOnly} />
					</Row>

					<Row>

						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade'
							placeholder='Informe a quantidade'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly}
							onChange={data => {
								let quantidade = parseFloat(String(data.target.value || '0').replace(',', '.'));
								let valorUnitario = parseFloat(String(this.props.formularioValues.valor_unitario || '0').replace(',', '.'));
								let total = (quantidade * valorUnitario).toFixed(2);
								this.props.initFormItem({
									...this.props.formularioValues,
									valor_unitario: valorUnitario.toFixed(2),
									valor: total
								});
							}} />
						<Field
							name='valor_unitario'
							component={LabelAndInputNumber}
							label='Valor Unitário'
							placeholder='Informe o valor unitário'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly}
							onChange={data => {
								let quantidade = parseFloat(String(this.props.formularioValues.quantidade || '0').replace(',', '.'));
								let valorUnitario = parseFloat(String(data.target.value || '0').replace(',', '.'));
								let total = (quantidade * valorUnitario).toFixed(2);
								this.props.initFormItem({
									...this.props.formularioValues,
									quantidade: quantidade.toFixed(2),
									valor: total
								});
							}} />

						<Field
							name='valor'
							component={LabelAndInputNumber}
							label='Total'
							placeholder='Informe o total'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly}
							onChange={data => {
								let quantidade = parseFloat(String(this.props.formularioValues.quantidade || '0').replace(',', '.'));
								let valorUnitario = parseFloat(String(this.props.formularioValues.valor_unitario || '0').replace(',', '.'));
								let valor = parseFloat(String(data.target.value || '0').replace(',', '.'));

								if (quantidade) {
									valorUnitario = (valor / quantidade).toFixed(2);
								}

								this.props.initFormItem({
									...this.props.formularioValues,
									quantidade: quantidade,
									valor_unitario: valorUnitario
								});
							}} />
					</Row>

					<Row>
						<Grid cols='12 6 4 3'>
							<div className='form-group'>
								<label>Preço de Custo Atual</label>
								<input className='form-control'
									 placeholder={'0,00'}
									 value={this.props.formularioValues.id_produto && this.props.listaProduto.filter(produto => produto.id == this.props.formularioValues.id_produto)[0] ? String(this.props.listaProduto.filter(produto => produto.id == this.props.formularioValues.id_produto)[0].custo || '0,00').replace('.', ',') : '0,00'}
									 readOnly={true} type={'text'} />
							</div>
						</Grid>

						<div style={{ paddingTop: 22 }}>
							<Field
								name='atualizar_custo'
								component={LabelAndCheckbox}
								label='Atualizar Preço de Custo'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</div>
					</Row>

					<Row>
						<Field
							name='observacao'
							component={LabelAndInput}
							label='Observação'
							placeholder='Informe a Observação'
							cols='12 12 10 8'
							readOnly={readOnly} />
					</Row>

					{this.props.listaUltimasCompras.length > 0 ? (
						<Row>
							<Grid>
								<Table>
									<THead>
										<Tr>
											<Th>Últimas compras</Th>
											<Th>Fornecedor</Th>
											<Th alignRight>Quantidade</Th>
											<Th alignRight>Valor Unitário</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaUltimasCompras.map(item => (
											<Tr key={item.id}>
												<Td>{DateFormat.formatarDataSqlParaTela(item.data_entrada)}</Td>
												<Td>{item.nome_fornecedor}</Td>
												<Td alignRight>{item.quantidade}</Td>
												<Td alignRight>{String(item.valor_unitario, 0).replace('.', ',')}</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>
						</Row>
					) : null}

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoItem('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

EntradaItemForm = reduxForm({form: 'entradaItemForm', destroyOnUnmount: false})(EntradaItemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.entrada.modoTela,
	listaProduto: state.entrada.listaProduto,
	listaUltimasCompras: state.entrada.listaUltimasCompras,
	registro: state.entrada.registro,
	formularioValues: getFormValues('entradaItemForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoItem, getListaProduto, initFormItem, getUltimasCompras }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EntradaItemForm);
