const INITIAL_STATE = {
	registro: {}, modoTela: 'lista', lista: [], listaDimensao: [], listaDesenho: [], listaTipo: [], listaMarca: [],
	listaPneuRecapagem: [], listaPneuAvaria: [], modoTelaRecapagem: 'lista', modoTelaAvaria: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PNEU_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				modoTelaRecapagem: 'lista',
				modoTelaAvaria: 'lista'
			};
        case 'PNEU_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
        case 'PNEU_DIMENSAO_SELECT_LISTADO':
            return { ...state, listaDimensao: action.payload.data };
        case 'PNEU_DESENHO_SELECT_LISTADO':
            return { ...state, listaDesenho: action.payload.data };
        case 'PNEU_TIPO_SELECT_LISTADO':
            return { ...state, listaTipo: action.payload.data };
        case 'PNEU_MARCA_SELECT_LISTADO':
            return { ...state, listaMarca: action.payload.data };
		case 'PNEU_RECAPAGEM_MODO_TELA':
            return { ...state,
				modoTelaRecapagem: action.payload.modo
			};
		case 'PNEU_RECAPAGEM_LISTADO':
            return { ...state, listaPneuRecapagem: action.payload.data };
		case 'PNEU_AVARIA_MODO_TELA':
            return { ...state,
				modoTelaAvaria: action.payload.modo
			};
		case 'PNEU_AVARIA_LISTADO':
            return { ...state, listaPneuAvaria: action.payload.data };
        default:
            return state;
    }
}
