import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaUsuario: [],
	listaTipo: [],
	listaEquipamento: [],
	listaProduto: [],
	listaFornecedor: [],
	listaManutencaoItem: [],
	listaManutencaoAtividade: [],
	modoTelaItem: 'lista',
	modoTelaAtividade: 'lista',
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null,
		id_equipamento: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'MANUTENCAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				modoTelaItem: 'lista',
				modoTelaAtividade: 'lista'
			};
		case 'MANUTENCAO_FILTRO':
			return { ...state, filtro: action.payload };
        case 'MANUTENCAO_LISTADO':
            return { ...state, lista: action.payload.data };
        case 'MANUTENCAO_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
        case 'MANUTENCAO_EQUIPAMENTO_SELECT_LISTADO':
            return { ...state, listaEquipamento: action.payload.data };
        case 'MANUTENCAO_TIPO_SELECT_LISTADO':
            return { ...state, listaTipo: action.payload.data };
		case 'MANUTENCAO_PRODUTO_SELECT_LISTADO':
            return { ...state, listaProduto: action.payload.data };
		case 'MANUTENCAO_FORNECEDOR_SELECT_LISTADO':
            return { ...state, listaFornecedor: action.payload.data };
        case 'MANUTENCAO_ITEM_MODO_TELA':
            return { ...state,
				modoTelaItem: action.payload.modo
			};
		case 'MANUTENCAO_ITEM_LISTADO':
            return { ...state, listaManutencaoItem: action.payload.data };
		case 'MANUTENCAO_ATIVIDADE_MODO_TELA':
            return { ...state,
				modoTelaAtividade: action.payload.modo
			};
		case 'MANUTENCAO_ATIVIDADE_LISTADO':
            return { ...state, listaManutencaoAtividade: action.payload.data };
        default:
            return state;
    }
}
