import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

import {
	initForm, setModoTela, getListaEspecie, getListaEquipamentoEixo, getListaEquipamentoEixoPneu,
	salvarEquipamentoEixo, removerEquipamentoEixo, salvarEquipamentoEixoPneu, removerEquipamentoEixoPneu, getListaCombustivelMedia
} from './equipamentoActions';

class EquipamentoForm extends Component {

    componentWillMount() {
		this.props.getListaEquipamentoEixo(this.props.sessao.id_empresa, this.props.formularioValues.id || -1);
		this.props.getListaEquipamentoEixoPneu(this.props.sessao.id_empresa, this.props.formularioValues.id || -1);
		this.props.getListaCombustivelMedia(this.props.sessao.id_empresa)
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let divPrincipal = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}

		let divEixo = {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		}

		let divEixoOrdem = {
			margin: 10,
			width: 40,
			fontWeight: 'bold'
		}

		let divEixoPneu = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			margin: 2,
			height: 75,
			width: 20,
			borderRadius: 8,
			backgroundColor: '#000',
			color: '#fff',
			fontWeight: 'bold'
		}
		let divEixoSeparador = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: 10,
			width: 24,
			backgroundColor: '#000',
			color: '#fff',
			fontWeight: 'bold'
		}

		let divEixoPneuNovo = {
			margin: 2
		}

		let divEixoPneuAcoes = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: 60
		}

		let divEixoAcoes = {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			marginRight: 80
		}

		let maiorEixo = 0;
		this.props.listaEquipamentoEixo.map(eixo => {
			let quantidade = this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length;
			if (quantidade > maiorEixo) {
				maiorEixo = quantidade;
			}
		});

		let roletes = [];
		for (var i = 0; i <= 10; i++) {
			roletes.push({
				id: i,
				valor: i
			});
		}

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome/Descrição *'
									placeholder='Informe o Nome'
									cols='12 8 6 4'
									readOnly={readOnly} />
								<Field
									name='placa'
									component={LabelAndInput}
									label='Placa *'
									placeholder='Informe a Placa'
									cols='12 4 4 2'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_especie'
									component={Select}
									options={this.props.listaEspecie}
									label='Espécie *'
									cols='12 8 6 4'
									placeholder='Selecione a Espécie'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='hodometro'
									component={LabelAndCheckbox}
									label='Odômetro'
									cols='6 3 2 2'
									readOnly={readOnly} />
								<Field
									name='horimetro'
									component={LabelAndCheckbox}
									label='Horímetro'
									cols='6 3 2 2'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='capacidade_tanque'
									component={LabelAndInputNumber}
									label='capacidade do Tanque' placeholder='0,00'
									cols='6 6 3 3'
									casas={2}
									readOnly={readOnly} />
							</Row>
							<Row>
								<Grid cols="12 12 8 6">
									<Table>
										<THead>
											<Tr>
												<Th>Combustível</Th>
												<Th alignCenter style={{ width: 60 }}>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.formularioValues.combustiveis.map(item => (
												<Tr key={item.id}>
													<Td>
														<select
															className="form-control"
															value={item.id_combustivel}
															onChange={data => {
																let lista = this.props.formularioValues.combustiveis.map(combustivel => {
																	if (item.id == combustivel.id) {
																		return {
																		   ...combustivel,
																		   id_combustivel: data.target.value
																		}
																	}
																	return combustivel;
																});
																this.props.initForm({
																	...this.props.formularioValues,
																	combustiveis: lista
																});
															}}>
															<option value="0">Selecione o Combustível</option>
															{this.props.listaCombustivelMedia.map(opt => {
																return (
																	<option
																		key={opt.id}
																		value={opt.id}>{opt.valor}</option>
																);
															})}
														</select>
													</Td>
													<Td alignCenter>
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash-o'}
															event={() => {
																let lista = this.props.formularioValues.combustiveis.filter(combustivel => combustivel.id != item.id);
																this.props.initForm({
																	...this.props.formularioValues,
																	combustiveis: lista
																});
															}} />
													</Td>
												</Tr>
											))}
											<Tr>
												<Td alignCenter colspan={6} onClick={() => {
													let lista = this.props.formularioValues.combustiveis;
													lista.push({
														id: new Date().getTime(),
														id_combustivel: 0
													});
													this.props.initForm({
														...this.props.formularioValues,
														combustiveis: lista
													});
													this.setState({...this.state});
												}}><i class='fa fa-plus' aria-hidden='true' style={{ fontSize: 14 }}></i><label style={{ fontSize: 16, marginLeft: 5 }}>Clique aqui para adicionar</label></Td>
											</Tr>
										</TBody>
									</Table>
								</Grid>
							</Row>


							<Row>
								<Field
									name='reboque'
									component={LabelAndCheckbox}
									label='Reboque'
									cols='6 3 2 2'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='esteira'
									component={LabelAndCheckbox}
									label='Esteira'
									cols='6 3 2 2'
									readOnly={readOnly} />
							</Row>
							{this.props.formularioValues.esteira ? (
								<Row>
									<tr>
										<td colspan={2}>
											<Grid cols='12 12 12 12'>
												<label>Roletes</label>
											</Grid>
										</td>
									</tr>
									<tr>
										<td>
											<Field
												name='esteira_rolete_esquerda_superior'
												component={Select}
												options={roletes}
												label='Superior Esquerdo'
												cols='12 12 12 12'
												placeholder="Selecione"
												readOnly={readOnly} />
										</td>
										<td>
											<Field
												name='esteira_rolete_direita_superior'
												component={Select}
												options={roletes}
												label='Superior Direito'
												cols='12 12 12 12'
												placeholder="Selecione"
												readOnly={readOnly} />
										</td>
									</tr>
									<tr>
										<td>
											<Field
												name='esteira_rolete_esquerda_inferior'
												component={Select}
												options={roletes}
												label='Inferior Esquerdo'
												cols='12 12 12 12'
												placeholder="Selecione"
												readOnly={readOnly} />
										</td>
										<td>
											<Field
												name='esteira_rolete_direita_inferior'
												component={Select}
												options={roletes}
												label='Inferior Direito'
												cols='12 12 12 12'
												placeholder="Selecione"
												readOnly={readOnly} />
										</td>
									</tr>
								</Row>
							) : null}
						</Row>
						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Cancelar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.formularioValues.id && !this.props.formularioValues.esteira ? (
					<ContentCard style={{ marginBottom: 80 }}>
						<ContentCardHeader title='Configuração de eixos e pneus' />
						<div style={divPrincipal}>
							<label style={{ fontSize: 18, marginBottom: 20, marginRight: 140 }}>Dianteira</label>
							{this.props.listaEquipamentoEixo.map(eixo => {
								let desenhoEixo = [];
								desenhoEixo.push(1);
								desenhoEixo.push(2);
								for (var i = this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length; i < maiorEixo; i++) {
									desenhoEixo.push(i);
								}

								return (
									<div style={divEixo}>
										{this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).map((pneu, i) => {
											if (i < this.props.listaEquipamentoEixoPneu.filter(pneuItem => pneuItem.id_equipamento_eixo === eixo.id).length / 2) {
												return (
													<div style={divEixoPneu}></div>
												);
											} else {
												return null;
											}
										})}
										{desenhoEixo.map(itemDesenhoEixo => {
											return (
												<div style={divEixoSeparador}></div>
											);
										})}
										{this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).map((pneu, i) => {
											if (i >= this.props.listaEquipamentoEixoPneu.filter(pneuItem => pneuItem.id_equipamento_eixo === eixo.id).length / 2) {
												return (
													<div style={divEixoPneu}></div>
												);
											} else {
												return null;
											}
										})}
										<div style={divEixoPneuAcoes}>
											<a class='btn btn-success'
												style={divEixoPneuNovo}
												onClick={() => {
													this.props.salvarEquipamentoEixoPneu({
														id_equipamento: this.props.formularioValues.id,
														id_equipamento_eixo: eixo.id,
														ordem: this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length + 1
													});
												}}>
												<i class='fa fa-plus'></i>
											</a>
											{this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length > 0 ? (
												<a class='btn btn-danger'
													style={divEixoPneuNovo}
													onClick={() => {
														this.props.removerEquipamentoEixoPneu({
															...this.props.listaEquipamentoEixoPneu
																.filter(pneu => pneu.id_equipamento_eixo === eixo.id)[this.props.listaEquipamentoEixoPneu
																	.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length - 1],
															id_equipamento: this.props.formularioValues.id
														});
													}}>
													<i class='fa fa-minus'></i>
												</a>
											) : null}
										</div>
										<div style={{ width: 80 }}>
											<div className='form-group checkbox'>
									            <label style={{ fontWeight: 'bold' }}>
													<input type='checkbox'
														 checked={eixo.tracao}
														 onChange={(data) => {
															 this.props.salvarEquipamentoEixo({
																 ...eixo,
					 											id_equipamento: this.props.formularioValues.id,
																tracao: !eixo.tracao
					 										});
														 }} />
													Tração
												</label>
									        </div>
										</div>
									</div>
								);
							})}
							<div style={divEixoAcoes}>
								<a class='btn btn-success'
									style={{ margin: 2 }}
									onClick={() => {
										this.props.salvarEquipamentoEixo({
											id_equipamento: this.props.formularioValues.id,
											ordem: this.props.listaEquipamentoEixo.length + 1,
											tracao: false
										});
									}}>
									<i class='fa fa-plus'></i>
								</a>
								{this.props.listaEquipamentoEixo.length > 0 ? (
									<a class='btn btn-danger'
										style={{ margin: 2 }}
										onClick={() => {
											this.props.removerEquipamentoEixo({
												...this.props.listaEquipamentoEixo[this.props.listaEquipamentoEixo.length - 1],
												id_equipamento: this.props.formularioValues.id
											});
										}}>
										<i class='fa fa-minus'></i>
									</a>
								) : null}
							</div>
						</div>
					</ContentCard>
				) : null}
			</div>
        )
    }


}

EquipamentoForm = reduxForm({form: 'equipamentoForm', destroyOnUnmount: false})(EquipamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.equipamento.registro,
	listaEspecie: state.equipamento.listaEspecie,
	listaEquipamentoEixo: state.equipamento.listaEquipamentoEixo,
	listaEquipamentoEixoPneu: state.equipamento.listaEquipamentoEixoPneu,
	listaCombustivelMedia: state.equipamento.listaCombustivelMedia,
	formularioValues: getFormValues('equipamentoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	initForm, setModoTela, getListaEspecie, getListaEquipamentoEixo, getListaEquipamentoEixoPneu,
	salvarEquipamentoEixo, removerEquipamentoEixo, salvarEquipamentoEixoPneu, removerEquipamentoEixoPneu, getListaCombustivelMedia
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EquipamentoForm);
