import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormItem from './ordemServicoItemForm';

import {
	setModoTela, initForm,
	setModoItem, initFormItem, salvarItem, excluirItem, getListaItem,
	reabrirOrdemServico, encerrarOrdemServico, cancelarOrdemServico
} from './ordemServicoActions';

class OrdemServicoForm extends Component {

	state = {}

    componentWillMount() {
		this.props.getListaItem(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir || this.props.formularioValues.datahora_fechamento || this.props.formularioValues.datahora_cancelamento ? 'readOnly' : '';

		let totalItems = 0;

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>

							<Field
								name='data_agendamento'
								component={LabelAndInputMask}
								label='Data Agendamento *'
								placeholder='Informe a Data'
								mask="99/99/9999"
								maskChar=" "
								cols='12 6 4 2'
								readOnly={readOnly} />

							{this.props.formularioValues.id ? (
								<Field
									name='numero'
									component={LabelAndInput}
									label='Número*'
									placeholder='Informe o número'
									cols='12 6 4 3'
									readOnly='readOnly' />
							) : null}

							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente}
								label='Cliente *'
								cols='12 12 12 12'
								placeholder='Selecione o cliente'
								readOnly={readOnly} />

							{!this.props.formularioValues.id_cliente ? (
								<Field
									name='nome_cliente'
									component={LabelAndInput}
									label='Nome do cliente'
									placeholder='Informe o nome do cliente'
									cols='12 12 12 12'
									readOnly={readOnly} />
							) : null}

							<Field
								name='id_operador'
								component={Select}
								options={this.props.listaUsuario}
								label='Operador *'
								cols='12 12 12 12'
								placeholder='Selecione o Operador'
								readOnly={readOnly} />
						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									disabled={readOnly == 'readOnly'}
									icon={'fa fa-check'} />
							)}
							
							{!this.props.excluir && this.props.formularioValues.id
								&& !this.props.formularioValues.datahora_fechamento && !this.props.formularioValues.datahora_cancelamento ? (
								<Button
									text='Encerrar Ordem de Serviço'
									type={'primary'}
									icon={'fa fa-check-square-o'}
									disabled={readOnly == 'readOnly'}
									event={() => this.props.encerrarOrdemServico(this.props.formularioValues.id)} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro
								&& !this.props.excluir && this.props.formularioValues.id
								&& this.props.formularioValues.datahora_fechamento && !this.props.formularioValues.datahora_cancelamento ? (
								<Button
									text='Reabrir Ordem de Serviço'
									type={'primary'}
									icon={'fa fa-check-square-o'}
									event={() => this.props.reabrirOrdemServico(this.props.formularioValues.id)} />
							) : null}
							{!this.props.excluir && this.props.formularioValues.id
								&& !this.props.formularioValues.datahora_fechamento && !this.props.formularioValues.datahora_cancelamento ? (
								<Button
									text='Cancelar Ordem de Serviço'
									type={'danger'}
									icon={'fa fa-ban'}
									disabled={readOnly == 'readOnly'}
									event={() => this.props.cancelarOrdemServico(this.props.formularioValues.id)} />
							) : null}
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.modoTela == 'cadastro' && this.props.formularioValues.id ? (
					<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 40, marginLeft: 0, marginRight: 0 }}>
						<Grid cols='12 12 12 12'>
							{this.props.modoTelaItem == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Item' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoItem('cadastro', { id_ordem_servico: this.props.formularioValues.id });
												this.props.initFormItem({ id_ordem_servico: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Serviço</Th>
												<Th>Equipamento</Th>
												<Th alignRight>Total</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaOrdemServicoItem.map(item => {

												totalItems += parseFloat(item.valor_total || 0);

												return (
													<Tr key={item.id}>
														<Td>{item.nome_servico}</Td>
														<Td>{item.nome_equipamento}</Td>
														<Td alignRight>{String(item.valor_total).replace('.', ',')}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'warning'}
																icon={'fa fa-pencil'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.setModoItem('cadastro', item);
																	this.props.initFormItem(item);
																}} />
															<ButtonTable
																type={'danger'}
																icon={'fa fa-trash-o'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.setModoItem('exclusao', item);
																	this.props.initFormItem(item);
																}} />
														</Td>
													</Tr>
												);
											})}
											{/*Total*/}
											<Tr>
												<Td>Total</Td>
												<Td></Td>
												<Td alignRight>{totalItems.toFixed(4).replace('.', ',')}</Td>
												<Td alignCenter>
												</Td>
											</Tr>
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaItem == 'cadastro' ? (
								<FormItem onSubmit={this.props.salvarItem} />
							) : (this.props.modoTelaItem == 'exclusao' ? (
								<FormItem excluir onSubmit={this.props.excluirItem} />
							) : null))}
						</Grid>
					</Row>
				) : null}
			</div>
        )
    }


}

OrdemServicoForm = reduxForm({form: 'ordemServicoForm', destroyOnUnmount: false})(OrdemServicoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.ordemServico.modoTela,
	listaCliente: state.ordemServico.listaCliente,
	listaEquipamento: state.ordemServico.listaEquipamento,
	listaUsuario: state.ordemServico.listaUsuario,
	modoTelaItem: state.ordemServico.modoTelaItem,
	listaOrdemServicoItem: state.ordemServico.listaOrdemServicoItem,
	registro: state.ordemServico.registro,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0],
	formularioValues: getFormValues('ordemServicoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm,
	setModoItem, initFormItem, salvarItem, excluirItem, getListaItem,
	reabrirOrdemServico, encerrarOrdemServico, cancelarOrdemServico
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoForm);
