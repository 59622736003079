import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import DateFormat from '../common/dateFormat/DateFormat';

import { initForm, setModoTela, getListaTanque, getListaBomba, getListaCombustivel, getListaUsuario, getListaEquipamento, getListaFornecedor } from './combustivelSaidaActions';

class CombustivelSaidaForm extends Component {

    componentWillMount() {
		
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaCombustivel = this.props.listaCombustivel.filter(combustivel => {
			let equipamento = this.props.listaEquipamento.filter(equipamento => equipamento.id == this.props.formularioValues.id_equipamento)[0];
			if (equipamento && equipamento.combustiveis && equipamento.combustiveis.length > 0) {
				return equipamento.combustiveis.filter(liberado => liberado.id_combustivel == combustivel.id_combustivel_media).length > 0;
			} else {
				return true;
			}
		});

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>

					<Row>
						<Field
							name='datahora_saida'
							component={LabelAndInputMask}
							label='Data e Hora *'
							placeholder='Informe a Data e Hora'
							mask="99/99/9999 99:99"
							maskChar=" "
							cols='12 6 4 2'
							readOnly={readOnly} />
					</Row>
					<Row style={{ marginBottom: 10 }}>
						<Grid cols='12 12 12 12'>
							<div class="btn-group">
								<button type='button'
									class={`btn btn-${this.props.formularioValues.interno ? 'success' : 'default'}`}
									onClick={() => {
										this.props.initForm({
											...this.props.formularioValues,
											interno: true,
											id_fornecedor: null,
											valor: 0
										}, false);
									}}
									>Interno - Tanque</button>
								<button type='button'
									class={`btn btn-${this.props.formularioValues.interno ? 'default' : 'success'}`}
									onClick={() => {
										this.props.initForm({
											...this.props.formularioValues,
											interno: false,
											id_tanque: null,
											id_bomba: null
										}, false);
									}}
									>Externo - Posto</button>
							</div>
						</Grid>
					</Row>
					{this.props.formularioValues.interno ? (
						<Row>
							<Select
								name='id_tanque'
								options={this.props.listaTanque}
								label='Tanque *'
								cols='12 12 4 4'
								placeholder='Selecione o Tanque'
								readOnly={readOnly}
								value={this.props.formularioValues.id_tanque}
								onChange={(value) => {
									let tanque = value ? this.props.listaTanque.filter(tanque => tanque.id == value)[0] : null;
									let bomba = tanque ? this.props.listaBomba.filter(bomba => bomba.id_tanque == value)[0] : null;
									let combustivel = tanque ? this.props.listaCombustivel.filter(combustivel => combustivel.id == tanque.id_combustivel)[0] : null;

									this.props.initForm({
										...this.props.formularioValues,
										id_tanque: tanque ? tanque.id : null,
										id_bomba: bomba ? bomba.id : null,
										id_combustivel: combustivel ? combustivel.id : null
									}, false);
								}} />

							<Field
								name='id_bomba'
								component={Select}
								options={this.props.listaBomba.filter(bomba => bomba.id_tanque == this.props.formularioValues.id_tanque)}
								label='Bomba *'
								cols='12 12 4 4'
								placeholder='Selecione a Bomba'
								readOnly={readOnly} />
						</Row>
					) : (
						<Row>
							<Field
								name='id_fornecedor'
								component={Select}
								options={this.props.listaFornecedor}
								label='Fornecedor *'
								cols='12 12 4 4'
								placeholder='Selecione o Fornecedor'
								readOnly={readOnly} />
						</Row>
					)}
					<Row>
						<Field
							name='id_equipamento'
							component={Select}
							options={this.props.listaEquipamento.filter(equipamento => !equipamento.reboque)}
							label='Equipamento *'
							cols='12 12 4 4'
							placeholder='Selecione o Equipamento'
							readOnly={readOnly} />

						<Field
							name='id_combustivel'
							component={Select}
							options={listaCombustivel}
							label='Combustível *'
							cols='12 12 4 4'
							placeholder='Selecione o Combustível'
							readOnly={readOnly} />
					</Row>
					<Row>
						{this.props.listaEquipamento.filter(equipamento => equipamento.id == this.props.formularioValues.id_equipamento)[0]
							&& this.props.listaEquipamento.filter(equipamento => equipamento.id == this.props.formularioValues.id_equipamento)[0].hodometro ? (
								<Field
									name='hodometro'
									component={LabelAndInputNumber}
									label='Odômetro' placeholder='0'
									cols='6 6 3 3'
									casas={0}
									readOnly={readOnly} />
						) : null}

						{this.props.listaEquipamento.filter(equipamento => equipamento.id == this.props.formularioValues.id_equipamento)[0]
							&& this.props.listaEquipamento.filter(equipamento => equipamento.id == this.props.formularioValues.id_equipamento)[0].horimetro ? (
								<Field
									name='horimetro'
									component={LabelAndInputNumber}
									label='Horímetro' placeholder='0,0'
									cols='6 6 3 3'
									casas={1}
									readOnly={readOnly} />
						) : null}
					</Row>
					<Row>
						<Field
							name='id_responsavel'
							component={Select}
							options={this.props.listaUsuario}
							label='Responsável *'
							cols='12 12 4 4'
							placeholder='Selecione o Responsável'
							readOnly={readOnly} />

						<Field
							name='id_operador'
							component={Select}
							options={this.props.listaUsuario}
							label='Operador *'
							cols='12 12 4 4'
							placeholder='Selecione o Operador'
							readOnly={readOnly} />
					</Row>
					{this.props.formularioValues.interno ? (
						<Row>
							<Field
								name='acumulador_bomba_inicial'
								component={LabelAndInputNumber}
								label='Acumulador Bomba Inicial' placeholder='0,0000'
								cols='6 6 3 3'
								casas={this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba)[0]
									? this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba)[0].casas_decimais
									: 4}
								readOnly={readOnly} />

							<Field
							name='acumulador_bomba_final'
							component={LabelAndInputNumber}
							label='Acumulador Bomba Final' placeholder='0,0000'
							cols='6 6 3 3'
							casas={this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba)[0]
								? this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba)[0].casas_decimais
								: 4}
							readOnly={readOnly} />
						</Row>
					) : null}
					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade *' placeholder='0,0000'
							cols='6 6 3 3'
							casas={this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba)[0]
								? this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba)[0].casas_decimais
								: 4}
							readOnly={readOnly} />
						{!this.props.formularioValues.interno ? (
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor *' placeholder='0,00'
								cols='6 6 3 3'
								casas={2}
								readOnly={readOnly} />
						) : null}
					</Row>
					<Row>
						<Field
							name='completou'
							component={LabelAndCheckbox}
							label='Completou o Tanque'
							cols='12 12 4 4'
							placeholder='Selecione se completou o tanque'
							readOnly={readOnly} />
					</Row>

					<Row>
					{this.props.registro && this.props.registro.imagem_hodometro ? (
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
							<div class="col-md-12">
								<label>Odômetro</label>
							</div>
							<div class="col-md-12">
								<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.registro.imagem_hodometro}`} />
							</div>
						</div>
					) : null}

					{this.props.registro && this.props.registro.imagem_horimetro ? (
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
							<div class="col-md-12">
								<label>Horímetro</label>
							</div>
							<div class="col-md-12">
								<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.registro.imagem_horimetro}`} />
							</div>
						</div>
					) : null}

					{this.props.registro && this.props.registro.imagem_bomba ? (
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
							<div class="col-md-12">
								<label>Bomba</label>
							</div>
							<div class="col-md-12">
								<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.registro.imagem_bomba}`} />
							</div>
						</div>
					) : null}

					{this.props.registro && this.props.registro.imagem_cupom ? (
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
							<div class="col-md-12">
								<label>Cupom</label>
							</div>
							<div class="col-md-12">
								<img style={{ marginTop: 0, height: 300 }} src={`data:imagem/png;base64,${this.props.registro.imagem_cupom}`} />
							</div>
						</div>
					) : null}
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

CombustivelSaidaForm = reduxForm({form: 'combustivelSaidaForm', destroyOnUnmount: false})(CombustivelSaidaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.combustivelSaida.registro,
	listaTanque: state.combustivelSaida.listaTanque,
	listaBomba: state.combustivelSaida.listaBomba,
	listaCombustivel: state.combustivelSaida.listaCombustivel,
	listaUsuario: state.combustivelSaida.listaUsuario,
	listaEquipamento: state.combustivelSaida.listaEquipamento,
	listaFornecedor: state.combustivelSaida.listaFornecedor,
	formularioValues: getFormValues('combustivelSaidaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ initForm, setModoTela, getListaTanque, getListaBomba, getListaCombustivel, getListaUsuario, getListaEquipamento, getListaFornecedor }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CombustivelSaidaForm);
