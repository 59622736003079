import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return (dispatch, getState) => {
		dispatch({
	        type: 'ENTRADA_MODO_TELA',
	        payload: {
				modo: modo,
				registro: registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			}
		});
	}
}
export function initForm(registro = { data_entrada: DateFormat.getDataAtual() }) {
    return (dispatch, getState) => {
		dispatch([
	        initialize('entradaForm', {
				...registro,
				data_entrada: registro.data_entrada ? DateFormat.formatarDataSqlParaTela(registro.data_entrada) : null,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			})
		]);
	}
}

export function setFiltro(filtro) {
    return {
        type: 'ENTRADA_FILTRO',
        payload: filtro
    };
}

export function getLista(id_empresa) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/entrada?id_empresa=${id_empresa}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(getState().entrada.filtro.dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(getState().entrada.filtro.dataFinal)}`)
		.then(resp => {
            dispatch({
				type: 'ENTRADA_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
	}
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ENTRADA_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/fornecedor/listarselect?id_empresa=${id_empresa}&peca=true`);
    return {
        type: 'ENTRADA_FORNECEDOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produto/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ENTRADA_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getUltimasCompras(id_produto) {
    const request = axios.get(`${consts.API_URL}/entradaItem/listarUltimasCompras?id_produto=${id_produto}`);
    return {
        type: 'ENTRADA_ULTIMAS_COMPRAS_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/entrada`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			data_entrada: registro.data_entrada ? DateFormat.formatarDataTelaParaSql(registro.data_entrada) : null,
			id_fornecedor: registro.id_fornecedor && registro.id_fornecedor != 0 ? registro.id_fornecedor : null,
			id_responsavel: registro.id_responsavel && registro.id_responsavel != 0 ? registro.id_responsavel : null
		}).then(resp => {
			if (registro.id) {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			} else {
				axios.get(`${consts.API_URL}/entrada?id=${resp.data}`).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
    				dispatch(getLista(getState().auth.usuario.id_empresa));
					dispatch(initForm({
						...resp.data[0],
						valor_nfe: String(resp.data[0].valor_nfe, 0).replace('.', ',')
					}));
				});
			}
			dispatch(getUltimasCompras(0));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/entrada?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Item
export function setModoItem(modo, registro = {}) {
    return {
        type: 'ENTRADA_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormItem(registro = {}) {
    return [
        initialize('entradaItemForm', {
			...registro,
			quantidade: parseFloat(registro.quantidade || 0).toFixed(4).replace('.', ','),
			valor_unitario: parseFloat(registro.valor_unitario || 0).toFixed(4).replace('.', ','),
			valor: parseFloat(registro.valor || 0).toFixed(4).replace('.', ',')
		})
    ];
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/entradaItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {

			if (registro.atualizar_custo) {
				axios.get(`${consts.API_URL}/produto?id_empresa=${getState().auth.usuario.id_empresa}`)
				.then(resultado => {

					let produto = resultado.data.filter(item => item.id == registro.id_produto)[0];

					if (produto) {
						axios.post(`${consts.API_URL}/produto`, {
							...produto,
							custo: registro.valor_unitario,
							id_empresa: getState().auth.usuario.id_empresa
						}).then(resp => {

						}).catch(e => {
							setErroAPI(e);
						});
					}
				});
			}

			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(getState().auth.usuario.id_empresa, registro.id_entrada));
			dispatch(setModoItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/entradaItem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_empresa, registro.id_entrada));
			dispatch(setModoItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaItem(id_empresa, id_entrada) {
    const request = axios.get(`${consts.API_URL}/entradaItem?id_empresa=${id_empresa}&id_entrada=${id_entrada}`);
    return {
        type: 'ENTRADA_ITEM_LISTADO',
        payload: request
    };
}
