import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return (dispatch, getState) => {
		dispatch({
	        type: 'COMBUSTIVEL_AJUSTE_MODO_TELA',
	        payload: {
				modo: modo,
				registro: registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			}
		});
	}
}
export function initForm(registro = { datahora_ajuste: DateFormat.getDataHoraAtual() }) {
    return (dispatch, getState) => {
		dispatch([
	        initialize('combustivelAjusteForm', {
				...registro,
				datahora_ajuste: registro.datahora_ajuste ? DateFormat.formatarDataHoraSqlParaTela(registro.datahora_ajuste) : null,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			})
		]);
	}
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/combustivelAjuste?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_AJUSTE_LISTADO',
        payload: request
    };
}

export function getListaTanque(id_empresa) {
    const request = axios.get(`${consts.API_URL}/tanque/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_AJUSTE_TANQUE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCombustivel(id_empresa) {
    const request = axios.get(`${consts.API_URL}/combustivel/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_AJUSTE_COMBUSTIVEL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_AJUSTE_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/combustivelAjuste`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_ajuste: registro.datahora_ajuste ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_ajuste) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/combustivelAjuste?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
