const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaUsuarioTipo: [], usuarioTipoSelecionado: 0 };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'USUARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'USUARIO_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
        case 'USUARIO_TIPO_SELECT_LISTADO':
            return { ...state, listaUsuarioTipo: action.payload.data };
        case 'USUARIO_TIPO_VALUE_CHANGED':
            return { ...state, usuarioTipoSelecionado: action.payload };
        default:
            return state;
    }
}
