import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'EQUIPAMENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { hodometro: false, horimetro: false, combustiveis: [] }) {
    return [
        initialize('equipamentoForm', registro)
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/equipamento?id_empresa=${id_empresa}`);
    return {
        type: 'EQUIPAMENTO_LISTADO',
        payload: request
    };
}

export function getListaEquipamentoEixo(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/equipamentoEixo?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'EQUIPAMENTO_EIXO_LISTADO',
        payload: request
    };
}

export function getListaEquipamentoEixoPneu(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/equipamentoEixoPneu?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'EQUIPAMENTO_EIXO_PNEU_LISTADO',
        payload: request
    };
}

export function getListaEspecie(id_empresa) {
    const request = axios.get(`${consts.API_URL}/especie/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'EQUIPAMENTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCombustivelMedia(id_empresa) {
    const request = axios.get(`${consts.API_URL}/combustivelMedia/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_MEDIA_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/equipamento`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));

			if (registro.id) {
				dispatch(setModoTela('lista'));
			} else {
				registro.id = resp.data;
				dispatch(setModoTela('cadastro', registro));
				dispatch(initForm(registro));
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/equipamento?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvarEquipamentoEixo(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/equipamentoEixo`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEquipamentoEixo(getState().auth.usuario.id_empresa, registro.id_equipamento));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function removerEquipamentoEixo(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/equipamentoEixo?id=${registro.id}`).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEquipamentoEixo(getState().auth.usuario.id_empresa, registro.id_equipamento));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvarEquipamentoEixoPneu(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/equipamentoEixoPneu`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEquipamentoEixoPneu(getState().auth.usuario.id_empresa, registro.id_equipamento));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function removerEquipamentoEixoPneu(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/equipamentoEixoPneu?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEquipamentoEixoPneu(getState().auth.usuario.id_empresa, registro.id_equipamento));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
