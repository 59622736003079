import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormItem from './entradaItemForm';

import {
	setModoTela, initForm, salvar, getListaFornecedor, getListaUsuario,
	setModoItem, initFormItem, salvarItem, excluirItem, getListaItem
} from './entradaActions';

class EntradaForm extends Component {

	state = {}

    componentWillMount() {
		this.props.getListaItem(this.props.sessao.id_empresa, this.props.formularioValues ? this.props.formularioValues.id || 0 : 0);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='data_entrada'
								component={LabelAndInputMask}
								label='Data da Entrada *'
								placeholder='Informe a Data'
								mask="99/99/9999"
								maskChar=" "
								cols='12 5 3 2'
								readOnly={readOnly} />

							<Field
								name='numero_nfe'
								component={LabelAndInput}
								label='Número da NFe'
								placeholder='Informe o Número da NFe'
								cols='12 7 4 2'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_fornecedor'
								component={Select}
								options={this.props.listaFornecedor}
								label='Fornecedor *'
								cols='12 6 5 4'
								placeholder='Selecione o Fornecedor'
								readOnly={readOnly} />

							<Field
								name='id_responsavel'
								component={Select}
								options={this.props.listaUsuario}
								label='Responsável *'
								cols='12 6 5 4'
								placeholder='Selecione o Responsável'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='valor_nfe'
								component={LabelAndInputNumber}
								label='Valor da NFe'
								placeholder='Informe o Valor da Nfe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
						</Row>
						{/*<Row>
							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação'
								placeholder='Informe a Observação'
								cols='12 12 10 8'
								readOnly={readOnly} />
						</Row>*/}

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									disabled={readOnly == 'readOnly'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.modoTela == 'cadastro' && this.props.formularioValues.id ? (
					<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 40, marginLeft: 0, marginRight: 0 }}>
						<Grid cols='12 12 12 12'>
							{this.props.modoTelaItem == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Item' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoItem('cadastro', { id_entrada: this.props.formularioValues.id });
												this.props.initFormItem({ id_entrada: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Produto</Th>
												<Th alignRight>Quantidade</Th>
												<Th alignRight>Valor</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaEntradaItem.map(item => (
												<Tr key={item.id}>
													<Td>{item.nome_produto}</Td>
													<Td alignRight>{String(item.quantidade).replace('.', ',')}</Td>
													<Td alignRight>{String(item.valor).replace('.', ',')}</Td>
													<Td alignCenter>
														<ButtonTable
															type={'warning'}
															icon={'fa fa-pencil'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoItem('cadastro', item);
																this.props.initFormItem(item);
															}} />
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash-o'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoItem('exclusao', item);
																this.props.initFormItem(item);
															}} />
													</Td>
												</Tr>
											))}
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaItem == 'cadastro' ? (
								<FormItem onSubmit={this.props.salvarItem} />
							) : (this.props.modoTelaItem == 'exclusao' ? (
								<FormItem excluir onSubmit={this.props.excluirItem} />
							) : null))}
						</Grid>
					</Row>
				) : null}
			</div>
        )
    }


}

EntradaForm = reduxForm({form: 'entradaForm', destroyOnUnmount: false})(EntradaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.entrada.modoTela,
	listaFornecedor: state.entrada.listaFornecedor,
	listaUsuario: state.entrada.listaUsuario,
	modoTelaItem: state.entrada.modoTelaItem,
	listaEntradaItem: state.entrada.listaEntradaItem,
	registro: state.entrada.registro,
	formularioValues: getFormValues('entradaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, getListaFornecedor, getListaUsuario,
	setModoItem, initFormItem, salvarItem, excluirItem, getListaItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EntradaForm);
