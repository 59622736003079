import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './clienteForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './clienteActions';

class Cliente extends Component {

	state = {
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Cliente' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>

				<input className='form-control'
	                 placeholder='Pesquisar'
	                 type='text'
					 value={this.state.pesquisar}
					 onChange={e => {
						 this.setState({ ...this.state, pesquisar: e.target.value });
					 }} />

				<Table>
					<THead>
						<Tr>
							<Th>Nome</Th>
							<Th>CPF/CNPJ</Th>
							<Th>Latitude</Th>
							<Th>Longitude</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.lista.filter(item => {
							return !this.state.pesquisar
								|| (`${item.nome} ${item.cpf_cnpj} ${item.longitude} ${item.latitude}`).toUpperCase().includes(this.state.pesquisar.toUpperCase());
						}).map(item => (
							<Tr key={item.id}>
								<Td>{item.nome}</Td>
								<Td>{item.cpf_cnpj}</Td>
								<Td>{item.latitude}</Td>
								<Td>{item.longitude}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', item);
											this.props.initForm(item);
										}} />
									<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', item);
											this.props.initForm(item);
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.cliente.modoTela,
	lista: state.cliente.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Cliente);
