import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoItem, getListaProduto, getListaFornecedor, initFormItem
} from './manutencaoActions';

class ManutencaoItemForm extends Component {

	state = {
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Item' />
				<Form event={this.props.handleSubmit}>

					<Row>
						<Select
							name='id_produto'
							options={this.props.listaProduto}
							value={this.props.formularioValues.id_produto}
							onChange={value => {
								let produto = this.props.listaProduto.filter(item => item.id == value)[0];

								this.props.initFormItem({
									...this.props.formularioValues,
									id_produto: produto ? produto.id : null,
									valor_unitario: produto ? produto.custo : 0
								})
							}}
							label='Produto'
							cols='12 12 12 12'
							placeholder='Selecione o Produto'
							readOnly={readOnly} />
					</Row>
					{this.props.formularioValues.id_produto == null || this.props.formularioValues.id_produto == 0 ? (
						<Row>
							<Field
								name='produto'
								component={LabelAndInput}
								label='Produto (não cadastrado)'
								placeholder='Informe o Produto'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
					) : null}
					{/*<Row>
						<Field
							name='descricao_servico'
							component={LabelAndInput}
							label='Serviço'
							placeholder='Informe o Serviço'
							cols='12 12 12 12'
							readOnly={readOnly} />
					</Row>*/}

					{/*<Row>
						<Field
							name='id_fornecedor'
							component={Select}
							options={this.props.listaFornecedor}
							label='Fornecedor'
							cols='12 12 12 12'
							placeholder='Selecione o Fornecedor'
							readOnly={readOnly} />
					</Row>
					{this.props.formularioValues.id_fornecedor == null || this.props.formularioValues.id_fornecedor == 0 ? (
						<Row>
							<Field
								name='fornecedor'
								component={LabelAndInput}
								label='Fornecedor (não cadastrado)'
								placeholder='Informe o Fornecedor'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
					) : null}*/}
					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade'
							placeholder='Informe a quantidade'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly}
							onChange={data => {
								let quantidade = parseFloat(String(data.target.value || '0').replace(',', '.'));
								let valorUnitario = parseFloat(String(this.props.formularioValues.valor_unitario || '0').replace(',', '.'));
								let total = (quantidade * valorUnitario).toFixed(2);
								this.props.initFormItem({
									...this.props.formularioValues,
									valor_unitario: valorUnitario.toFixed(2),
									valor_total: total
								});
							}} />
						<Field
							name='valor_unitario'
							component={LabelAndInputNumber}
							label='Valor Unitário'
							placeholder='Informe o valor unitário'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly}
							onChange={data => {
								let quantidade = parseFloat(String(this.props.formularioValues.quantidade || '0').replace(',', '.'));
								let valorUnitario = parseFloat(String(data.target.value || '0').replace(',', '.'));
								let total = (quantidade * valorUnitario).toFixed(2);
								this.props.initFormItem({
									...this.props.formularioValues,
									quantidade: quantidade.toFixed(2),
									valor_total: total
								});
							}} />

						<Field
							name='valor_total'
							component={LabelAndInputNumber}
							label='Total'
							placeholder='Informe o total'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly}
							onChange={data => {
								let quantidade = parseFloat(String(this.props.formularioValues.quantidade || '0').replace(',', '.'));
								let valorUnitario = parseFloat(String(this.props.formularioValues.valor_unitario || '0').replace(',', '.'));
								let valorTotal = parseFloat(String(data.target.value || '0').replace(',', '.'));

								if (quantidade) {
									valorUnitario = (valorTotal / quantidade).toFixed(2);
								}

								this.props.initFormItem({
									...this.props.formularioValues,
									quantidade: quantidade,
									valor_unitario: valorUnitario
								});
							}} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoItem('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

ManutencaoItemForm = reduxForm({form: 'manutencaoItemForm', destroyOnUnmount: false})(ManutencaoItemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.manutencao.modoTela,
	listaProduto: state.manutencao.listaProduto,
	listaFornecedor: state.manutencao.listaFornecedor,
	registro: state.manutencao.registro,
	formularioValues: getFormValues('manutencaoItemForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoItem, getListaProduto, getListaFornecedor, initFormItem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManutencaoItemForm);
