import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaTanque: [],
	listaUsuario: [],
	listaCombustivel: [],
	listaBomba: [],
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMBUSTIVEL_TRANSFERENCIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		case 'COMBUSTIVEL_TRANSFERENCIA_FILTRO':
			return { ...state, filtro: action.payload };
        case 'COMBUSTIVEL_TRANSFERENCIA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'COMBUSTIVEL_TRANSFERENCIA_TANQUE_SELECT_LISTADO':
            return { ...state, listaTanque: action.payload.data };
		case 'COMBUSTIVEL_TRANSFERENCIA_COMBUSTIVEL_SELECT_LISTADO':
            return { ...state, listaCombustivel: action.payload.data };
		case 'COMBUSTIVEL_TRANSFERENCIA_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
		case 'COMBUSTIVEL_TRANSFERENCIA_BOMBA_SELECT_LISTADO':
            return { ...state, listaBomba: action.payload.data };
        default:
            return state;
    }
}
