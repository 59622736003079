const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaTanque: [], listaUsuario: [], listaCombustivel: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMBUSTIVEL_AJUSTE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'COMBUSTIVEL_AJUSTE_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'COMBUSTIVEL_AJUSTE_TANQUE_SELECT_LISTADO':
            return { ...state, listaTanque: action.payload.data };
		case 'COMBUSTIVEL_AJUSTE_COMBUSTIVEL_SELECT_LISTADO':
            return { ...state, listaCombustivel: action.payload.data };
		case 'COMBUSTIVEL_AJUSTE_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
        default:
            return state;
    }
}
