import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PNEU_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('pneuForm', {
			...registro,
			datahora_descarte: registro.datahora_descarte ? DateFormat.formatarDataHoraSqlParaTela(registro.datahora_descarte) : null,
			valor: String(registro.valor || 0).replace('.', ','),
			total: String(registro.total || 0).replace('.', ',')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneu?id_empresa=${id_empresa}&listar_descarte=true`);
    return {
        type: 'PNEU_LISTADO',
        payload: request
    };
}

export function getListaDimensao(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneuDimensao/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_DIMENSAO_SELECT_LISTADO',
        payload: request
    };
}

export function salvarDimensao(registro, formulario) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuDimensao`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaDimensao(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				dispatch(initForm({
					...formulario,
					id_dimensao: resp.data
				}));
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaDesenho(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneuDesenho/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_DESENHO_SELECT_LISTADO',
        payload: request
    };
}

export function salvarDesenho(registro, formulario, tipo = 'pneu') {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuDesenho`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaDesenho(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				if (tipo == 'recapagem') {
					dispatch(initFormRecapagem({
						...formulario,
						id_desenho: resp.data
					}));
				} else if (tipo == 'avaria') {
					dispatch(initFormAvaria({
						...formulario,
						id_desenho: resp.data
					}));
				} else {
					dispatch(initForm({
						...formulario,
						id_desenho: resp.data
					}));
				}
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTipo(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneuTipo/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function salvarTipo(registro, formulario) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuTipo`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaTipo(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				dispatch(initForm({
					...formulario,
					id_tipo: resp.data
				}));
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMarca(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneuMarca/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_MARCA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarMarca(registro, formulario, tipo = 'pneu') {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuMarca`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaMarca(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				if (tipo == 'recapagem') {
					dispatch(initFormRecapagem({
						...formulario,
						id_marca: resp.data
					}));
				} else if (tipo == 'avaria') {
					dispatch(initFormAvaria({
						...formulario,
						id_marca: resp.data
					}));
				} else {
					dispatch(initForm({
						...formulario,
						id_marca: resp.data
					}));
				}
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneu`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_descarte: registro.datahora_descarte ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_descarte) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/pneu?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Recapagem
export function setModoRecapagem(modo, registro = {}) {
    return {
        type: 'PNEU_RECAPAGEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormRecapagem(registro = {}) {
    return [
        initialize('pneuRecapagemForm', {
			...registro,
			valor: String(registro.valor || 0).replace('.', ',')
		})
    ];
}

export function salvarRecapagem(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuRecapagem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaRecapagem(getState().auth.usuario.id_empresa, registro.id_pneu));
			dispatch(setModoRecapagem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirRecapagem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/pneuRecapagem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaRecapagem(registro.id_empresa, registro.id_pneu));
			dispatch(setModoRecapagem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaRecapagem(id_empresa, id_pneu) {
    const request = axios.get(`${consts.API_URL}/pneuRecapagem?id_empresa=${id_empresa}&id_pneu=${id_pneu}`);
    return {
        type: 'PNEU_RECAPAGEM_LISTADO',
        payload: request
    };
}

//Avaria
export function setModoAvaria(modo, registro = {}) {
    return {
        type: 'PNEU_AVARIA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormAvaria(registro = {}) {
    return [
        initialize('pneuAvariaForm', {
			...registro,
			valor: String(registro.valor || 0).replace('.', ',')
		})
    ];
}

export function salvarAvaria(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuAvaria`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAvaria(getState().auth.usuario.id_empresa, registro.id_pneu));
			dispatch(setModoAvaria('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirAvaria(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/pneuAvaria?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAvaria(registro.id_empresa, registro.id_pneu));
			dispatch(setModoAvaria('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


export function getListaAvaria(id_empresa, id_pneu) {
    const request = axios.get(`${consts.API_URL}/pneuAvaria?id_empresa=${id_empresa}&id_pneu=${id_pneu}`);
    return {
        type: 'PNEU_AVARIA_LISTADO',
        payload: request
    };
}
