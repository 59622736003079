import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return (dispatch, getState) => {
		dispatch({
	        type: 'ORDEM_SERVICO_FECHAMENTO_MODO_TELA',
	        payload: {
				modo: modo,
				registro: registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id,
			}
    	});
	}
}
export function initForm(registro = { data_agendamento: DateFormat.getDataAtual() }) {
    return (dispatch, getState) => {
		dispatch([
	        initialize('ordemServicoFechamentoForm', {
				...registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id,
				observacao: ''
			})
		]);
	}
}

export function setFiltro(filtro) {
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_FILTRO',
        payload: filtro
    };
}

export function getLista(id_empresa) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServicoFechamento?id_empresa=${id_empresa}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(getState().ordemServicoFechamento.filtro.dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(getState().ordemServicoFechamento.filtro.dataFinal)}
			&id_usuario=${getState().ordemServicoFechamento.filtro.id_usuario}
			&id_equipamento=${getState().ordemServicoFechamento.filtro.id_equipamento}`)
		.then(resp => {
            dispatch({
				type: 'ORDEM_SERVICO_FECHAMENTO_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEquipamento(id_empresa) {
    const request = axios.get(`${consts.API_URL}/equipamento/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_EQUIPAMENTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaServico(id_empresa) {
    const request = axios.get(`${consts.API_URL}/servico/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_SERVICO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente(id_empresa) {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/ordemServicoFechamento`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			data_agendamento: registro.data_agendamento ? DateFormat.formatarDataTelaParaSql(registro.data_agendamento) : null
		}).then(resp => {
			if (registro.id) {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			} else {
				axios.get(`${consts.API_URL}/ordemServicoFechamento?id=${resp.data}`).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
    				dispatch(getLista(getState().auth.usuario.id_empresa));
					dispatch(initForm({
						...resp.data[0]
					}));
				});
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/ordemServicoFechamento?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function encerrarOrdemServicoFechamento(registro) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServicoFechamento?id=${registro.id}`).then(resp => {
	        axios.post(`${consts.API_URL}/ordemServicoFechamento`, {
				...resp.data[0],
				datahora_fechamento: DateFormat.getDataHoraAtual(),
				valor_servico: registro.valor_servico,
				valor_cobranca: registro.valor_cobranca
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function reabrirOrdemServicoFechamento(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServicoFechamento?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/ordemServicoFechamento`, {
				...resp.data[0],
				datahora_fechamento: null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function vincularFechamento(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/ordemServicoFechamento/vincular`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(getState().auth.usuario.id_empresa, registro.id_fechamento));
			dispatch(getListaOrdemServicoAberta(getState().auth.usuario.id_empresa));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function desvincularFechamento(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/ordemServicoFechamento/desvincular`, {
			...registro
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(getState().auth.usuario.id_empresa, registro.id_fechamento));
			dispatch(getListaOrdemServicoAberta(getState().auth.usuario.id_empresa));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaItem(id_empresa, id_fechamento) {
    const request = axios.get(`${consts.API_URL}/ordemServicoFechamento/fechamento?id_empresa=${id_empresa}&id_fechamento=${id_fechamento}`);
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_ITEM_LISTADO',
        payload: request
    };
}


export function getListaOrdemServicoAberta(id_empresa) {
    const request = axios.get(`${consts.API_URL}/ordemServicoFechamento/abertas?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_FECHAMENTO_ABERTA_LISTADO',
        payload: request
    };
}
