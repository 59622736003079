const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaUnidadeMedida: [], listaCategoria: [], listaMarca: [], listaUltimasCompras: [], listaMovimento: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PRODUTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'PRODUTO_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
        case 'PRODUTO_UNIDADE_MEDIDA_SELECT_LISTADO':
            return { ...state, listaUnidadeMedida: action.payload.data };
		case 'PRODUTO_CATEGORIA_SELECT_LISTADO':
            return { ...state, listaCategoria: action.payload.data };
		case 'PRODUTO_MARCA_SELECT_LISTADO':
            return { ...state, listaMarca: action.payload.data };

		case 'PRODUTO_ULTIMAS_COMPRAS_LISTADO':
            return { ...state, listaUltimasCompras: action.payload.data };

		case 'PRODUTO_MOVIMENTO_LISTADO':
            return { ...state, listaMovimento: action.payload.data };

        default:
            return state;
    }
}
