import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoAvaria, initFormAvaria, salvarDesenho, salvarMarca
} from './pneuActions';

class PneuAvariaForm extends Component {

	state = {
	}

    componentWillMount() {
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Avaria' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='causa'
							component={LabelAndInput}
							label='Causa *'
							placeholder='Informe a causa'
							cols='12 12 12 12'
							readOnly={readOnly} />

						<Field
							name='km_avaria'
							component={LabelAndInputNumber}
							label='Km da Avaria'
							placeholder='Informe a Km'
							cols='12 6 6 6'
							casas={0}
							readOnly={readOnly} />

						<Field
							name='hr_avaria'
							component={LabelAndInputNumber}
							label='Hr da Avaria'
							placeholder='Informe a Hr'
							cols='12 6 6 6'
							casas={0}
							readOnly={readOnly} />

						<Field
							name='observacao'
							component={LabelAndInput}
							label='Observação'
							placeholder='Informe observações'
							cols='12 12 12 12'
							readOnly={readOnly} />

						<Field
							name='valor'
							component={LabelAndInputNumber}
							label='Valor'
							placeholder='Informe o valor'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoAvaria('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

PneuAvariaForm = reduxForm({form: 'pneuAvariaForm', destroyOnUnmount: false})(PneuAvariaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pneu.modoTela,
	registro: state.pneu.registro,
	formularioValues: getFormValues('pneuAvariaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoAvaria, initFormAvaria, salvarDesenho, salvarMarca }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuAvariaForm);
