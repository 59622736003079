import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = { contato: [] }) {
    return {
        type: 'FORNECEDOR_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { contato: [] }) {
    return [
        initialize('fornecedorForm', registro)
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/fornecedor?id_empresa=${id_empresa}`);
    return {
        type: 'FORNECEDOR_LISTADO',
        payload: request
    };
}

export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarselect`);
    return {
        type: 'FORNECEDOR_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade() {
    const request = axios.get(`${consts.API_URL}/cidade/listarselect`);
    return {
        type: 'FORNECEDOR_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/fornecedor`, {
			...registro,
			cnpj: (registro.cnpj || '').replace(".", "").replace(".", "").replace("-", "").replace("/", ""),
			cpf: (registro.cpf || '').replace(".", "").replace(".", "").replace("-", ""),
			cep: (registro.cep || '').replace(".", "").replace("-", ""),
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/fornecedor?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
