import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormItem from './manutencaoItemForm';
import FormAtividade from './manutencaoAtividadeForm';

import {
	setModoTela, initForm, salvar, getListaEquipamento, getListaUsuario, getListaTipo,
	setModoItem, initFormItem, salvarItem, excluirItem, getListaItem,
	setModoAtividade, initFormAtividade, salvarAtividade, excluirAtividade, getListaAtividade,
	reabrirManutencao, encerrarManutencao, cancelarManutencao
} from './manutencaoActions';

class ManutencaoForm extends Component {

	state = {}

    componentWillMount() {
		this.props.getListaItem(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
		this.props.getListaAtividade(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir || this.props.formularioValues.datahora_encerramento || this.props.formularioValues.datahora_cancelamento ? 'readOnly' : '';

		let totalItems = 0;

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='data_manutencao'
								component={LabelAndInputMask}
								label='Data da Manutenção *'
								placeholder='Informe a Data'
								mask="99/99/9999"
								maskChar=" "
								cols='12 6 4 2'
								readOnly={readOnly} />

							{this.props.formularioValues.id ? (
								<Field
									name='ordem_servico'
									component={LabelAndInput}
									label='Ordem de Serviço *'
									placeholder='Informe a Ordem de Serviço'
									cols='12 6 4 3'
									readOnly='readOnly' />
							) : null}

							{this.props.formularioValues.id ? (
								<Field
									name='id'
									component={LabelAndInput}
									label='Sequencial *'
									placeholder='Informe a Sequência'
									cols='12 6 4 3'
									readOnly='readOnly' />
							) : null}

							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo *'
								cols='12 12 4 4'
								placeholder='Selecione o Tipo'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_equipamento'
								component={Select}
								options={this.props.listaEquipamento}
								label='Equipamento *'
								cols='12 12 6 4'
								placeholder='Selecione o Equipamento'
								readOnly={readOnly} />

							<Field
								name='compartimento'
								component={LabelAndInput}
								label='Compartimento'
								placeholder='Informe o Compartimento'
								cols='12 12 6 5'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_responsavel'
								component={Select}
								options={this.props.listaUsuario}
								label='Responsável *'
								cols='12 12 4 3'
								placeholder='Selecione o Responsável'
								readOnly={readOnly} />

							<Field
								name='id_responsavel_externo'
								component={Select}
								options={this.props.listaFornecedor}
								label='Responsável Externo'
								cols='12 12 4 3'
								placeholder='Selecione o Responsável Externo'
								readOnly={readOnly} />

							<Field
								name='id_auxiliar'
								component={Select}
								options={this.props.listaUsuario}
								label='Auxiliar'
								cols='12 12 4 3'
								placeholder='Selecione o Auxiliar'
								readOnly={readOnly} />

							<Field
								name='id_operador'
								component={Select}
								options={this.props.listaUsuario}
								label='Operador *'
								cols='12 12 4 3'
								placeholder='Selecione o Operador'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='hodometro'
								component={LabelAndInputNumber}
								label='Odômetro'
								placeholder='Informe o Odômetro'
								cols='12 6 4 2'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='horimetro'
								component={LabelAndInputNumber}
								label='Horímetro'
								placeholder='Informe o Horímetro'
								cols='12 6 4 2'
								casas={1}
								readOnly={readOnly} />
						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									disabled={readOnly == 'readOnly'}
									icon={'fa fa-check'} />
							)}
							{!this.props.excluir && this.props.formularioValues.id
								&& !this.props.formularioValues.datahora_encerramento && !this.props.formularioValues.datahora_cancelamento ? (
								<Button
									text='Encerrar Manutenção'
									type={'primary'}
									icon={'fa fa-check-square-o'}
									disabled={readOnly == 'readOnly'}
									event={() => this.props.encerrarManutencao(this.props.formularioValues.id)} />
							) : null}
							{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro
								&& !this.props.excluir && this.props.formularioValues.id
								&& this.props.formularioValues.datahora_encerramento && !this.props.formularioValues.datahora_cancelamento ? (
								<Button
									text='Reabrir Manutenção'
									type={'primary'}
									icon={'fa fa-check-square-o'}
									event={() => this.props.reabrirManutencao(this.props.formularioValues.id)} />
							) : null}
							{!this.props.excluir && this.props.formularioValues.id
								&& !this.props.formularioValues.datahora_encerramento && !this.props.formularioValues.datahora_cancelamento ? (
								<Button
									text='Cancelar Manutenção'
									type={'danger'}
									icon={'fa fa-ban'}
									disabled={readOnly == 'readOnly'}
									event={() => this.props.cancelarManutencao(this.props.formularioValues.id)} />
							) : null}
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.modoTela == 'cadastro' && this.props.formularioValues.id ? (
					<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 40, marginLeft: 0, marginRight: 0 }}>
						<Grid cols='12 12 12 12'>
							{this.props.modoTelaAtividade == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Atividade' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoAtividade('cadastro', { id_manutencao: this.props.formularioValues.id });
												this.props.initFormAtividade({ id_manutencao: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Descrição</Th>
												<Th>Início</Th>
												<Th>Final</Th>
												<Th alignCenter>Tempo</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaManutencaoAtividade.map(item => {
												let tempo = '00:00:00';
												if (item.datahora_inicio) {
													let segundos = ((item.datahora_fim ? new Date(item.datahora_fim) : new Date()).getTime() - new Date(item.datahora_inicio).getTime()) / 1000;
													if (segundos > 0) {
														let horas = parseInt(segundos / 60 / 60);
														segundos = segundos - (horas * 60 * 60);
														let minutos = parseInt(segundos / 60);
														tempo = `${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}`;
													}
												}
												return (
													<Tr key={item.id}>
														<Td>{item.descricao}</Td>
														<Td>{item.datahora_inicio ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio) : ''}</Td>
														<Td>{item.datahora_fim ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim) : ''}</Td>
														<Td alignCenter>{tempo}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'warning'}
																icon={'fa fa-pencil'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.setModoAtividade('cadastro', item);
																	this.props.initFormAtividade(item);
																}} />
															<ButtonTable
																type={'danger'}
																icon={'fa fa-trash-o'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.setModoAtividade('exclusao', item);
																	this.props.initFormAtividade(item);
																}} />
														</Td>
													</Tr>
												);
											})}
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaAtividade == 'cadastro' ? (
								<FormAtividade onSubmit={this.props.salvarAtividade} />
							) : (this.props.modoTelaAtividade == 'exclusao' ? (
								<FormAtividade excluir onSubmit={this.props.excluirAtividade} />
							) : null))}
						</Grid>
						<Grid cols='12 12 12 12'>
							{this.props.modoTelaItem == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Produto' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoItem('cadastro', { id_manutencao: this.props.formularioValues.id });
												this.props.initFormItem({ id_manutencao: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Produto</Th>
												{/*<Th>Fornecedor</Th>*/}
												<Th alignRight>Quantidade</Th>
												<Th alignRight>Total</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaManutencaoItem.map(item => {

												totalItems += parseFloat(item.valor_total || 0);

												return (
													<Tr key={item.id}>
														<Td>{(item.nome_produto || item.produto) && item.descricao_servico ? `${item.nome_produto || item.produto} - ${item.descricao_servico}` : ((item.nome_produto || item.produto) || item.descricao_servico)}</Td>
														{/*<Td>{item.nome_fornecedor || item.fornecedor}</Td>*/}
														<Td alignRight>{String(item.quantidade).replace('.', ',')}</Td>
														<Td alignRight>{String(item.valor_total).replace('.', ',')}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'warning'}
																icon={'fa fa-pencil'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.setModoItem('cadastro', item);
																	this.props.initFormItem(item);
																}} />
															<ButtonTable
																type={'danger'}
																icon={'fa fa-trash-o'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.setModoItem('exclusao', item);
																	this.props.initFormItem(item);
																}} />
														</Td>
													</Tr>
												);
											})}
											{/*Total*/}
											<Tr>
												<Td>Total</Td>
												<Td alignRight></Td>
												<Td alignRight>{totalItems.toFixed(4).replace('.', ',')}</Td>
												<Td alignCenter>
												</Td>
											</Tr>
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaItem == 'cadastro' ? (
								<FormItem onSubmit={this.props.salvarItem} />
							) : (this.props.modoTelaItem == 'exclusao' ? (
								<FormItem excluir onSubmit={this.props.excluirItem} />
							) : null))}
						</Grid>
					</Row>
				) : null}
			</div>
        )
    }


}

ManutencaoForm = reduxForm({form: 'manutencaoForm', destroyOnUnmount: false})(ManutencaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.manutencao.modoTela,
	listaTipo: state.manutencao.listaTipo,
	listaEquipamento: state.manutencao.listaEquipamento,
	listaUsuario: state.manutencao.listaUsuario,
	listaFornecedor: state.manutencao.listaFornecedor,
	modoTelaItem: state.manutencao.modoTelaItem,
	listaManutencaoItem: state.manutencao.listaManutencaoItem,
	modoTelaAtividade: state.manutencao.modoTelaAtividade,
	listaManutencaoAtividade: state.manutencao.listaManutencaoAtividade,
	registro: state.manutencao.registro,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0],
	formularioValues: getFormValues('manutencaoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, getListaEquipamento, getListaUsuario, getListaTipo,
	setModoItem, initFormItem, salvarItem, excluirItem, getListaItem,
	setModoAtividade, initFormAtividade, salvarAtividade, excluirAtividade, getListaAtividade,
	reabrirManutencao, encerrarManutencao, cancelarManutencao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManutencaoForm);
