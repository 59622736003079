const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaEspecie: [], listaEquipamentoEixo: [], listaEquipamentoEixoPneu: [], listaCombustivelMedia: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'EQUIPAMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'EQUIPAMENTO_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'EQUIPAMENTO_EIXO_LISTADO':
            return { ...state, listaEquipamentoEixo: action.payload.data };
		case 'EQUIPAMENTO_EIXO_PNEU_LISTADO':
            return { ...state, listaEquipamentoEixoPneu: action.payload.data };
		case 'EQUIPAMENTO_SELECT_LISTADO':
            return { ...state, listaEspecie: action.payload.data };
		case 'COMBUSTIVEL_MEDIA_SELECT_LISTADO':
			return { ...state, listaCombustivelMedia: action.payload.data };
        default:
            return state;
    }
}
