import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaUsuario: [],
	listaEquipamento: [],
	listaServico: [],
	listaCliente: [],
	listaOrdemServicoItem: [],
	listaDiaria: [{ id: true, valor: 'Sim' }, { id: false, valor: 'Não' }],
	modoTelaItem: 'lista',
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null,
		id_cliente: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ORDEM_SERVICO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				modoTelaItem: 'lista'
			};
		case 'ORDEM_SERVICO_FILTRO':
			return { ...state, filtro: action.payload };
        case 'ORDEM_SERVICO_LISTADO':
            return { ...state, lista: action.payload.data };
        case 'ORDEM_SERVICO_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
        case 'ORDEM_SERVICO_EQUIPAMENTO_SELECT_LISTADO':
            return { ...state, listaEquipamento: action.payload.data };
        case 'ORDEM_SERVICO_SERVICO_SELECT_LISTADO':
            return { ...state, listaServico: action.payload.data };
		case 'ORDEM_SERVICO_CLIENTE_SELECT_LISTADO':
            return { ...state, listaCliente: action.payload.data };
        case 'ORDEM_SERVICO_ITEM_MODO_TELA':
            return { ...state,
				modoTelaItem: action.payload.modo
			};
		case 'ORDEM_SERVICO_ITEM_LISTADO':
            return { ...state, listaOrdemServicoItem: action.payload.data };
        default:
            return state;
    }
}
