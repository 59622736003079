import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './combustivelTransferenciaForm';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';

import { setModoTela, initForm, salvar, excluir, getLista, setFiltro, getListaUsuario, getListaTanque, getListaBomba, getListaCombustivel } from './combustivelTransferenciaActions';

class CombustivelTransferencia extends Component {

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa);
		this.props.getListaUsuario(this.props.sessao.id_empresa);
		this.props.getListaTanque(this.props.sessao.id_empresa);
		this.props.getListaBomba(this.props.sessao.id_empresa);
		this.props.getListaCombustivel(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Transferência entre Tanques' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>
				<Row>
					<LabelAndInputMask
						label='Data Inicial'
						placeholder='Informe a Data Inicial'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataInicial}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataInicial: data.target.value
							});
							console.log(this.props.filtro);
							this.props.getLista(this.props.sessao.id_empresa);
						}} />
					<LabelAndInputMask
						label='Data Final'
						placeholder='Informe a Data Final'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataFinal}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataFinal: data.target.value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />

					<Select
						name='id_usuario'
						options={this.props.listaUsuario}
						label='Usuário *'
						cols='12 4 3'
						placeholder='Selecione o Usuário'
						value={this.props.filtro.id_usuario}
						onChange={value => {
							this.props.setFiltro({
								...this.props.filtro,
								id_usuario: value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />
				</Row>
				<Table>
					<THead>
						<Tr>
							<Th>Data e Hora</Th>
							<Th>Tanque Saída</Th>
							<Th>Bomba Saída</Th>
							<Th>Tanque Entrada</Th>
							<Th>Combustível</Th>
							<Th alignRight>Quantidade</Th>
							<Th>Responsável</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{(this.props.lista).map(item => (
							<Tr key={item.id}>
								<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_transferencia)}</Td>
								<Td>{item.identificacao_tanque_saida}</Td>
								<Td>{item.identificacao_bomba_saida}</Td>
								<Td>{item.identificacao_tanque_entrada}</Td>
								<Td>{item.nome_combustivel}</Td>
								<Td alignRight>{parseFloat(item.quantidade || 0).toFixed(4).replace('.', ',')}</Td>
								<Td>{item.nome_responsavel}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', item);
											this.props.initForm({
												...item,
												quantidade: item.quantidade.replace('.', ','),
												acumulador_bomba: item.acumulador_bomba.replace('.', ',')
											});
										}} />
									<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', item);
											this.props.initForm({
												...item,
												quantidade: item.quantidade.replace('.', ','),
												acumulador_bomba: item.acumulador_bomba.replace('.', ',')
											});
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.combustivelTransferencia.modoTela,
	lista: state.combustivelTransferencia.lista,
	listaUsuario: state.combustivelTransferencia.listaUsuario,
	filtro: state.combustivelTransferencia.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro, getListaUsuario, getListaTanque, getListaBomba, getListaCombustivel }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CombustivelTransferencia);
