import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';

import {
	setModoTela, initForm,
	vincularFechamento, desvincularFechamento, getListaItem, getListaOrdemServicoAberta,
	reabrirOrdemServicoFechamento, encerrarOrdemServicoFechamento
} from './ordemServicoFechamentoActions';

class OrdemServicoFechamentoForm extends Component {

	state = {}

    componentWillMount() {
		this.props.getListaItem(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
		this.props.getListaOrdemServicoAberta(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir || this.props.formularioValues.datahora_fechamento || this.props.formularioValues.datahora_cancelamento ? 'readOnly' : '';

		let totalItems = 0;

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>

							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente}
								label='Cliente *'
								cols='12 12 12 12'
								placeholder='Selecione o cliente'
								readOnly={readOnly} />

						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									disabled={readOnly == 'readOnly'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />

							{!this.props.excluir && this.props.formularioValues.id
								&& !this.props.formularioValues.datahora_fechamento ? (
								<Button
									text='Finalizar'
									type={'primary'}
									icon={'fa fa-check-square-o'}
									disabled={readOnly == 'readOnly'}
									event={() => this.props.encerrarOrdemServicoFechamento({
										id: this.props.formularioValues.id,
										valor_servico: totalItems,
										valor_cobranca: totalItems
									})} />
							) : null}
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.modoTela == 'cadastro' && this.props.formularioValues.id ? (
					<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 40, marginLeft: 0, marginRight: 0 }}>
						<Grid cols='12 12 12 12'>
							{!this.props.formularioValues.datahora_fechamento ? (
								<ContentCard>
									<ContentCardHeader title='Abertas' />
									<Table>
										<THead>
											<Tr>
												<Th>Data</Th>
												<Th>Operador</Th>
												<Th alignRight>Total</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaOrdemServicoAberta.filter(item => item.id_cliente == this.props.formularioValues.id_cliente).map(item => {

												return (
													<Tr key={item.id}>
														<Td>{DateFormat.formatarDataSqlParaTela(item.data_agendamento)}</Td>
														<Td>{item.nome_usuario_operador}</Td>
														<Td alignRight>{String(item.valor_total).replace('.', ',')}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'success'}
																icon={'fa fa-plus'}
																disabled={readOnly == 'readOnly'}
																event={() => {
																	this.props.vincularFechamento({
																		id: item.id,
																		id_fechamento: this.props.formularioValues.id
																	});
																}} />
														</Td>
													</Tr>
												);
											})}
										</TBody>
									</Table>
								</ContentCard>
							) : null}

							<ContentCard>
								<ContentCardHeader title='Item' />
								<Table>
									<THead>
										<Tr>
											<Th>Data</Th>
											<Th>Operador</Th>
											<Th alignRight>Total</Th>
											<Th alignCenter acoes>Ações</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaFechamentoItem.map(item => {

											totalItems += parseFloat(item.valor_total || 0);

											return (
												<Tr key={item.id}>
													<Td>{DateFormat.formatarDataSqlParaTela(item.data_agendamento)}</Td>
													<Td>{item.nome_usuario_operador}</Td>
													<Td alignRight>{String(item.valor_total).replace('.', ',')}</Td>
													<Td alignCenter>
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.desvincularFechamento({
																	id: item.id,
																	id_fechamento: this.props.formularioValues.id
																});
															}} />
													</Td>
												</Tr>
											);
										})}
										{/*Total*/}
										<Tr>
											<Td>Total</Td>
											<Td></Td>
											<Td alignRight>{totalItems.toFixed(4).replace('.', ',')}</Td>
											<Td alignCenter>
											</Td>
										</Tr>
									</TBody>
								</Table>
							</ContentCard>
						</Grid>
					</Row>
				) : null}
			</div>
        )
    }

}

OrdemServicoFechamentoForm = reduxForm({form: 'ordemServicoFechamentoForm', destroyOnUnmount: false})(OrdemServicoFechamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.ordemServicoFechamento.modoTela,
	listaCliente: state.ordemServicoFechamento.listaCliente,
	listaEquipamento: state.ordemServicoFechamento.listaEquipamento,
	listaUsuario: state.ordemServicoFechamento.listaUsuario,
	modoTelaItem: state.ordemServicoFechamento.modoTelaItem,
	listaFechamentoItem: state.ordemServicoFechamento.listaFechamentoItem,
	listaOrdemServicoAberta: state.ordemServicoFechamento.listaOrdemServicoAberta,
	registro: state.ordemServicoFechamento.registro,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0],
	formularioValues: getFormValues('ordemServicoFechamentoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm,
	vincularFechamento, desvincularFechamento, getListaItem, getListaOrdemServicoAberta,
	reabrirOrdemServicoFechamento, encerrarOrdemServicoFechamento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoFechamentoForm);
