import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import NumberFormat from 'react-number-format';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';

import {
	setModoTela, getLista, getListaEquipamentoEixo, getListaEquipamentoEixoPneu, getListaPneu, setModoTelaEquipamento, salvar, excluir
} from './pneuControleActions';

class PneuControleEquipamentoForm extends Component {

	state = {
		pneuControleAux: null,
		filtroPneu: '',
		pneuControleAlteracao: null
	}

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa, this.props.registro.id || -1);
		this.props.getListaPneu(this.props.sessao.id_empresa);
		this.props.getListaEquipamentoEixo(this.props.sessao.id_empresa, this.props.registro.id || -1);
		this.props.getListaEquipamentoEixoPneu(this.props.sessao.id_empresa, this.props.registro.id || -1);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		return (
			<div>
				{this.props.registro.id ? (
					<ContentCard style={{ marginBottom: 80 }}>
						<Table>
							<THead>
								<Tr>
									<Th>Nome/Descrição</Th>
									<Th>Placa</Th>
									<Th>Espécie</Th>
									<Th>Tipo de Média</Th>
									<Th>Reboque</Th>
									<Th alignCenter acoes>Voltar</Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaEquipamento.filter(item => item.id == this.props.registro.id).map(item => (
									<Tr key={item.id}>
										<Td>{item.nome}</Td>
										<Td>{item.placa}</Td>
										<Td>{item.nome_especie}</Td>
										<Td>{item.hodometro && item.horimetro ? 'Odômetro/Horímetro' : (item.hodometro ? 'Odômetro' : (item.horimetro ? 'Horímetro' : 'Nenhum'))}</Td>
										<Td>{item.reboque ? 'Sim' : 'Não'}</Td>
										<Td alignCenter>
											<ButtonTable
												type={'warning'}
												icon={'fa fa-chevron-left'}
												event={() => {
													this.props.setModoTela('lista', {});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>

						{this.props.modoTelaEquipamento == 'estrutura' ? (
							<div>
								<div class={`col-xs-12 col-sm-12 col-md-${this.state.pneuControleAlteracao ? '6' : '12'} col-lg-${this.state.pneuControleAlteracao ? '6' : '12'}`}>
									{this.telaEstrutura(readOnly)}
								</div>
								{this.state.pneuControleAlteracao ? (
									<div class='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
										{this.telaCadastro(readOnly)}
									</div>
								) : null}
							</div>
						) : this.props.modoTelaEquipamento == 'selecionarPneu' ? (
							this.telaSelecionarPneu()
						) : null}
					</ContentCard>
				) : null}
			</div>
		);
    }

	telaEstrutura(readOnly) {

		let divPrincipal = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}

		let divEixo = {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		}

		let divEixoOrdem = {
			margin: 10,
			width: 40,
			fontWeight: 'bold'
		}

		let divEixoPneuVazio = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			margin: 2,
			height: 150,
			width: 50,
			borderRadius: 20,
			borderColor: '#000',
			borderStyle: 'dashed',
			color: '#000',
			fontSize: 20,
			fontWeight: 'bold'
		}

		let divEixoPneu = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			margin: 2,
			height: 150,
			width: 50,
			borderColor: '#000',
			borderStyle: 'solid',
			borderRadius: 20,
			backgroundColor: '#000',
			color: '#fff',
			fontSize: 20,
			fontWeight: 'bold',
			writingMode: 'vertical-rl',
			textOrientation: 'mixed'
		}

		let divEixoSeparador = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: 20,
			width: 54,
			backgroundColor: '#000',
			color: '#fff',
			fontWeight: 'bold'
		}

		let maiorEixo = 0;
		this.props.listaEquipamentoEixo.map(eixo => {
			let quantidade = this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length;
			if (quantidade > maiorEixo) {
				maiorEixo = quantidade;
			}
		});

		return (
			<div style={divPrincipal}>
				<label style={{ fontSize: 18, marginBottom: 20 }}>Dianteira</label>
				{this.props.listaEquipamentoEixo.map(eixo => {
					let desenhoEixo = [];
					desenhoEixo.push(1);
					desenhoEixo.push(2);
					for (var i = this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).length; i < maiorEixo; i++) {
						desenhoEixo.push(i);
					}

					return (
						<div style={divEixo}>
							{this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).map((pneu, i) => {
								if (i < this.props.listaEquipamentoEixoPneu.filter(pneuItem => pneuItem.id_equipamento_eixo === eixo.id).length / 2) {
									let pneuControle = this.props.lista.filter(pneuControle => pneuControle.id_equipamento_eixo_pneu == pneu.id && !pneuControle.datahora_final)[0];
									if (pneuControle) {
										return (
											<a class='btn' style={divEixoPneu}
												onClick={() => {
													this.setState({
														...this.state,
														pneuControleAlteracao: pneuControle
													});
												}}>
												{pneuControle.numero_fogo}
											</a>
										);
									} else {
										return (
											<a class='btn' style={divEixoPneuVazio}
												onClick={() => {
													this.props.setModoTelaEquipamento('selecionarPneu');
													this.setState({
														...this.state,
														pneuControleAux: {
															id_pneu: null,
															id_equipamento: this.props.registro.id,
															id_equipamento_eixo_pneu: pneu.id,
															estepe: false,
															datahora_inicial: DateFormat.getDataHoraAtual(),
															km_acumulada: 0,
															hr_acumulada: 0,
															datahora_final: null,
															observacao: ''
														}
													})
												}}>
													<i class='fa fa-fw fa-plus'></i>
											</a>
										);
									}
								} else {
									return null;
								}
							})}

							<div
								style={{
									...divEixoSeparador,
									width: divEixoSeparador.width * desenhoEixo.length
								}}>
								{eixo.tracao ? 'Tração' : ''}
							</div>
							{this.props.listaEquipamentoEixoPneu.filter(pneu => pneu.id_equipamento_eixo === eixo.id).map((pneu, i) => {
								if (i >= this.props.listaEquipamentoEixoPneu.filter(pneuItem => pneuItem.id_equipamento_eixo === eixo.id).length / 2) {
									let pneuControle = this.props.lista.filter(pneuControle => pneuControle.id_equipamento_eixo_pneu == pneu.id && !pneuControle.datahora_final)[0];
									if (pneuControle) {
										return (
											<a class='btn' style={divEixoPneu}
												onClick={() => {
													this.setState({
														...this.state,
														pneuControleAlteracao: pneuControle
													});
												}}>
												{pneuControle.numero_fogo}
											</a>
										);
									} else {
										return (
											<a class='btn' style={divEixoPneuVazio}
												onClick={() => {
													this.props.setModoTelaEquipamento('selecionarPneu');
													this.setState({
														...this.state,
														pneuControleAux: {
															id_pneu: null,
															id_equipamento: this.props.registro.id,
															id_equipamento_eixo_pneu: pneu.id,
															estepe: false,
															datahora_inicial: DateFormat.getDataHoraAtual(),
															km_acumulada: 0,
															hr_acumulada: 0,
															datahora_final: null,
															observacao: ''
														}
													});
												}}>
													<i class='fa fa-fw fa-plus'></i>
											</a>
										);
									}
								} else {
									return null;
								}
							})}
						</div>
					);
				})}

				<label style={{ fontSize: 18, marginTop: 20, marginBottom: 20 }}>Estepes</label>
				<div style={divEixo}>
					{this.props.lista.filter(pneuControle => pneuControle.estepe && !pneuControle.datahora_final).map(pneuControle => {
						return (
							<a class='btn' style={divEixoPneu}
								onClick={() => {
									this.setState({
										...this.state,
										pneuControleAlteracao: pneuControle
									});
								}}>
								{pneuControle.numero_fogo}
							</a>
						);
					})}
					<a class='btn' style={divEixoPneuVazio}
						onClick={() => {
							this.props.setModoTelaEquipamento('selecionarPneu');
							this.setState({
								...this.state,
								pneuControleAux: {
									id_pneu: null,
									id_equipamento: this.props.registro.id,
									estepe: true,
									datahora_inicial: DateFormat.getDataHoraAtual(),
									km_acumulada: 0,
									hr_acumulada: 0,
									datahora_final: null,
									observacao: ''
								}
							});
						}}>
							<i class='fa fa-fw fa-plus'></i>
					</a>
				</div>
			</div>
		)
	}

	telaSelecionarPneu() {

		return (
			<div>
				<BoxButtonFooter>
					<Button
						text='Cancelar'
						type={'warning'}
						icon={'fa fa-chevron-left'}
						event={() => this.props.setModoTelaEquipamento('estrutura')} />
				</BoxButtonFooter>
				<div className='form-group'>
		            <input className='form-control'
						value={this.state.filtroPneu}
						onChange={data => {
							this.setState({
								...this.state,
								filtroPneu: data.target.value
							});
						}}
		                 placeholder='Pesquisar...'
		                 type='text' />
		        </div>
				<Table>
					<THead>
						<Tr>
							<Th>Nr Fogo</Th>
							<Th>DOT</Th>
							<Th>Dimensão</Th>
							<Th>Desenho</Th>
							<Th>Tipo</Th>
							<Th>Marca</Th>
							<Th alignRight>Km Total</Th>
							<Th alignRight>Hr Total</Th>
							<Th>Status</Th>
							<Th alignCenter acoes>Selecionar</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.listaPneu.filter(item => {
							if (this.state.filtroPneu) {
								let filtro = this.state.filtroPneu.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

								return String(item.numero_fogo).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1
									|| String(item.dot).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1
									|| String(item.codigo_dimensao).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1
									|| item.nome_desenho.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1
									|| item.nome_tipo.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1
									|| item.nome_marca.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1
									|| item.status.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtro) != -1;
							} else {
								return true;
							}
						}).map(item => (
							<Tr key={item.id}>
								<Td>{item.numero_fogo}</Td>
								<Td>{item.dot}</Td>
								<Td>{item.codigo_dimensao}</Td>
								<Td>{item.nome_desenho}</Td>
								<Td>{item.nome_tipo}</Td>
								<Td>{item.nome_marca}</Td>
								<Td alignRight>{item.km_acumulada}</Td>
								<Td alignRight>{item.hr_acumulada}</Td>
								<Td>{item.status}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'success'}
										icon={'fa fa-check'}
										disabled={item.id_status != 1}
										event={() => {
											this.props.salvar({
												...this.state.pneuControleAux,
												id_pneu: item.id
											});
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</div>
		);
	}

	telaCadastro(readOnly) {

		let divPrincipal = {
			paddingLeft: 10,
			paddingRight: 10,
			paddingBotoom: 10
		}

		let divTitulo = {
			textAlign: 'center',
			fontSize: 18,
			marginBottom: 20
		}

		return (
			<div style={divPrincipal}>
				<Row style={divTitulo}>
					<label>Número a Fogo: {this.state.pneuControleAlteracao.numero_fogo}</label>
				</Row>
				<Row>
					<Grid cols='12 12 6 4'>
				        <div className='form-group'>
				            <label>Data e Hora Inicial</label>
				            <input className='form-control'
								placeholder='Informe a Data e hora inicial'
								type='text'
								value={DateFormat.formatarDataHoraSqlParaTela(this.state.pneuControleAlteracao.datahora_inicial)}
								readOnly='readOnly' />
				        </div>
				    </Grid>
				</Row>
				{this.props.registro.hodometro ? (
					<Row>
						<Grid cols='12 12 6 4'>
							<div className='form-group'>
								<label>Km Acumulada *</label>
								<NumberFormat className='form-control'
									placeholder='Informe a Km acumulada'
									decimalSeparator={","}
									decimalScale={0}
									fixedDecimalScale={0}
									value={this.state.pneuControleAlteracao.km_acumulada || null}
									onChange={data => {
										this.setState({
											pneuControleAlteracao: {
												...this.state.pneuControleAlteracao,
												km_acumulada: data.target.value
											}
										})
									}} />
							</div>
						</Grid>
					</Row>
				) : null}
				{this.props.registro.horimetro ? (
					<Row>
						<Grid cols='12 12 6 4'>
							<div className='form-group'>
								<label>Hr Acumulada *</label>
								<NumberFormat className='form-control'
									placeholder='Informe as horas acumuladas'
									decimalSeparator={","}
									decimalScale={0}
									fixedDecimalScale={0}
									value={this.state.pneuControleAlteracao.hr_acumulada || null}
									onChange={data => {
										this.setState({
											pneuControleAlteracao: {
												...this.state.pneuControleAlteracao,
												hr_acumulada: data.target.value
											}
										})
									}} />
							</div>
						</Grid>
					</Row>
				) : null}
				<Row>
					<Grid cols='12 12 12 12'>
						<div className='form-group'>
							<label>Observação</label>
							<input className='form-control'
								placeholder='Informe as observações'
								type='text'
								value={this.state.pneuControleAlteracao.observacao}
								onChange={data => {
									this.setState({
										pneuControleAlteracao: {
											...this.state.pneuControleAlteracao,
											observacao: data.target.value
										}
									});
								}} />
						</div>
					</Grid>
				</Row>

				<Row style={{ paddingLeft: 24, paddingRight: 32, marginTop: 20 }}>
					<Grid cols='4 4 4 4' style={{ padding: 2 }}>
						<button type='button' class='btn btn-block btn-success'
							disabled={(this.props.registro.hodometro && !this.state.pneuControleAlteracao.estepe && (!this.state.pneuControleAlteracao.km_acumulada || this.state.pneuControleAlteracao.km_acumulada <= 0))
								|| (this.props.registro.horimetro && !this.state.pneuControleAlteracao.estepe && (!this.state.pneuControleAlteracao.hr_acumulada || this.state.pneuControleAlteracao.hr_acumulada <= 0))}
							onClick={() => {
								this.props.salvar({
									...this.state.pneuControleAlteracao,
									datahora_final: DateFormat.getDataHoraAtual()
								});
								this.setState({
									pneuControleAlteracao: null
								});
							}}>
							Remover Pneu
						</button>
					</Grid>
					<Grid cols='4 4 4 4' style={{ padding: 2 }}>
						<button type='button' class='btn btn-block btn-danger'
							onClick={() => {
								this.props.excluir(this.state.pneuControleAlteracao);
								this.setState({
									pneuControleAlteracao: null
								});
							}}>
							Excluir Operação
						</button>
					</Grid>
					<Grid cols='4 4 4 4' style={{ padding: 2 }}>
						<button type='button' class='btn btn-block btn-warning'
							onClick={() => {
								this.setState({
									pneuControleAlteracao: null
								});
							}}>
							Voltar
						</button>
					</Grid>
				</Row>
			</div>
		);
	}

}

PneuControleEquipamentoForm = reduxForm({destroyOnUnmount: false})(PneuControleEquipamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.pneuControle.registro,
	lista: state.pneuControle.lista,
	listaEquipamento: state.pneuControle.listaEquipamento,
	listaPneu: state.pneuControle.listaPneu,
	listaEquipamentoEixo: state.pneuControle.listaEquipamentoEixo,
	listaEquipamentoEixoPneu: state.pneuControle.listaEquipamentoEixoPneu,
	modoTelaEquipamento: state.pneuControle.modoTelaEquipamento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, getLista, getListaEquipamentoEixo, getListaEquipamentoEixoPneu, getListaPneu, setModoTelaEquipamento, salvar, excluir
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuControleEquipamentoForm);
