import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return (dispatch, getState) => {
		dispatch({
	        type: 'ORDEM_SERVICO_MODO_TELA',
	        payload: {
				modo: modo,
				registro: registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id,
				id_auxiliar: registro.id_auxiliar || getState().auth.usuario.id,
				id_operador: registro.id_operador || getState().auth.usuario.id
			}
    	});
	}
}
export function initForm(registro = { data_agendamento: DateFormat.getDataAtual() }) {
    return (dispatch, getState) => {
		dispatch([
	        initialize('ordemServicoForm', {
				...registro,
				data_agendamento: registro.data_agendamento ? DateFormat.formatarDataSqlParaTela(registro.data_agendamento) : null,
				id_usuario_agendamento: registro.id_usuario_agendamento || getState().auth.usuario.id
			})
		]);
	}
}

export function setFiltro(filtro) {
    return {
        type: 'ORDEM_SERVICO_FILTRO',
        payload: filtro
    };
}

export function getLista(id_empresa) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServico?id_empresa=${id_empresa}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(getState().ordemServico.filtro.dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(getState().ordemServico.filtro.dataFinal)}
			&id_usuario=${getState().ordemServico.filtro.id_usuario}
			&id_equipamento=${getState().ordemServico.filtro.id_equipamento}`)
		.then(resp => {
            dispatch({
				type: 'ORDEM_SERVICO_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEquipamento(id_empresa) {
    const request = axios.get(`${consts.API_URL}/equipamento/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_EQUIPAMENTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaServico(id_empresa) {
    const request = axios.get(`${consts.API_URL}/servico/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_SERVICO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente(id_empresa) {
    const request = axios.get(`${consts.API_URL}/cliente/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ORDEM_SERVICO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/ordemServico`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			data_agendamento: registro.data_agendamento ? DateFormat.formatarDataTelaParaSql(registro.data_agendamento) : null
		}).then(resp => {
			if (registro.id) {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			} else {
				axios.get(`${consts.API_URL}/ordemServico?id=${resp.data}`).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
    				dispatch(getLista(getState().auth.usuario.id_empresa));
					dispatch(initForm({
						...resp.data[0]
					}));
				});
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/ordemServico?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function encerrarOrdemServico(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServico?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/ordemServico`, {
				...resp.data[0],
				id_usuario_fechamento: getState().auth.usuario.id,
				datahora_fechamento: DateFormat.getDataHoraAtual()
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function reabrirOrdemServico(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServico?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/ordemServico`, {
				...resp.data[0],
				datahora_fechamento: null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function cancelarOrdemServico(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/ordemServico?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/ordemServico`, {
				...resp.data[0],
				id_usuario_cancelamento: getState().auth.usuario.id,
				datahora_cancelamento: DateFormat.getDataHoraAtual()
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

//Item
export function setModoItem(modo, registro = {}) {
    return {
        type: 'ORDEM_SERVICO_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormItem(registro = {}) {
    return [
        initialize('ordemServicoItemForm', {
			...registro,
			datahora_inicio: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_inicio),
			datahora_fim: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_fim),
			hodometro_inicio: parseFloat(registro.hodometro_inicio || 0).toFixed(2).replace('.', ','),
			hodometro_fim: parseFloat(registro.hodometro_fim || 0).toFixed(2).replace('.', ','),
			horimetro_inicio: parseFloat(registro.horimetro_inicio || 0).toFixed(2).replace('.', ','),
			horimetro_fim: parseFloat(registro.horimetro_fim || 0).toFixed(2).replace('.', ','),
			quantidade: parseFloat(registro.quantidade || 0).toFixed(2).replace('.', ','),
			valor_hora: parseFloat(registro.valor_hora || 0).toFixed(2).replace('.', ','),
			valor_unitario: parseFloat(registro.valor_unitario || 0).toFixed(2).replace('.', ','),
			valor_km: parseFloat(registro.valor_km || 0).toFixed(2).replace('.', ','),
			valor_diaria: parseFloat(registro.valor_diaria || 0).toFixed(2).replace('.', ','),
			valor_total: parseFloat(registro.valor_total || 0).toFixed(2).replace('.', ','),
		})
    ];
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/ordemServicoItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_inicio: registro.datahora_inicio ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio) : null,
			datahora_fim: registro.datahora_fim ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(getState().auth.usuario.id_empresa, registro.id_ordem_servico));
			dispatch(setModoItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/ordemServicoItem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_empresa, registro.id_ordem_servico));
			dispatch(setModoItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaItem(id_empresa, id_ordem_servico) {
    const request = axios.get(`${consts.API_URL}/ordemServicoItem?id_empresa=${id_empresa}&id_ordem_servico=${id_ordem_servico}`);
    return {
        type: 'ORDEM_SERVICO_ITEM_LISTADO',
        payload: request
    };
}
