import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import InputMask from 'react-input-mask';

import { setModoTela, initForm, getListaEstado, getListaCidade } from './fornecedorActions';

class FornecedorForm extends Component {

    componentWillMount() {
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='razao_social'
							component={LabelAndInput}
							label='Razão Social *'
							placeholder='Informe a Razão Social'
							cols='12 10 6 4'
							readOnly={readOnly} />

						<Field
							name='nome_fantasia'
							component={LabelAndInput}
							label='Nome Fantasia *'
							placeholder='Informe o Nome Fantasia'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='cpf'
							component={LabelAndInputMask}
							label='CPF'
							placeholder='Informe o CPF'
							mask="999.999.999-99"
							maskChar=" "
							cols='12 6 6 3'
							readOnly={readOnly} />

						<Field
							name='cnpj'
							component={LabelAndInputMask}
							label='CNPJ'
							placeholder='Informe o CNPJ'
							mask="99.999.999/9999-99"
							maskChar=" "
							cols='12 6 6 3'
							readOnly={readOnly} />

						<Field
							name='inscricao_estadual'
							component={LabelAndInput}
							label='IE'
							placeholder='Informe a IE'
							cols='12 6 3 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='logradouro'
							component={LabelAndInput}
							label='Logradouro'
							placeholder='Informe o Logradouro'
							cols='12 12 5 4'
							readOnly={readOnly} />

						<Field
							name='numero'
							component={LabelAndInput}
							label='Número'
							placeholder='Informe o Número'
							cols='12 4 3 2'
							readOnly={readOnly} />

						<Field
							name='complemento'
							component={LabelAndInput}
							label='Complemento'
							placeholder='Informe o Complemento'
							cols='12 8 4 3'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='bairro'
							component={LabelAndInput}
							label='Bairro'
							placeholder='Informe o Bairro'
							cols='12 6 6 3'
							readOnly={readOnly} />

						<Field
							name='cep'
							component={LabelAndInputMask}
							label='CEP'
							placeholder='Informe o CEP'
							mask="99.999-999"
							maskChar=" "
							cols='12 6 6 3'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Select
							name='id_estado'
							options={this.props.listaEstados}
							value={this.props.formularioValues.id_estado}
							onChange={(value) => {
								this.props.initForm({
									...this.props.formularioValues,
									id_estado: value
								});
							}}
							label='Estado'
							cols='12 6 6 3'
							placeholder='Selecione o Estado'
							readOnly={readOnly} />

						<Field
							name='id_cidade'
							component={Select}
							options={this.props.listaCidades.filter(item => item.id_estado == this.props.formularioValues.id_estado)}
							label='Cidade'
							cols='12 6 6 3'
							placeholder='Selecione a Cidade'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='combustivel'
							component={LabelAndCheckbox}
							label='Combustível'
							cols='6 3 2 2'
							readOnly={readOnly} />

						<Field
							name='peca'
							component={LabelAndCheckbox}
							label='Peças'
							cols='6 3 2 2'
							readOnly={readOnly} />

						<Field
							name='servico'
							component={LabelAndCheckbox}
							label='Serviço'
							cols='6 3 2 2'
							readOnly={readOnly} />

						<Field
							name='pneu'
							component={LabelAndCheckbox}
							label='Pneu'
							cols='6 3 2 2'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Grid>
							<label>Contatos</label>
							<Table>
								<THead>
									<Tr>
										<Th>Nome</Th>
										<Th>Telefone</Th>
										<Th>Celular</Th>
										<Th>Email</Th>
										<Th>Observação</Th>
										<Th alignCenter acoes>Ações</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.formularioValues.contato.map(item => (
										<Tr key={item.id}>
											<Td>
												<input className='form-control'
									                 placeholder='Informe o nome'
													 type='text'
													 value={item.nome}
													 onChange={(data) => {
														 let lista = this.props.formularioValues.contato.map(contato => {
															 if (contato.id == item.id) {
																 return {
																	...contato,
																	nome: data.target.value
																 }
															 }
															 return contato;
	 													 });
														 this.props.initForm({
															 ...this.props.formularioValues,
															 contato: lista
														 });
													 }} />
											</Td>
											<Td>
												<InputMask
													className='form-control'
													mask="(99) 9999-9999"
													maskChar=" "
													value={item.telefone}
													placeholder="Informe o telefone"
													onChange={(data) => {
														let lista = this.props.formularioValues.contato.map(contato => {
															if (contato.id == item.id) {
																return {
																   ...contato,
																   telefone: data.target.value
																}
															}
															return contato;
														});
														this.props.initForm({
															...this.props.formularioValues,
															contato: lista
														});
													}} />
											</Td>
											<Td>
												<InputMask
													className='form-control'
													mask="(99) 99999-9999"
													maskChar=" "
													placeholder="Informe o celular"
													value={item.celular}
													onChange={(data) => {
														let lista = this.props.formularioValues.contato.map(contato => {
															if (contato.id == item.id) {
																return {
																   ...contato,
																   celular: data.target.value
																}
															}
															return contato;
														});
														this.props.initForm({
															...this.props.formularioValues,
															contato: lista
														});
													}} />
											</Td>
											<Td>
												<input className='form-control'
									                 placeholder='Informe o email'
													 type='text'
													 value={item.email}
													 onChange={(data) => {
														 let lista = this.props.formularioValues.contato.map(contato => {
															 if (contato.id == item.id) {
																 return {
																	...contato,
																	email: data.target.value
																 }
															 }
															 return contato;
	 													 });
														 this.props.initForm({
															 ...this.props.formularioValues,
															 contato: lista
														 });
													 }} />
											</Td>
											<Td>
												<input className='form-control'
													 placeholder='Informe observações'
													 type='text'
													 value={item.observacao}
													 onChange={(data) => {
														 let lista = this.props.formularioValues.contato.map(contato => {
															 if (contato.id == item.id) {
																 return {
																	...contato,
																	observacao: data.target.value
																 }
															 }
															 return contato;
														 });
														 this.props.initForm({
															 ...this.props.formularioValues,
															 contato: lista
														 });
													 }} />
											</Td>
											<Td alignCenter>
												<ButtonTable
													type={'danger'}
													icon={'fa fa-trash-o'}
													event={() => {
														let lista = this.props.formularioValues.contato.filter(contato => contato.id != item.id);
														this.props.initForm({
															...this.props.formularioValues,
															contato: lista
														});
													}} />
											</Td>
										</Tr>
									))}
									<Tr>
										<Td alignCenter colspan={6} onClick={() => {
											let lista = this.props.formularioValues.contato;
											lista.push({
												id: new Date().getTime(),
												nome: '',
												telefone: '',
												celular: '',
												observacao: ''
											});
											this.props.initForm({
												...this.props.formularioValues,
												contato: lista
											});
											this.setState({...this.state});
										}}><i class='fa fa-plus' aria-hidden='true' style={{ fontSize: 14 }}></i><label style={{ fontSize: 16, marginLeft: 5 }}>Clique aqui para adicionar</label></Td>
									</Tr>
								</TBody>
							</Table>
						</Grid>
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

FornecedorForm = reduxForm({form: 'fornecedorForm', destroyOnUnmount: false})(FornecedorForm);
const mapStateToProps = state => ({
	registro: state.fornecedor.registro,
	listaEstados: state.fornecedor.listaEstados,
	listaCidades: state.fornecedor.listaCidades,
	formularioValues: getFormValues('fornecedorForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaEstado, getListaCidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FornecedorForm);
