const INITIAL_STATE = {
	registro: {}, modoTela: 'lista', modoTelaEquipamento: 'estrutura',
	lista: [], listaEquipamento: [], listaPneu: [], listaEquipamentoEixo: [], listaEquipamentoEixoPneu: [],
	listaFornecedor: [], listaMarca: [],
	listaEsteira: [], listaEsteiraManutencao: [], listaRolete: [],
	modoTelaEsteira: 'lista', modoTelaEsteiraManutencao: 'lista', modoTelaRolete: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PNEU_CONTROLE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				modoTelaEquipamento: 'estrutura',
				registro: action.payload.registro
			};
		case 'PNEU_CONTROLE_MODO_TELA_EQUIPAMENTO':
            return { ...state,
				modoTelaEquipamento: action.payload.modo
			};
        case 'PNEU_CONTROLE_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'PNEU_CONTROLE_EQUIPAMENTO_LISTADO':
            return { ...state, listaEquipamento: action.payload.data };
		case 'PNEU_CONTROLE_PNEU_LISTADO':
			return { ...state, listaPneu: action.payload.data };
		case 'PNEU_CONTROLE_EIXO_LISTADO':
            return { ...state, listaEquipamentoEixo: action.payload.data };
		case 'PNEU_CONTROLE_EIXO_PNEU_LISTADO':
            return { ...state, listaEquipamentoEixoPneu: action.payload.data };
		case 'PNEU_CONTROLE_FORNECEDOR_SELECT_LISTADO':
            return { ...state, listaFornecedor: action.payload.data };
		case 'PNEU_CONTROLE_MARCA_SELECT_LISTADO':
            return { ...state, listaMarca: action.payload.data };
		case 'PNEU_CONTROLE_ESTEIRA_MODO_TELA':
            return { ...state,
				modoTelaEsteira: action.payload.modo
			};
		case 'PNEU_CONTROLE_ESTEIRA_LISTADO':
            return { ...state, listaEsteira: action.payload.data };
		case 'PNEU_CONTROLE_ESTEIRA_MANUTENCAO_MODO_TELA':
            return { ...state,
				modoTelaEsteiraManutencao: action.payload.modo
			};
		case 'PNEU_CONTROLE_ESTEIRA_MANUTENCAO_LISTADO':
            return { ...state, listaEsteiraManutencao: action.payload.data };
		case 'PNEU_CONTROLE_ROLETE_MODO_TELA':
            return { ...state,
				modoTelaRolete: action.payload.modo
			};
		case 'PNEU_CONTROLE_ROLETE_LISTADO':
            return { ...state, listaRolete: action.payload.data };
        default:
            return state;
    }
}
