import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import { setModoTela, getListaCombustivelMedia } from './combustivelActions';

class CombustivelForm extends Component {

    componentWillMount() {
		
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *'
							placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_combustivel_media'
							component={Select}
							options={this.props.listaCombustivelMedia}
							label='Tipo de Média *'
							cols='12 10 6 4'
							placeholder='Selecione o Tipo de Média'
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

CombustivelForm = reduxForm({form: 'combustivelForm', destroyOnUnmount: false})(CombustivelForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaCombustivelMedia: state.combustivel.listaCombustivelMedia,
	registro: state.combustivel.registro,
	formularioValues: getFormValues('combustivelForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaCombustivelMedia }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CombustivelForm);
