import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioTipo from '../usuarioTipo/usuarioTipo';
import Usuario from '../usuario/usuario';
import Fornecedor from '../fornecedor/fornecedor';
import Cliente from '../cliente/cliente';
import Produto from '../produto/produto';
import ProdutoCategoria from '../produtoCategoria/produtoCategoria';
import ProdutoMarca from '../produtoMarca/produtoMarca';
import UnidadeMedida from '../unidadeMedida/unidadeMedida';
import Especie from '../especie/especie';
import Equipamento from '../equipamento/equipamento';
import Servico from '../servico/servico';
import Combustivel from '../combustivel/combustivel';
import Pneu from '../pneu/pneu';
import PneuDimensao from '../pneuDimensao/pneuDimensao';
import PneuDesenho from '../pneuDesenho/pneuDesenho';
import PneuTipo from '../pneuTipo/pneuTipo';
import PneuMarca from '../pneuMarca/pneuMarca';
import PneuControle from '../pneuControle/pneuControle';
import Manutencao from '../manutencao/manutencao';
import Tanque from '../tanque/tanque';
import CombustivelEntrada from '../combustivelEntrada/combustivelEntrada';
import CombustivelAjuste from '../combustivelAjuste/combustivelAjuste';
import CombustivelTransferencia from '../combustivelTransferencia/combustivelTransferencia';
import CombustivelSaida from '../combustivelSaida/combustivelSaida';
import Entrada from '../entrada/entrada';
import OrdemServico from '../ordemServico/ordemServico';
import OrdemServicoFechamento from '../ordemServicoFechamento/ordemServicoFechamento';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper'>
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuarioTipo' component={UsuarioTipo} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/fornecedor' component={Fornecedor} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/produto' component={Produto} />
			<Route path='/produtoCategoria' component={ProdutoCategoria} />
			<Route path='/produtoMarca' component={ProdutoMarca} />
			<Route path='/unidadeMedida' component={UnidadeMedida} />
			<Route path='/especie' component={Especie} />
			<Route path='/equipamento' component={Equipamento} />
			<Route path='/servico' component={Servico} />
			<Route path='/combustivel' component={Combustivel} />
			<Route path='/pneu' component={Pneu} />
			<Route path='/pneuDimensao' component={PneuDimensao} />
			<Route path='/pneuDesenho' component={PneuDesenho} />
			<Route path='/pneuTipo' component={PneuTipo} />
			<Route path='/pneuMarca' component={PneuMarca} />
			<Route path='/pneuControle' component={PneuControle} />
			<Route path='/manutencao' component={Manutencao} />
			<Route path='/Entrada' component={Entrada} />
			<Route path='/tanque' component={Tanque} />
			<Route path='/combustivelEntrada' component={CombustivelEntrada} />
			<Route path='/combustivelAjuste' component={CombustivelAjuste} />
			<Route path='/combustivelTransferencia' component={CombustivelTransferencia} />
			<Route path='/combustivelSaida' component={CombustivelSaida} />
			<Route path='/ordemServico' component={OrdemServico} />
			<Route path='/ordemServicoFechamento' component={OrdemServicoFechamento} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
