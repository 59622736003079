import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import MenuItem from './menuItem'
import MenuTree from './menuTree'

import { getLista as getListaUsuario } from '../../usuario/usuarioActions';

class Menu extends Component {

	componentWillMount() {
        this.props.getListaUsuario(this.props.sessao.id_empresa);
    }

	render() {
		return (
		    <ul className='sidebar-menu'>
		        <MenuItem path='/' label='Dashboard' icon='dashboard' />

				{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_usuario ? (
					<MenuTree label='Acesso' icon='user'>
			            <MenuItem path='usuarioTipo' label='Tipo de Usuário' icon='' />
			            <MenuItem path='usuario' label='Usuário' icon='' />
			        </MenuTree>
				) : null}

				{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
			        <MenuTree label='Cadastro' icon='edit'>
						<MenuItem path='fornecedor' label='Fornecedor' icon='' />
						<MenuItem path='cliente' label='Cliente' icon='' />
						<MenuItem path='especie' label='Espécie do Equipamento' icon='' />
						<MenuItem path='equipamento' label='Equipamento' icon='' />
			        </MenuTree>
				) : null}

				{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_manutencao ? (
					<MenuTree label='Manutenção' icon='wrench'>
						<MenuItem path='manutencao' label='Ordem de Manutenção' icon='' />
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='unidadeMedida' label='Unidade de Medida' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='produtoCategoria' label='Categoria' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='produtoMarca' label='Marca' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='produto' label='Produto' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='entrada' label='Entrada' icon='' />
						) : null}
					</MenuTree>
				) : null}

				{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_combustivel ? (
					<MenuTree label='Combustível' icon='battery-full'>
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='combustivel' label='Cadastro' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='tanque' label='Tanque' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='combustivelAjuste' label='Ajuste de Saldo' icon='' />
						) : null}
						<MenuItem path='combustivelEntrada' label='Entrada' icon='' />
						<MenuItem path='combustivelTransferencia' label='Transferência entre Tanques' icon='' />
						<MenuItem path='combustivelSaida' label='Abastecimento' icon='' />
					</MenuTree>
				) : null}

				{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_pneu ? (
					<MenuTree label='Pneus' icon='truck'>
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='pneu' label='Cadastro' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='pneuDimensao' label='Dimensão' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='pneuDesenho' label='Desenho' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='pneuTipo' label='Tipo' icon='' />
						) : null}
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='pneuMarca' label='Marca' icon='' />
						) : null}
						<MenuItem path='pneuControle' label='Controle de Pneus' icon='' />
					</MenuTree>
				) : null}

				{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_servico ? (
					<MenuTree label='Serviço' icon='plus-square'>
						{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
							<MenuItem path='servico' label='Cadastro' icon='' />
						) : null}
						<MenuItem path='ordemServico' label='Ordem de Serviço' icon='' />
						<MenuItem path='ordemServicoFechamento' label='Fechamento' icon='' />
					</MenuTree>
				) : null}
		    </ul>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0]
});
const mapDispatchToProps = dispatch => bindActionCreators({ getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
