import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import DateFormat from '../common/dateFormat/DateFormat';

import { setModoTela, getListaUnidadeMedida, salvarUnidadeMedida, getListaCategoria, salvarCategoria, getListaMarca, salvarMarca, getUltimasCompras, getListaMovimento } from './produtoActions';

class ProdutoForm extends Component {

	state = {
		modoCadastroCategoria: false,
		categoria: null,
		modoCadastroMarca: false,
		marca: null,
		modoCadastroUnidadeMedida: false,
		unidadeMedida: null
	}

    componentWillMount() {
		this.props.getListaUnidadeMedida(this.props.sessao.id_empresa);
		this.props.getListaCategoria(this.props.sessao.id_empresa);
		this.props.getListaMarca(this.props.sessao.id_empresa);
		this.props.getUltimasCompras(this.props.formularioValues.id || 0);
		this.props.getListaMovimento(this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *'
							placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='codigo'
							component={LabelAndInput}
							label='Código'
							placeholder='Informe o Código'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					{this.props.formularioValues.id ? (
						<Row>
							<Field
								name='id'
								component={LabelAndInput}
								label='Sequencial *'
								placeholder='Informe o Código'
								cols='12 10 6 4'
								readOnly={readOnly} />
						</Row>
					) : null}

					<Row>
						<Field
							name='id_unidade_medida'
							component={SelectAlteracao}
							options={this.props.listaUnidadeMedida}
							label='Unidade de Medida *'
							cols='12 10 6 4'
							placeholder='Selecione a Unidade de Medida'
							modoCadastro={this.state.modoCadastroUnidadeMedida}
							acaoBotaoLabel={this.props.formularioValues.id_unidade_medida && this.props.formularioValues.id_unidade_medida != 0 ? 'Alterar' : 'Nova'}
							acaoBotao={() => {
								let itemLista = this.props.listaUnidadeMedida.filter(item => item.id == this.props.formularioValues.id_unidade_medida)[0];

								this.setState({
									...this.state,
									modoCadastroUnidadeMedida: true,
									unidadeMedida: {
										id: this.props.formularioValues.id_unidade_medida || null,
										nome: itemLista ? itemLista.nome : null,
										sigla: itemLista ? itemLista.sigla : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										<input type='text' className='form-control' style={{ flex: 2 }}
											placeholder='Nome'
											value={this.state.unidadeMedida ? this.state.unidadeMedida.nome : ''}
											onChange={data => {
												this.setState({
													...this.state,
													unidadeMedida: {
														id: this.state.unidadeMedida ? this.state.unidadeMedida.id : null,
														nome: data.target.value,
														sigla: this.state.unidadeMedida ? this.state.unidadeMedida.sigla : null
													}
												})
											}} />
										<input type='text' className='form-control' style={{ flex: 1 }}
											placeholder='Sigla'
											value={this.state.unidadeMedida ? this.state.unidadeMedida.sigla : ''}
											onChange={data => {
												this.setState({
													...this.state,
													unidadeMedida: {
														id: this.state.unidadeMedida ? this.state.unidadeMedida.id : null,
														nome: this.state.unidadeMedida ? this.state.unidadeMedida.nome : null,
														sigla: data.target.value
													}
												})
											}} />
									</div>
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarUnidadeMedida(this.state.unidadeMedida, this.props.formularioValues);
												this.setState({
													...this.state,
													modoCadastroUnidadeMedida: false,
													unidadeMedida: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_categoria'
							component={SelectAlteracao}
							options={this.props.listaCategoria}
							label='Categoria *'
							cols='12 10 6 4'
							placeholder='Selecione a Categoria'
							modoCadastro={this.state.modoCadastroCategoria}
							acaoBotaoLabel={this.props.formularioValues.id_categoria && this.props.formularioValues.id_categoria != 0 ? 'Alterar' : 'Nova'}
							acaoBotao={() => {
								let itemLista = this.props.listaCategoria.filter(item => item.id == this.props.formularioValues.id_categoria)[0];

								this.setState({
									...this.state,
									modoCadastroCategoria: true,
									categoria: {
										id: this.props.formularioValues.id_categoria || null,
										nome: itemLista ? itemLista.valor : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<input type='text' className='form-control'
										placeholder='Informe o nome'
										value={this.state.categoria ? this.state.categoria.nome : ''}
										onChange={data => {
											this.setState({
												...this.state,
												categoria: {
													id: this.state.categoria ? this.state.categoria.id : null,
													nome: data.target.value
												}
											})
										}} />
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarCategoria(this.state.categoria, this.props.formularioValues);
												this.setState({
													...this.state,
													modoCadastroCategoria: false,
													categoria: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_marca'
							component={SelectAlteracao}
							options={this.props.listaMarca}
							label='Marca'
							cols='12 10 6 4'
							placeholder='Selecione a Marca'
							modoCadastro={this.state.modoCadastroMarca}
							acaoBotaoLabel={this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? 'Alterar' : 'Nova'}
							acaoBotao={() => {
								let itemLista = this.props.listaMarca.filter(item => item.id == this.props.formularioValues.id_marca)[0];

								this.setState({
									...this.state,
									modoCadastroMarca: true,
									marca: {
										id: this.props.formularioValues.id_marca || null,
										nome: itemLista ? itemLista.valor : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<input type='text' className='form-control'
										placeholder='Informe o nome'
										value={this.state.marca ? this.state.marca.nome : ''}
										onChange={data => {
											this.setState({
												...this.state,
												marca: {
													id: this.state.marca ? this.state.marca.id : null,
													nome: data.target.value
												}
											})
										}} />
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarMarca(this.state.marca, this.props.formularioValues);
												this.setState({
													...this.state,
													modoCadastroMarca: false,
													marca: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='referencia_externa'
							component={LabelAndInput}
							label='Referência Externa'
							placeholder='Informe a Referência Externa'
							cols='12 12 10 8'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='alocacao'
							component={LabelAndInput}
							label='Alocação'
							placeholder='Informe o alocacao'
							cols='12 12 12 12'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='custo'
							component={LabelAndInputNumber}
							label='Custo do Produto'
							placeholder='Custo do Produto'
							cols='12 6 4 2'
							casas={4}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>

				{this.props.listaUltimasCompras.length > 0 ? (
					<Row>
						<Grid>
							<Table>
								<THead>
									<Tr>
										<Th>Últimas compras</Th>
										<Th>Fornecedor</Th>
										<Th alignRight>Quantidade</Th>
										<Th alignRight>Valor Unitário</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaUltimasCompras.map(item => (
										<Tr key={item.id}>
											<Td>{DateFormat.formatarDataSqlParaTela(item.data_entrada)}</Td>
											<Td>{item.nome_fornecedor}</Td>
											<Td alignRight>{item.quantidade}</Td>
											<Td alignRight>{String(item.valor_unitario, 0).replace('.', ',')}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</Grid>
					</Row>
				) : null}

				{this.props.listaMovimento.length > 0 ? (
					<Row>
						<Grid>
							<Table>
								<THead>
									<Tr>
										<Th>Movimentos</Th>
										<Th>Data</Th>
										<Th>Número</Th>
										<Th alignRight>Quantidade</Th>
										<Th alignRight>Saldo</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaMovimento.map(item => (
										<Tr key={item.ordem}>
											<Td>{item.tipo}</Td>
											<Td>{DateFormat.formatarDataSqlParaTela(item.datahora)}</Td>
											<Td>{item.numero}</Td>
											<Td alignRight>{item.quantidade.toFixed(4).replace('.', ',')}</Td>
											<Td alignRight>{item.saldo.toFixed(4).replace('.', ',')}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</Grid>
					</Row>
				) : null}

			</ContentCard>
        )
    }


}

ProdutoForm = reduxForm({form: 'produtoForm', destroyOnUnmount: false})(ProdutoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaUnidadeMedida: state.produto.listaUnidadeMedida,
	listaCategoria: state.produto.listaCategoria,
	listaMarca: state.produto.listaMarca,
	listaUltimasCompras: state.produto.listaUltimasCompras,
	listaMovimento: state.produto.listaMovimento,
	registro: state.produto.registro,
	formularioValues: getFormValues('produtoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaUnidadeMedida, salvarUnidadeMedida, getListaCategoria, salvarCategoria, getListaMarca, salvarMarca, getUltimasCompras, getListaMovimento }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProdutoForm);
