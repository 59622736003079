import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';

import { setModoTela, initForm, getListaTanque, getListaBomba, getListaCombustivel, getListaUsuario } from './combustivelTransferenciaActions';

class CombustivelTransferenciaForm extends Component {

    componentWillMount() {
		
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>

					<Row>
						<Field
							name='datahora_transferencia'
							component={LabelAndInputMask}
							label='Data e Hora *'
							placeholder='Informe a Data e Hora'
							mask="99/99/9999 99:99"
							maskChar=" "
							cols='12 6 4 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Select
							name='id_tanque_saida'
							options={this.props.listaTanque}
							value={this.props.formularioValues.id_tanque_saida}
							label='Tanque Saída *'
							cols='12 12 4 4'
							placeholder='Selecione o Tanque'
							readOnly={readOnly}
							onChange={(value) => {
								let tanque = value ? this.props.listaTanque.filter(tanque => tanque.id == value)[0] : null;
								let bomba = tanque ? this.props.listaBomba.filter(bomba => bomba.id_tanque == value)[0] : null;
								let combustivel = tanque ? this.props.listaCombustivel.filter(combustivel => combustivel.id == tanque.id_combustivel)[0] : null;

								this.props.initForm({
									...this.props.formularioValues,
									id_tanque_saida: tanque ? tanque.id : null,
									id_bomba_saida: bomba ? bomba.id : null,
									id_combustivel: combustivel ? combustivel.id : null
								}, false);
							}} />

						<Field
							name='id_bomba_saida'
							component={Select}
							options={this.props.listaBomba.filter(bomba => bomba.id_tanque == this.props.formularioValues.id_tanque_saida)}
							label='Bomba Saída *'
							cols='12 12 4 4'
							placeholder='Selecione a Bomba'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_tanque_entrada'
							component={Select}
							options={this.props.listaTanque}
							label='Tanque Entrada *'
							cols='12 12 4 4'
							placeholder='Selecione o Tanque'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_combustivel'
							component={Select}
							options={this.props.listaCombustivel}
							label='Combustível *'
							cols='12 12 4 4'
							placeholder='Selecione o Combustível'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_responsavel'
							component={Select}
							options={this.props.listaUsuario}
							label='Responsável *'
							cols='12 12 4 4'
							placeholder='Selecione o Responsável'
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade *' placeholder='0,0000'
							cols='6 6 3 3'
							casas={this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba_saida)[0]
								? this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba_saida)[0].casas_decimais
								: 0}
							readOnly={readOnly} />
					</Row>

					<Row>
						<Field
							name='acumulador_bomba'
							component={LabelAndInputNumber}
							label='Acumulador da Bomba' placeholder='0,0000'
							cols='6 6 3 3'
							casas={this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba_saida)[0]
								? this.props.listaBomba.filter(bomba => bomba.id == this.props.formularioValues.id_bomba_saida)[0].casas_decimais
								: 0}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

CombustivelTransferenciaForm = reduxForm({form: 'combustivelTransferenciaForm', destroyOnUnmount: false})(CombustivelTransferenciaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.combustivelTransferencia.registro,
	listaTanque: state.combustivelTransferencia.listaTanque,
	listaBomba: state.combustivelTransferencia.listaBomba,
	listaCombustivel: state.combustivelTransferencia.listaCombustivel,
	listaUsuario: state.combustivelTransferencia.listaUsuario,
	formularioValues: getFormValues('combustivelTransferenciaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaTanque, getListaBomba, getListaCombustivel, getListaUsuario }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CombustivelTransferenciaForm);
