import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'TANQUE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('tanqueForm', {
			...registro,
			capacidade: String(registro.capacidade || 0).replace('.', ',')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/tanque?id_empresa=${id_empresa}&listar_descarte=true`);
    return {
        type: 'TANQUE_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/tanque`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			if (registro.id) {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			} else {
				axios.get(`${consts.API_URL}/tanque?id=${resp.data}`).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
    				dispatch(getLista(getState().auth.usuario.id_empresa));
					dispatch(initForm(resp.data[0]));
				});
			}

		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/tanque?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Bomba
export function setModoBomba(modo, registro = {}) {
    return {
        type: 'TANQUE_BOMBA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormBomba(registro = { casas_decimais: 0 }) {
    return [
        initialize('bombaForm', {
			...registro
		})
    ];
}

export function salvarBomba(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/bomba`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaBomba(getState().auth.usuario.id_empresa, registro.id_tanque));
			dispatch(setModoBomba('lista'));
			dispatch(getLista(getState().auth.usuario.id_empresa));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirBomba(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/bomba?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaBomba(registro.id_empresa, registro.id_tanque));
			dispatch(setModoBomba('lista'));
			dispatch(getLista(registro.id_empresa));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}


export function getListaBomba(id_empresa, id_tanque) {
    const request = axios.get(`${consts.API_URL}/bomba?id_empresa=${id_empresa}&id_tanque=${id_tanque}`);
    return {
        type: 'TANQUE_BOMBA_LISTADO',
        payload: request
    };
}
