import React from 'react'

export default props => (
	<button type={'button'} className={`btn btn-flat btn-${props.type}`} onClick={props.event} disabled={props.disabled}>
		<i className={props.icon}></i>
	</button>
)

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
