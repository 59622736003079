import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoRecapagem, getListaDesenho, getListaMarca, initFormRecapagem, salvarDesenho, salvarMarca
} from './pneuActions';

class PneuRecapagemForm extends Component {

	state = {
		modoCadastroDesenho: false,
		desenho: null,
		modoCadastroMarca: false,
		marca: null,
	}

    componentWillMount() {
		this.props.getListaDesenho(this.props.sessao.id_empresa);
		this.props.getListaMarca(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Recapagem' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='id_desenho'
							component={SelectAlteracao}
							options={this.props.listaDesenho}
							label='Desenho *'
							cols='12 12 12 12'
							placeholder='Selecione o Desenho'
							modoCadastro={this.state.modoCadastroDesenho}
							acaoBotaoLabel={this.props.formularioValues.id_desenho && this.props.formularioValues.id_desenho != 0 ? 'Alterar' : 'Novo'}
							acaoBotao={() => {
								let itemLista = this.props.listaDesenho.filter(item => item.id == this.props.formularioValues.id_desenho)[0];

								this.setState({
									...this.state,
									modoCadastroDesenho: true,
									desenho: {
										id: this.props.formularioValues.id_desenho && this.props.formularioValues.id_desenho != 0 ? this.props.formularioValues.id_desenho : null,
										nome: itemLista ? itemLista.valor : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<input type='text' className='form-control'
										placeholder='Informe o nome'
										value={this.state.desenho ? this.state.desenho.nome : ''}
										onChange={data => {
											this.setState({
												...this.state,
												desenho: {
													id: this.state.desenho ? this.state.desenho.id : null,
													nome: data.target.value
												}
											})
										}} />
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarDesenho(this.state.desenho, this.props.formularioValues, 'recapagem');
												this.setState({
													...this.state,
													modoCadastroDesenho: false,
													desenho: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />

						<Field
							name='id_marca'
							component={SelectAlteracao}
							options={this.props.listaMarca}
							label='Marca *'
							cols='12 12 12 12'
							placeholder='Selecione a Marca'
							modoCadastro={this.state.modoCadastroMarca}
							acaoBotaoLabel={this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? 'Alterar' : 'Nova'}
							acaoBotao={() => {
								let itemLista = this.props.listaMarca.filter(item => item.id == this.props.formularioValues.id_marca)[0];

								this.setState({
									...this.state,
									modoCadastroMarca: true,
									marca: {
										id: this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? this.props.formularioValues.id_marca : null,
										nome: itemLista ? itemLista.valor : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<input type='text' className='form-control'
										placeholder='Informe o nome'
										value={this.state.marca ? this.state.marca.nome : ''}
										onChange={data => {
											this.setState({
												...this.state,
												marca: {
													id: this.state.marca ? this.state.marca.id : null,
													nome: data.target.value
												}
											})
										}} />
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarMarca(this.state.marca, this.props.formularioValues, 'recapagem');
												this.setState({
													...this.state,
													modoCadastroMarca: false,
													marca: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />

						<Field
							name='km_recapagem'
							component={LabelAndInputNumber}
							label='Km da Recapagem'
							placeholder='Informe a Km'
							cols='12 6 6 6'
							casas={0}
							readOnly={readOnly} />

						<Field
							name='hr_recapagem'
							component={LabelAndInputNumber}
							label='Hr da Recapagem'
							placeholder='Informe a Hr'
							cols='12 6 6 6'
							casas={0}
							readOnly={readOnly} />

						<Field
							name='observacao'
							component={LabelAndInput}
							label='Observação'
							placeholder='Informe observações'
							cols='12 12 12 12'
							readOnly={readOnly} />

						<Field
							name='valor'
							component={LabelAndInputNumber}
							label='Valor'
							placeholder='Informe o valor'
							cols='12 6 6 4'
							casas={2}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoRecapagem('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

PneuRecapagemForm = reduxForm({form: 'pneuRecapagemForm', destroyOnUnmount: false})(PneuRecapagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pneu.modoTela,
	listaDesenho: state.pneu.listaDesenho,
	listaMarca: state.pneu.listaMarca,
	registro: state.pneu.registro,
	formularioValues: getFormValues('pneuRecapagemForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoRecapagem, getListaDesenho, getListaMarca, initFormRecapagem, salvarDesenho, salvarMarca }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuRecapagemForm);
