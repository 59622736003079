import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoBomba, initFormBomba
} from './tanqueActions';

class BombaForm extends Component {

	state = {
	}

    componentWillMount() {
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Bomba' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='identificacao'
							component={LabelAndInput}
							label='Identificação *'
							placeholder='Informe a identificação'
							cols='12 12 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='casas_decimais'
							component={Select}
							options={[{ id: 0, valor: '0' }, { id: 1, valor: '0,0' }, { id: 2, valor: '0,00' }, { id: 3, valor: '0,000' }, { id: 4, valor: '0,0000' }]}
							label='Casas Decimais *'
							cols='12 6 4 2'
							placeholder='Selecione as casas decimais'
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoBomba('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

BombaForm = reduxForm({form: 'bombaForm', destroyOnUnmount: false})(BombaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.tanque.modoTela,
	registro: state.tanque.registro,
	formularioValues: getFormValues('bombaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoBomba, initFormBomba }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BombaForm);
