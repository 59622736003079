import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return (dispatch, getState) => {
		dispatch({
	        type: 'MANUTENCAO_MODO_TELA',
	        payload: {
				modo: modo,
				registro: registro,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id,
				id_operador: registro.id_operador || getState().auth.usuario.id
			}
    	});
	}
}
export function initForm(registro = { data_manutencao: DateFormat.getDataAtual() }) {
    return (dispatch, getState) => {
		dispatch([
	        initialize('manutencaoForm', {
				...registro,
				data_manutencao: registro.data_manutencao ? DateFormat.formatarDataSqlParaTela(registro.data_manutencao) : null,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id,
				id_operador: registro.id_operador || getState().auth.usuario.id
			})
		]);
	}
}

export function setFiltro(filtro) {
    return {
        type: 'MANUTENCAO_FILTRO',
        payload: filtro
    };
}

export function getLista(id_empresa) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/manutencao?id_empresa=${id_empresa}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(getState().manutencao.filtro.dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(getState().manutencao.filtro.dataFinal)}
			&id_usuario=${getState().manutencao.filtro.id_usuario}
			&id_equipamento=${getState().manutencao.filtro.id_equipamento}`)
		.then(resp => {
            dispatch({
				type: 'MANUTENCAO_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'MANUTENCAO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaTipo(id_empresa) {
    const request = axios.get(`${consts.API_URL}/manutencaoTipo/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'MANUTENCAO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEquipamento(id_empresa) {
    const request = axios.get(`${consts.API_URL}/equipamento/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'MANUTENCAO_EQUIPAMENTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produto/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'MANUTENCAO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/fornecedor/listarselect?id_empresa=${id_empresa}&peca=true&servico=true`);
    return {
        type: 'MANUTENCAO_FORNECEDOR_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/manutencao`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			data_manutencao: registro.data_manutencao ? DateFormat.formatarDataTelaParaSql(registro.data_manutencao) : null
		}).then(resp => {
			if (registro.id) {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			} else {
				axios.get(`${consts.API_URL}/manutencao?id=${resp.data}`).then(resp => {
					setSuccess('Operação Realizada com sucesso.');
    				dispatch(getLista(getState().auth.usuario.id_empresa));
					dispatch(initForm({
						...resp.data[0],
						hodometro: String(resp.data[0].hodometro || 0).replace('.', ','),
						horimetro: String(resp.data[0].horimetro || 0).replace('.', ',')
					}));
				});
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/manutencao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function encerrarManutencao(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/manutencao?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/manutencao`, {
				...resp.data[0],
				datahora_encerramento: DateFormat.getDataHoraAtual()
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function reabrirManutencao(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/manutencao?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/manutencao`, {
				...resp.data[0],
				datahora_encerramento: null
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

export function cancelarManutencao(id) {
    return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/manutencao?id=${id}`).then(resp => {
	        axios.post(`${consts.API_URL}/manutencao`, {
				...resp.data[0],
				datahora_cancelamento: DateFormat.getDataHoraAtual()
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		});
    };
}

//Item
export function setModoItem(modo, registro = {}) {
    return {
        type: 'MANUTENCAO_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormItem(registro = {}) {
    return [
        initialize('manutencaoItemForm', {
			...registro,
			quantidade: parseFloat(registro.quantidade || 0).toFixed(2).replace('.', ','),
			valor_unitario: parseFloat(registro.valor_unitario || 0).toFixed(2).replace('.', ','),
			valor_total: parseFloat(registro.valor_total || 0).toFixed(2).replace('.', ',')
		})
    ];
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/manutencaoItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(getState().auth.usuario.id_empresa, registro.id_manutencao));
			dispatch(setModoItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/manutencaoItem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_empresa, registro.id_manutencao));
			dispatch(setModoItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaItem(id_empresa, id_manutencao) {
    const request = axios.get(`${consts.API_URL}/manutencaoItem?id_empresa=${id_empresa}&id_manutencao=${id_manutencao}`);
    return {
        type: 'MANUTENCAO_ITEM_LISTADO',
        payload: request
    };
}

//Atividade
export function setModoAtividade(modo, registro = {}) {
    return {
        type: 'MANUTENCAO_ATIVIDADE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormAtividade(registro = { datahora_inicio: DateFormat.getDataHoraAtual() }) {
    return [
        initialize('manutencaoAtividadeForm', {
			...registro,
			datahora_inicio: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_inicio || DateFormat.getDataHoraAtual()),
			datahora_fim: registro.datahora_fim ? DateFormat.formatarDataHoraSqlParaTela(registro.datahora_fim) : (registro.datahora_inicio ? DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()) : null)
		})
    ];
}

export function salvarAtividade(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/manutencaoAtividade`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_inicio: registro.datahora_inicio ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inicio) : null,
			datahora_fim: registro.datahora_fim ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_fim) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAtividade(getState().auth.usuario.id_empresa, registro.id_manutencao));
			dispatch(setModoAtividade('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirAtividade(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/manutencaoAtividade?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaAtividade(registro.id_empresa, registro.id_manutencao));
			dispatch(setModoAtividade('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaAtividade(id_empresa, id_manutencao) {
    const request = axios.get(`${consts.API_URL}/manutencaoAtividade?id_empresa=${id_empresa}&id_manutencao=${id_manutencao}`);
    return {
        type: 'MANUTENCAO_ATIVIDADE_LISTADO',
        payload: request
    };
}
