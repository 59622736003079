import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PRODUTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('produtoForm', registro)
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produto?id_empresa=${id_empresa}`);
    return {
        type: 'PRODUTO_LISTADO',
        payload: request
    };
}

export function getListaUnidadeMedida(id_empresa) {
    const request = axios.get(`${consts.API_URL}/unidadeMedida/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PRODUTO_UNIDADE_MEDIDA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarUnidadeMedida(registro, formulario) {
	registro = { ...registro, id: registro.id ? parseInt(registro.id) : null	}
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/unidadeMedida`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaUnidadeMedida(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				dispatch(initForm({
					...formulario,
					id_unidade_medida: resp.data
				}));
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaCategoria(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produtoCategoria/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PRODUTO_CATEGORIA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarCategoria(registro, formulario) {
	registro = { ...registro, id: registro.id ? parseInt(registro.id) : null	}
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/produtoCategoria`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaCategoria(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				dispatch(initForm({
					...formulario,
					id_categoria: resp.data
				}));
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMarca(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produtoMarca/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PRODUTO_MARCA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarMarca(registro, formulario) {
	registro = { ...registro, id: registro.id ? parseInt(registro.id) : null	}
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/produtoMarca`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaMarca(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				dispatch(initForm({
					...formulario,
					id_marca: resp.data
				}));
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/produto`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			id_marca: registro.id_marca && registro.id_marca != 0 ? registro.id_marca : null,
			descricao: registro.nome
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/produto?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getUltimasCompras(id_produto) {
    const request = axios.get(`${consts.API_URL}/entradaItem/listarUltimasCompras?id_produto=${id_produto}`);
    return {
        type: 'PRODUTO_ULTIMAS_COMPRAS_LISTADO',
        payload: request
    };
}

export function getListaMovimento(id_produto) {
    const request = axios.get(`${consts.API_URL}/produto/listarMovimento?id_produto=${id_produto}`);
    return {
        type: 'PRODUTO_MOVIMENTO_LISTADO',
        payload: request
    };
}
