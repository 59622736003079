import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import PneuControleEquipamento from './pneuControleEquipamento';
import PneuControleEquipamentoEsteira from './pneuControleEquipamentoEsteira';

import { setModoTela, getListaEquipamento } from './pneuControleActions';

class PneuControle extends Component {

    componentWillMount() {
        this.props.getListaEquipamento(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Controle de Pneus' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'equipamento' ? (
						<PneuControleEquipamento />
					) : null}
					{this.props.modoTela == 'equipamento-esteira' ? (
						<PneuControleEquipamentoEsteira />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<Table>
					<THead>
						<Tr>
							<Th>Nome/Descrição</Th>
							<Th>Placa</Th>
							<Th>Espécie</Th>
							<Th>Tipo de Média</Th>
							<Th>Reboque</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.listaEquipamento.map(item => (
							<Tr key={item.id}>
								<Td>{item.nome}</Td>
								<Td>{item.placa}</Td>
								<Td>{item.nome_especie}</Td>
								<Td>{item.hodometro && item.horimetro ? 'Odômetro/Horímetro' : (item.hodometro ? 'Odômetro' : (item.horimetro ? 'Horímetro' : 'Nenhum'))}</Td>
								<Td>{item.reboque ? 'Sim' : 'Não'}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela(item.esteira ? 'equipamento-esteira' : 'equipamento', item);
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pneuControle.modoTela,
	listaEquipamento: state.pneuControle.listaEquipamento
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaEquipamento }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuControle);
