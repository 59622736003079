import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './produtoForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './produtoActions';

class Produto extends Component {

	state = {
		pesquisar: ''
	}

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Produto' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>

				<input className='form-control'
	                 placeholder='Pesquisar'
	                 type='text'
					 value={this.state.pesquisar}
					 onChange={e => {
						 this.setState({ ...this.state, pesquisar: e.target.value });
					 }} />

				<Table>
					<THead>
						<Tr>
							<Th>Nome</Th>
							<Th>Código</Th>
							<Th>Seq</Th>
							<Th>Unidade</Th>
							<Th>Categoria</Th>
							<Th>Ref. Externa</Th>
							<Th>Alocação</Th>
							<Th alignRight>Estoque</Th>
							<Th alignRight>Custo</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.lista.filter(item => {
							return !this.state.pesquisar
								|| (`${item.id} ${item.nome} ${item.codigo} ${item.nome_unidade_medida} ${item.nome_categoria} ${item.alocacao} ${item.referencia_interna} ${item.referencia_externa}`).toUpperCase().includes(this.state.pesquisar.toUpperCase());
						}).map(item => (
							<Tr key={item.id}>
								<Td>{item.nome}</Td>
								<Td>{item.codigo}</Td>
								<Td>{item.id}</Td>
								<Td>{item.nome_unidade_medida}</Td>
								<Td>{item.nome_categoria}</Td>
								<Td>{item.referencia_externa}</Td>
								<Td>{item.alocacao}</Td>
								<Td alignRight>{item.saldo_estoque.replace('.', ',')}</Td>
								<Td alignRight>{item.custo.replace('.', ',')}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', item);
											this.props.initForm({
												...item,
												custo: String(item.custo, 0).replace('.', ',')
											});
										}} />
									<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', item);
											this.props.initForm({
												...item,
												custo: String(item.custo, 0).replace('.', ',')
											});
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.produto.modoTela,
	lista: state.produto.lista,
	listaProdutoTipo: state.produto.listaProdutoTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Produto);
