import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaUsuario: [],
	listaCliente: [],
	listaOrdemServicoAberta: [],
	listaFechamentoItem: [],
	modoTelaItem: 'lista',
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null,
		id_cliente: null
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ORDEM_SERVICO_FECHAMENTO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				modoTelaItem: 'lista'
			};
		case 'ORDEM_SERVICO_FECHAMENTO_FILTRO':
			return { ...state, filtro: action.payload };
        case 'ORDEM_SERVICO_FECHAMENTO_LISTADO':
            return { ...state, lista: action.payload.data };
        case 'ORDEM_SERVICO_FECHAMENTO_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
		case 'ORDEM_SERVICO_FECHAMENTO_CLIENTE_SELECT_LISTADO':
            return { ...state, listaCliente: action.payload.data };
        case 'ORDEM_SERVICO_FECHAMENTO_ITEM_MODO_TELA':
            return { ...state,
				modoTelaItem: action.payload.modo
			};
		case 'ORDEM_SERVICO_FECHAMENTO_ITEM_LISTADO':
            return { ...state, listaFechamentoItem: action.payload.data };
		case 'ORDEM_SERVICO_FECHAMENTO_ABERTA_LISTADO':
            return { ...state, listaOrdemServicoAberta: action.payload.data };
        default:
            return state;
    }
}
