import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import FormRecapagem from './pneuRecapagemForm';
import FormAvaria from './pneuAvariaForm';

import {
	setModoTela, initForm, salvar, salvarDimensao, getListaDimensao, salvarDesenho, getListaDesenho, salvarTipo, getListaTipo, salvarMarca, getListaMarca,
	setModoRecapagem, initFormRecapagem, salvarRecapagem, excluirRecapagem, getListaRecapagem,
	setModoAvaria, initFormAvaria, salvarAvaria, excluirAvaria, getListaAvaria
} from './pneuActions';

class PneuForm extends Component {

	state = {
		modoCadastroDimensao: false,
		dimensao: null,
		modoCadastroDesenho: false,
		desenho: null,
		modoCadastroTipo: false,
		tipo: null,
		modoCadastroMarca: false,
		marca: null,
	}

    componentWillMount() {
		this.props.getListaDimensao(this.props.sessao.id_empresa);
		this.props.getListaDesenho(this.props.sessao.id_empresa);
		this.props.getListaTipo(this.props.sessao.id_empresa);
		this.props.getListaMarca(this.props.sessao.id_empresa);
		this.props.getListaRecapagem(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
		this.props.getListaAvaria(this.props.sessao.id_empresa, this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir || this.props.modoTela == 'descarte' || this.props.formularioValues.datahora_descarte ? 'readOnly' : '';

		let valorTotal = parseFloat(this.props.formularioValues.valor.replace(',', '.') || 0);
		this.props.listaPneuRecapagem.forEach(item => {
			valorTotal += parseFloat(item.valor || 0);
		});
		this.props.listaPneuAvaria.forEach(item => {
			valorTotal += parseFloat(item.valor || 0);
		});

		this.props.initForm({
			...this.props.formularioValues,
			total: String(valorTotal).replace('.', ',')
		});

        return (
			<div>
				<ContentCard>
					{this.props.modoTela == 'cadastro' && this.props.formularioValues.id && !this.props.formularioValues.datahora_descarte ? (
						<BoxButtonHeader>
							<Button
								text='Descartar Pneu'
								type={'danger'}
								icon={'fa fa-times'}
								event={() => {
									this.props.setModoTela('descarte', {
										...this.props.formularioValues,
										datahora_descarte: DateFormat.getDataHoraAtual()
									});
									this.props.initForm({
										...this.props.formularioValues,
										datahora_descarte: DateFormat.getDataHoraAtual()
									});
								}} />
							{!this.props.formularioValues.manutencao ? (
								<Button
									text='Enviar para Manutenção'
									type={'primary'}
										icon={'fa fa-arrow-right'}
									event={() => {
										this.props.salvar({
											...this.props.formularioValues,
											manutencao: true
										});
									}} />
							) : (
								<Button
									text='Retornar da Manutenção'
									type={'success'}
									icon={'fa fa-arrow-left'}
									event={() => {
										this.props.salvar({
											...this.props.formularioValues,
											manutencao: false
										});
									}} />
							)}
						</BoxButtonHeader>
					) : null}
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='dot'
								component={LabelAndInput}
								label='DOT *'
								placeholder='Informe o DOT'
								cols='12 10 6 4'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='numero_fogo'
								component={LabelAndInput}
								label='Número de Fogo *'
								placeholder='Informe o Número de Fogo'
								cols='12 10 6 4'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_dimensao'
								component={SelectAlteracao}
								options={this.props.listaDimensao}
								label='Dimensão *'
								cols='12 10 6 4'
								placeholder='Selecione a Dimensão'
								modoCadastro={this.state.modoCadastroDimensao}
								acaoBotaoLabel={this.props.formularioValues.id_dimensao && this.props.formularioValues.id_dimensao != 0 ? 'Alterar' : 'Nova'}
								acaoBotao={() => {
									let itemLista = this.props.listaDimensao.filter(item => item.id == this.props.formularioValues.id_dimensao)[0];

									this.setState({
										...this.state,
										modoCadastroDimensao: true,
										dimensao: {
											id: this.props.formularioValues.id_dimensao && this.props.formularioValues.id_dimensao != 0 ? this.props.formularioValues.id_dimensao : null,
											codigo: itemLista ? itemLista.valor : null
										}
									});
								}}
								componenteCadastro={
									<div class='input-group'>
										<input type='text' className='form-control'
											placeholder='Informe o código'
											value={this.state.dimensao ? this.state.dimensao.codigo : ''}
											onChange={data => {
												this.setState({
													...this.state,
													dimensao: {
														id: this.state.dimensao ? this.state.dimensao.id : null,
														codigo: data.target.value
													}
												})
											}} />
										<span class='input-group-btn'>
											<button type='button' class='btn btn-success btn-flat'
												onClick={() => {
													this.props.salvarDimensao(this.state.dimensao, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroDimensao: false,
														dimensao: null
													});
												}}>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_desenho'
								component={SelectAlteracao}
								options={this.props.listaDesenho}
								label='Desenho *'
								cols='12 10 6 4'
								placeholder='Selecione o Desenho'
								modoCadastro={this.state.modoCadastroDesenho}
								acaoBotaoLabel={this.props.formularioValues.id_desenho && this.props.formularioValues.id_desenho != 0 ? 'Alterar' : 'Novo'}
								acaoBotao={() => {
									let itemLista = this.props.listaDesenho.filter(item => item.id == this.props.formularioValues.id_desenho)[0];

									this.setState({
										...this.state,
										modoCadastroDesenho: true,
										desenho: {
											id: this.props.formularioValues.id_desenho && this.props.formularioValues.id_desenho != 0 ? this.props.formularioValues.id_desenho : null,
											nome: itemLista ? itemLista.valor : null
										}
									});
								}}
								componenteCadastro={
									<div class='input-group'>
										<input type='text' className='form-control'
											placeholder='Informe o nome'
											value={this.state.desenho ? this.state.desenho.nome : ''}
											onChange={data => {
												this.setState({
													...this.state,
													desenho: {
														id: this.state.desenho ? this.state.desenho.id : null,
														nome: data.target.value
													}
												})
											}} />
										<span class='input-group-btn'>
											<button type='button' class='btn btn-success btn-flat'
												onClick={() => {
													this.props.salvarDesenho(this.state.desenho, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroDesenho: false,
														desenho: null
													});
												}}>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_tipo'
								component={SelectAlteracao}
								options={this.props.listaTipo}
								label='Tipo *'
								cols='12 10 6 4'
								placeholder='Selecione o Tipo'
								modoCadastro={this.state.modoCadastroTipo}
								acaoBotaoLabel={this.props.formularioValues.id_tipo && this.props.formularioValues.id_tipo != 0 ? 'Alterar' : 'Novo'}
								acaoBotao={() => {
									let itemLista = this.props.listaTipo.filter(item => item.id == this.props.formularioValues.id_tipo)[0];

									this.setState({
										...this.state,
										modoCadastroTipo: true,
										tipo: {
											id: this.props.formularioValues.id_tipo && this.props.formularioValues.id_tipo != 0 ? this.props.formularioValues.id_tipo : null,
											nome: itemLista ? itemLista.valor : null
										}
									});
								}}
								componenteCadastro={
									<div class='input-group'>
										<input type='text' className='form-control'
											placeholder='Informe o nome'
											value={this.state.tipo ? this.state.tipo.nome : ''}
											onChange={data => {
												this.setState({
													...this.state,
													tipo: {
														id: this.state.tipo ? this.state.tipo.id : null,
														nome: data.target.value
													}
												})
											}} />
										<span class='input-group-btn'>
											<button type='button' class='btn btn-success btn-flat'
												onClick={() => {
													this.props.salvarTipo(this.state.tipo, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroTipo: false,
														tipo: null
													});
												}}>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_marca'
								component={SelectAlteracao}
								options={this.props.listaMarca}
								label='Marca *'
								cols='12 10 6 4'
								placeholder='Selecione a Marca'
								modoCadastro={this.state.modoCadastroMarca}
								acaoBotaoLabel={this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? 'Alterar' : 'Nova'}
								acaoBotao={() => {
									let itemLista = this.props.listaMarca.filter(item => item.id == this.props.formularioValues.id_marca)[0];

									this.setState({
										...this.state,
										modoCadastroMarca: true,
										marca: {
											id: this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? this.props.formularioValues.id_marca : null,
											nome: itemLista ? itemLista.valor : null
										}
									});
								}}
								componenteCadastro={
									<div class='input-group'>
										<input type='text' className='form-control'
											placeholder='Informe o nome'
											value={this.state.marca ? this.state.marca.nome : ''}
											onChange={data => {
												this.setState({
													...this.state,
													marca: {
														id: this.state.marca ? this.state.marca.id : null,
														nome: data.target.value
													}
												})
											}} />
										<span class='input-group-btn'>
											<button type='button' class='btn btn-success btn-flat'
												onClick={() => {
													this.props.salvarMarca(this.state.marca, this.props.formularioValues);
													this.setState({
														...this.state,
														modoCadastroMarca: false,
														marca: null
													});
												}}>
												Salvar
											</button>
										</span>
									</div>
								}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='km_inicial'
								component={LabelAndInputNumber}
								label='Km Inicial'
								placeholder='Informe a Km inicial'
								cols='12 6 4 2'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='hr_inicial'
								component={LabelAndInputNumber}
								label='Hr Inicial'
								placeholder='Informe a Hr inicial'
								cols='12 6 4 2'
								casas={0}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor'
								placeholder='Informe o valor'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='total'
								component={LabelAndInputNumber}
								label='Valor Total'
								placeholder='0,00'
								cols='12 6 4 2'
								casas={2}
								readOnly='readOnly' />
						</Row>

						{this.props.modoTela == 'descarte' ? (
							<Row>
								<Field
									name='km_descarte'
									component={LabelAndInputNumber}
									label='Km de Descarte'
									placeholder='Informe a Km'
									cols='12 6 4 2'
									casas={0} />

								<Field
									name='hr_descarte'
									component={LabelAndInputNumber}
									label='Hr de Descarte'
									placeholder='Informe a Hr'
									cols='12 6 4 2'
									casas={0} />

								<Field
									name='motivo_descarte'
									component={LabelAndInput}
									label='Motivo do Descarte'
									placeholder='Informe o motivo do descarte'
									cols='12 12 12 12' />
							</Row>
						) : (this.props.modoTela == 'cadastro' || this.props.modoTela == 'exclusao') && this.props.formularioValues.datahora_descarte ? (
							<Row>
								<Field
									name='datahora_descarte'
									component={LabelAndInputDateTime}
									label='Data e Hora do Descarte'
									placeholder='Informe a Data e hora do descarte'
									cols='12 12 4 2'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='km_descarte'
									component={LabelAndInputNumber}
									label='Km de Descarte'
									placeholder='Informe a Km'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='hr_descarte'
									component={LabelAndInputNumber}
									label='Hr de Descarte'
									placeholder='Informe a Hr'
									cols='12 6 4 2'
									casas={0}
									readOnly={readOnly} />

								<Field
									name='motivo_descarte'
									component={LabelAndInput}
									label='Motivo do Descarte'
									placeholder='Informe o motivo do descarte'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>
						) : null}

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									disabled={this.props.modoTela != 'descarte' && this.props.formularioValues.datahora_descarte != null}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Cancelar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.modoTela == 'cadastro' && this.props.formularioValues.id ? (
					<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 40, marginLeft: 0, marginRight: 0 }}>
						<Grid cols='12 12 6 6'>
							{this.props.modoTelaRecapagem == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Recapagem' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoRecapagem('cadastro', { id_pneu: this.props.formularioValues.id });
												this.props.initFormRecapagem({ id_pneu: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Data</Th>
												<Th>Desenho</Th>
												<Th>Marca</Th>
												<Th>Km</Th>
												<Th>Hr</Th>
												<Th alignRight>Valor</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaPneuRecapagem.map(item => (
												<Tr key={item.id}>
													<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
													<Td>{item.nome_desenho}</Td>
													<Td>{item.nome_marca}</Td>
													<Td>{item.km_recapagem}</Td>
													<Td>{item.hr_recapagem}</Td>
													<Td alignRight>{String(item.valor || 0).replace('.', ',')}</Td>
													<Td alignCenter>
														<ButtonTable
															type={'warning'}
															icon={'fa fa-pencil'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoRecapagem('cadastro', item);
																this.props.initFormRecapagem(item);
															}} />
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash-o'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoRecapagem('exclusao', item);
																this.props.initFormRecapagem(item);
															}} />
													</Td>
												</Tr>
											))}
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaRecapagem == 'cadastro' ? (
								<FormRecapagem onSubmit={this.props.salvarRecapagem} />
							) : (this.props.modoTelaRecapagem == 'exclusao' ? (
								<FormRecapagem excluir onSubmit={this.props.excluirRecapagem} />
							) : null))}
						</Grid>
						<Grid cols='12 12 6 6'>
							{this.props.modoTelaAvaria == 'lista' ? (
								<ContentCard>
									<ContentCardHeader title='Avaria' />
									<div style={{ marginBottom: 10 }}>
										<Button
											text='Adicionar'
											type={'success'}
											icon={'fa fa-plus'}
											disabled={readOnly == 'readOnly'}
											event={() => {
												this.props.setModoAvaria('cadastro', { id_pneu: this.props.formularioValues.id });
												this.props.initFormAvaria({ id_pneu: this.props.formularioValues.id });
											}} />
									</div>
									<Table>
										<THead>
											<Tr>
												<Th>Data/Hora</Th>
												<Th>Causa</Th>
												<Th>Km</Th>
												<Th>Hr</Th>
												<Th alignRight>Valor</Th>
												<Th alignCenter acoes>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.props.listaPneuAvaria.map(item => (
												<Tr key={item.id}>
													<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
													<Td>{item.causa}</Td>
													<Td>{item.km_avaria}</Td>
													<Td>{item.hr_avaria}</Td>
													<Td alignRight>{String(item.valor || 0).replace('.', ',')}</Td>
													<Td alignCenter>
														<ButtonTable
															type={'warning'}
															icon={'fa fa-pencil'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoAvaria('cadastro', item);
																this.props.initFormAvaria(item);
															}} />
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash-o'}
															disabled={readOnly == 'readOnly'}
															event={() => {
																this.props.setModoAvaria('exclusao', item);
																this.props.initFormAvaria(item);
															}} />
													</Td>
												</Tr>
											))}
										</TBody>
									</Table>
								</ContentCard>
							) : (this.props.modoTelaAvaria == 'cadastro' ? (
								<FormAvaria onSubmit={this.props.salvarAvaria} />
							) : (this.props.modoTelaAvaria == 'exclusao' ? (
								<FormAvaria excluir onSubmit={this.props.excluirAvaria} />
							) : null))}
						</Grid>
					</Row>
				) : null}
			</div>
        )
    }


}

PneuForm = reduxForm({form: 'pneuForm', destroyOnUnmount: false})(PneuForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pneu.modoTela,
	listaDimensao: state.pneu.listaDimensao,
	listaDesenho: state.pneu.listaDesenho,
	listaTipo: state.pneu.listaTipo,
	listaMarca: state.pneu.listaMarca,
	modoTelaRecapagem: state.pneu.modoTelaRecapagem,
	listaPneuRecapagem: state.pneu.listaPneuRecapagem,
	modoTelaAvaria: state.pneu.modoTelaAvaria,
	listaPneuAvaria: state.pneu.listaPneuAvaria,
	registro: state.pneu.registro,
	formularioValues: getFormValues('pneuForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, salvar, salvarDimensao, getListaDimensao, salvarDesenho, getListaDesenho, salvarTipo, getListaTipo, salvarMarca, getListaMarca,
	setModoRecapagem, initFormRecapagem, salvarRecapagem, excluirRecapagem, getListaRecapagem,
	setModoAvaria, initFormAvaria, salvarAvaria, excluirAvaria, getListaAvaria
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuForm);
