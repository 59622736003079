import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {}, modoTela: 'lista', lista: [], listaUsuario: [], listaProduto: [], listaFornecedor: [], listaUltimasCompras: [],
	listaEntradaItem: [], modoTelaItem: 'lista',
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ENTRADA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				modoTelaItem: 'lista'
			};
		case 'ENTRADA_FILTRO':
			return { ...state, filtro: action.payload };
        case 'ENTRADA_LISTADO':
            return { ...state, lista: action.payload.data };
        case 'ENTRADA_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
        case 'ENTRADA_PRODUTO_SELECT_LISTADO':
            return { ...state, listaProduto: action.payload.data };
		case 'ENTRADA_FORNECEDOR_SELECT_LISTADO':
            return { ...state, listaFornecedor: action.payload.data };
		case 'ENTRADA_ULTIMAS_COMPRAS_LISTADO':
            return { ...state, listaUltimasCompras: action.payload.data };
        case 'ENTRADA_ITEM_MODO_TELA':
            return { ...state,
				modoTelaItem: action.payload.modo
			};
		case 'ENTRADA_ITEM_LISTADO':
            return { ...state, listaEntradaItem: action.payload.data };
        default:
            return state;
    }
}
