import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';

import {
	setModoRolete, getListaFornecedor, getListaMarca, initFormRolete, salvarFornecedor, salvarMarca
} from './pneuControleActions';

class PneuControleRoleteForm extends Component {

	state = {
		modoCadastroFornecedor: false,
		fornecedor: null,
		modoCadastroMarca: false,
		marca: null,
	}

    componentWillMount() {
		this.props.getListaFornecedor(this.props.sessao.id_empresa);
		this.props.getListaMarca(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Rolete' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='lado'
							component={Select}
							options={[{ id: 'direita', valor: 'Direita' }, { id: 'esquerda', valor: 'Esquerda' }]}
							label='Lado *'
							cols='12 6 4 2'
							placeholder='Selecione o Lado'
							readOnly={readOnly} />

						<Field
							name='posicao'
							component={Select}
							options={[{ id: 'inferior', valor: 'Inferior' }, { id: 'superior', valor: 'Superior' }]}
							label='Posição *'
							cols='12 6 4 2'
							placeholder='Selecione a Posição'
							readOnly={readOnly} />

						<Field
							name='ordem'
							component={Select}
							options={[{ id: '1', valor: '1' }, { id: '2', valor: '2' }, { id: '3', valor: '3' }, { id: '4', valor: '4' }, { id: '5', valor: '5' }, { id: '6', valor: '6' }, { id: '7', valor: '7' }, { id: '8', valor: '8' }, { id: '9', valor: '9' }, { id: '10', valor: '10' }]}
							label='Ordem *'
							cols='12 6 4 2'
							placeholder='Selecione o Ordem'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='datahora_inicial'
							component={LabelAndInputDate}
							label='Data de Início *'
							placeholder='Informe o Início'
							cols='12 4 3 2'
							readOnly={readOnly} />

						<Field
							name='datahora_final'
							component={LabelAndInputDate}
							label='Data de Final'
							placeholder='Informe o Final'
							cols='12 4 3 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_fornecedor'
							component={SelectAlteracao}
							options={this.props.listaFornecedor}
							label='Fornecedor'
							cols='12 12 6 4'
							placeholder='Selecione o Fornecedor'
							modoCadastro={this.state.modoCadastroFornecedor}
							acaoBotaoLabel={this.props.formularioValues.id_fornecedor && this.props.formularioValues.id_fornecedor != 0 ? 'Alterar' : 'Novo'}
							acaoBotao={() => {
								let itemLista = this.props.listaFornecedor.filter(item => item.id == this.props.formularioValues.id_fornecedor)[0];

								this.setState({
									...this.state,
									modoCadastroFornecedor: true,
									fornecedor: {
										id: this.props.formularioValues.id_fornecedor && this.props.formularioValues.id_fornecedor != 0 ? this.props.formularioValues.id_fornecedor : null,
										nome: itemLista ? itemLista.valor : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<input type='text' className='form-control'
										placeholder='Informe o nome'
										value={this.state.fornecedor ? this.state.fornecedor.nome_fantasia : ''}
										onChange={data => {
											this.setState({
												...this.state,
												fornecedor: {
													id: this.state.fornecedor ? this.state.fornecedor.id : null,
													nome_fantasia: data.target.value,
													razao_social: data.target.value
												}
											})
										}} />
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarFornecedor(this.state.fornecedor, this.props.formularioValues, 'rolete');
												this.setState({
													...this.state,
													modoCadastroFornecedor: false,
													fornecedor: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />

						<Field
							name='id_marca'
							component={SelectAlteracao}
							options={this.props.listaMarca}
							label='Marca'
							cols='12 12 6 4'
							placeholder='Selecione a Marca'
							modoCadastro={this.state.modoCadastroMarca}
							acaoBotaoLabel={this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? 'Alterar' : 'Nova'}
							acaoBotao={() => {
								let itemLista = this.props.listaMarca.filter(item => item.id == this.props.formularioValues.id_marca)[0];

								this.setState({
									...this.state,
									modoCadastroMarca: true,
									marca: {
										id: this.props.formularioValues.id_marca && this.props.formularioValues.id_marca != 0 ? this.props.formularioValues.id_marca : null,
										nome: itemLista ? itemLista.valor : null
									}
								});
							}}
							componenteCadastro={
								<div class='input-group'>
									<input type='text' className='form-control'
										placeholder='Informe o nome'
										value={this.state.marca ? this.state.marca.nome : ''}
										onChange={data => {
											this.setState({
												...this.state,
												marca: {
													id: this.state.marca ? this.state.marca.id : null,
													nome: data.target.value
												}
											})
										}} />
									<span class='input-group-btn'>
										<button type='button' class='btn btn-success btn-flat'
											onClick={() => {
												this.props.salvarMarca(this.state.marca, this.props.formularioValues, 'rolete');
												this.setState({
													...this.state,
													modoCadastroMarca: false,
													marca: null
												});
											}}>
											Salvar
										</button>
									</span>
								</div>
							}
							readOnly={readOnly} />

						<Row>
							<Field
								name='km_inicial'
								component={LabelAndInputNumber}
								label='Km Inicial'
								placeholder='Informe a Km'
								cols='12 6 3 2'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='hr_inicial'
								component={LabelAndInputNumber}
								label='Hr Inicial'
								placeholder='Informe a Hr'
								cols='12 6 3 2'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='km_final'
								component={LabelAndInputNumber}
								label='Km Final'
								placeholder='Informe a Km'
								cols='12 6 3 2'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='hr_final'
								component={LabelAndInputNumber}
								label='Hr Final'
								placeholder='Informe a Hr'
								cols='12 6 3 2'
								casas={0}
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='valor'
								component={LabelAndInputNumber}
								label='Valor'
								placeholder='Informe o valor'
								cols='12 6 3 2'
								casas={2}
								readOnly={readOnly} />
						</Row>
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoRolete('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }
}

PneuControleRoleteForm = reduxForm({form: 'pneuControleRoleteForm', destroyOnUnmount: false})(PneuControleRoleteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pneuControle.modoTela,
	listaFornecedor: state.pneuControle.listaFornecedor,
	listaMarca: state.pneuControle.listaMarca,
	registro: state.pneuControle.registro,
	formularioValues: getFormValues('pneuControleRoleteForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoRolete, getListaFornecedor, getListaMarca, initFormRolete, salvarFornecedor, salvarMarca }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuControleRoleteForm);
