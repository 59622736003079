const INITIAL_STATE = {
	registro: {}, modoTela: 'lista', lista: [], listaBomba: [], modoTelaBomba: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TANQUE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
				modoTelaBomba: 'lista'
			};
        case 'TANQUE_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'TANQUE_BOMBA_MODO_TELA':
            return { ...state,
				modoTelaBomba: action.payload.modo
			};
		case 'TANQUE_BOMBA_LISTADO':
            return { ...state, listaBomba: action.payload.data };
        default:
            return state;
    }
}
