import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import NumberFormat from 'react-number-format';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';

import FormEsteira from './pneuControleEsteiraForm';
import FormRolete from './pneuControleRoleteForm';
import FormEsteiraManutencao from './pneuControleEsteiraManutencaoForm';

import {
	setModoTela, getLista, salvar, excluir,
	setModoEsteira, initFormEsteira, getListaEsteira, salvarEsteira, excluirEsteira,
	setModoEsteiraManutencao, initFormEsteiraManutencao, getListaEsteiraManutencao, salvarEsteiraManutencao, excluirEsteiraManutencao,
	setModoRolete, initFormRolete, getListaRolete, salvarRolete, excluirRolete,
} from './pneuControleActions';

class PneuControleEquipamentoForm extends Component {

	state = {
		pneuControleAux: null,
		filtroPneu: '',
		pneuControleAlteracao: null
	}

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa, this.props.registro.id || -1);
		this.props.getListaEsteira(this.props.sessao.id_empresa, this.props.registro.id || -1);
		this.props.getListaEsteiraManutencao(this.props.sessao.id_empresa, this.props.registro.id || -1);
		this.props.getListaRolete(this.props.sessao.id_empresa, this.props.registro.id || -1);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';
		return (
			<div style={{ marginBottom: 40 }}>
				{this.props.registro.id ? (
					<div>
						<ContentCard>
							<Table>
								<THead>
									<Tr>
										<Th>Nome/Descrição</Th>
										<Th>Placa</Th>
										<Th>Espécie</Th>
										<Th>Tipo de Média</Th>
										<Th>Reboque</Th>
										<Th alignCenter acoes>Voltar</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaEquipamento.filter(item => item.id == this.props.registro.id).map(item => (
										<Tr key={item.id}>
											<Td>{item.nome}</Td>
											<Td>{item.placa}</Td>
											<Td>{item.nome_especie}</Td>
											<Td>{item.hodometro && item.horimetro ? 'Odômetro/Horímetro' : (item.hodometro ? 'Odômetro' : (item.horimetro ? 'Horímetro' : 'Nenhum'))}</Td>
											<Td>{item.reboque ? 'Sim' : 'Não'}</Td>
											<Td alignCenter>
												<ButtonTable
													type={'warning'}
													icon={'fa fa-chevron-left'}
													event={() => {
														this.props.setModoTela('lista', {});
													}} />
											</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</ContentCard>

						<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginLeft: 0, marginRight: 0 }}>
							<Grid cols='12 12 12 12'>
								{this.props.modoTelaEsteira == 'lista' ? (
									<ContentCard>
										<ContentCardHeader title='Esteira' />
										<div style={{ marginBottom: 10 }}>
											<Button
												text='Adicionar'
												type={'success'}
												icon={'fa fa-plus'}
												event={() => {
													this.props.setModoEsteira('cadastro', { id_equipamento: this.props.registro.id });
													this.props.initFormEsteira({ id_equipamento: this.props.registro.id });
												}} />
										</div>
										<Table>
											<THead>
												<Tr>
													<Th>Lado</Th>
													<Th>Data Inicial</Th>
													<Th>Data Final</Th>
													<Th>Fornecedor</Th>
													<Th>Marca</Th>
													<Th>Km Inicial</Th>
													<Th>Hr Inicial</Th>
													<Th>Km Final</Th>
													<Th>Hr Final</Th>
													<Th alignRight>Valor</Th>
													<Th alignCenter acoes>Ações</Th>
												</Tr>
											</THead>
											<TBody>
												{this.props.listaEsteira.map(item => (
													<Tr key={item.id}>
														<Td>{item.lado == 'direita' ? 'Direita' : 'Esquerda'}</Td>
														<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inicial)}</Td>
														<Td>{item.datahora_final ? DateFormat.formatarDataSqlParaTela(item.datahora_final) : ''}</Td>
														<Td>{item.nome_fornecedor}</Td>
														<Td>{item.nome_marca}</Td>
														<Td>{item.km_inicial}</Td>
														<Td>{item.hr_inicial}</Td>
														<Td>{item.km_final}</Td>
														<Td>{item.hr_final}</Td>
														<Td alignRight>{String(item.valor || 0).replace('.', ',')}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'warning'}
																icon={'fa fa-pencil'}
																event={() => {
																	this.props.setModoEsteira('cadastro', item);
																	this.props.initFormEsteira(item);
																}} />
															<ButtonTable
																type={'danger'}
																icon={'fa fa-trash-o'}
																event={() => {
																	this.props.setModoEsteira('exclusao', item);
																	this.props.initFormEsteira(item);
																}} />
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</ContentCard>
								) : (this.props.modoTelaEsteira == 'cadastro' ? (
									<FormEsteira onSubmit={this.props.salvarEsteira} />
								) : (this.props.modoTelaEsteira == 'exclusao' ? (
									<FormEsteira excluir onSubmit={this.props.excluirEsteira} />
								) : null))}
							</Grid>
						</Row>
						<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginLeft: 0, marginRight: 0 }}>
							<Grid cols='12 12 12 12'>
								{this.props.modoTelaRolete == 'lista' ? (
									<ContentCard>
										<ContentCardHeader title='Rolete' />
										<div style={{ marginBottom: 10 }}>
											<Button
												text='Adicionar'
												type={'success'}
												icon={'fa fa-plus'}
												event={() => {
													this.props.setModoRolete('cadastro', { id_equipamento: this.props.registro.id });
													this.props.initFormRolete({ id_equipamento: this.props.registro.id });
												}} />
										</div>
										<Table>
											<THead>
												<Tr>
													<Th>Lado</Th>
													<Th>Posição</Th>
													<Th>Ordem</Th>
													<Th>Data Inicial</Th>
													<Th>Data Final</Th>
													<Th>Fornecedor</Th>
													<Th>Marca</Th>
													<Th>Km Inicial</Th>
													<Th>Hr Inicial</Th>
													<Th>Km Final</Th>
													<Th>Hr Final</Th>
													<Th alignRight>Valor</Th>
													<Th alignCenter acoes>Ações</Th>
												</Tr>
											</THead>
											<TBody>
												{this.props.listaRolete.map(item => (
													<Tr key={item.id}>
														<Td>{item.lado == 'direita' ? 'Direita' : 'Esquerda'}</Td>
														<Td>{item.posicao == 'inferior' ? 'Inferior' : 'Superior'}</Td>
														<Td>{item.ordem}</Td>
														<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inicial)}</Td>
														<Td>{item.datahora_final ? DateFormat.formatarDataSqlParaTela(item.datahora_final) : ''}</Td>
														<Td>{item.nome_fornecedor}</Td>
														<Td>{item.nome_marca}</Td>
														<Td>{item.km_inicial}</Td>
														<Td>{item.hr_inicial}</Td>
														<Td>{item.km_final}</Td>
														<Td>{item.hr_final}</Td>
														<Td alignRight>{String(item.valor || 0).replace('.', ',')}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'warning'}
																icon={'fa fa-pencil'}
																event={() => {
																	this.props.setModoRolete('cadastro', item);
																	this.props.initFormRolete(item);
																}} />
															<ButtonTable
																type={'danger'}
																icon={'fa fa-trash-o'}
																event={() => {
																	this.props.setModoRolete('exclusao', item);
																	this.props.initFormRolete(item);
																}} />
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</ContentCard>
								) : (this.props.modoTelaRolete == 'cadastro' ? (
									<FormRolete onSubmit={this.props.salvarRolete} />
								) : (this.props.modoTelaRolete == 'exclusao' ? (
									<FormRolete excluir onSubmit={this.props.excluirRolete} />
								) : null))}
							</Grid>
						</Row>
						<Row style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginLeft: 0, marginRight: 0 }}>
							<Grid cols='12 12 12 12'>
								{this.props.modoTelaEsteiraManutencao == 'lista' ? (
									<ContentCard>
										<ContentCardHeader title='Manutenção da Esteira' />
										<div style={{ marginBottom: 10 }}>
											<Button
												text='Adicionar'
												type={'success'}
												icon={'fa fa-plus'}
												event={() => {
													this.props.setModoEsteiraManutencao('cadastro', {
														id_equipamento: this.props.registro.id,
														listaEsteira: this.props.listaEsteira.filter(esteira => esteira.ativo)
													});
													this.props.initFormEsteiraManutencao({
														id_equipamento: this.props.registro.id,
														listaEsteira: this.props.listaEsteira.filter(esteira => esteira.ativo)
													});
												}} />
										</div>
										<Table>
											<THead>
												<Tr>
													<Th>Esteira</Th>
													<Th>Data</Th>
													<Th>Causa</Th>
													<Th>Km Manutenção</Th>
													<Th>Hr Manutenção</Th>
													<Th>Fornecedor</Th>
													<Th>Marca</Th>
													<Th alignRight>Valor</Th>
													<Th alignCenter acoes>Ações</Th>
												</Tr>
											</THead>
											<TBody>
												{this.props.listaEsteiraManutencao.map(item => (
													<Tr key={item.id}>
														<Td>{item.nome_esteira == 'direita' ? 'Direita' : 'Esquerda'}</Td>
														<Td>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
														<Td>{item.causa}</Td>
														<Td>{item.km_manutencao}</Td>
														<Td>{item.hr_manutencao}</Td>
														<Td>{item.nome_fornecedor}</Td>
														<Td>{item.nome_marca}</Td>
														<Td alignRight>{String(item.valor || 0).replace('.', ',')}</Td>
														<Td alignCenter>
															<ButtonTable
																type={'warning'}
																icon={'fa fa-pencil'}
																event={() => {
																	this.props.setModoEsteiraManutencao('cadastro', {
																		...item,
																		id_equipamento: this.props.registro.id,
																		listaEsteira: this.props.listaEsteira.filter(esteira => esteira.ativo)
																	});
																	this.props.initFormEsteiraManutencao({
																		...item,
																		id_equipamento: this.props.registro.id,
																		listaEsteira: this.props.listaEsteira.filter(esteira => esteira.ativo)
																	});
																}} />
															<ButtonTable
																type={'danger'}
																icon={'fa fa-trash-o'}
																event={() => {
																	this.props.setModoEsteiraManutencao('exclusao', {
																		...item,
																		id_equipamento: this.props.registro.id,
																		listaEsteira: this.props.listaEsteira.filter(esteira => esteira.ativo)
																	});
																	this.props.initFormEsteiraManutencao({
																		...item,
																		id_equipamento: this.props.registro.id,
																		listaEsteira: this.props.listaEsteira.filter(esteira => esteira.ativo)
																	});
																}} />
														</Td>
													</Tr>
												))}
											</TBody>
										</Table>
									</ContentCard>
								) : (this.props.modoTelaEsteiraManutencao == 'cadastro' ? (
									<FormEsteiraManutencao onSubmit={this.props.salvarEsteiraManutencao} />
								) : (this.props.modoTelaEsteiraManutencao == 'exclusao' ? (
									<FormEsteiraManutencao excluir onSubmit={this.props.excluirEsteiraManutencao} />
								) : null))}
							</Grid>
						</Row>
					</div>
				) : null}
			</div>
		);
    }
}

PneuControleEquipamentoForm = reduxForm({destroyOnUnmount: false})(PneuControleEquipamentoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.pneuControle.registro,
	lista: state.pneuControle.lista,
	listaEquipamento: state.pneuControle.listaEquipamento,
	listaEsteira: state.pneuControle.listaEsteira,
	listaEsteiraManutencao: state.pneuControle.listaEsteiraManutencao,
	listaRolete: state.pneuControle.listaRolete,
	modoTelaEquipamento: state.pneuControle.modoTelaEquipamento,
	modoTelaEsteira: state.pneuControle.modoTelaEsteira,
	modoTelaEsteiraManutencao: state.pneuControle.modoTelaEsteiraManutencao,
	modoTelaRolete: state.pneuControle.modoTelaRolete
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, getLista, salvar, excluir,
	setModoEsteira, initFormEsteira, getListaEsteira, salvarEsteira, excluirEsteira,
	setModoEsteiraManutencao, initFormEsteiraManutencao, getListaEsteiraManutencao, salvarEsteiraManutencao, excluirEsteiraManutencao,
	setModoRolete, initFormRolete, getListaRolete, salvarRolete, excluirRolete,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PneuControleEquipamentoForm);
