import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
			<label htmlFor={props.name}>{props.label}</label>
			{props.modoCadastro ? (
				props.componenteCadastro
			) : (
				<div class='input-group'>
					<select
						{...props.input}
						className="form-control"
						readOnly={props.readOnly} >
						{props.removerOptionPadrao ? null : (
							<option value={0} disabled={props.readOnly ? true : false}>{props.placeholder}</option>
						)}
						{props.options.map(opt => {
							return (
								<option disabled={props.readOnly ? true : false}
									key={opt.id}
									value={opt.id}>{opt.valor}</option>
							);
						})}
					</select>
					<span class='input-group-btn'>
						<button type='button' class='btn btn-success btn-flat'
							onClick={props.acaoBotao}
							disabled={props.readOnly == 'readOnly'}>
							{props.acaoBotaoLabel}
						</button>
					</span>
				</div>
			)}
       </div>
   </Grid>
)
