import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';

import { setModoTela, getListaServicoTipo } from './servicoActions';

class ServicoForm extends Component {

    componentWillMount() {
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *' placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='descricao'
							component={LabelAndInput}
							label='Descrição *' placeholder='Informe a Descrição'
							cols='12 12 10 8'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='valor_hora'
							component={LabelAndInputNumber}
							label='R$ Hora' placeholder='0,00'
							cols='6 6 3 3'
							casas={2}
							readOnly={readOnly} />
						<Field
							name='valor_km'
							component={LabelAndInputNumber}
							label='R$ Km' placeholder='0,00'
							cols='6 6 3 3'
							casas={2}
							readOnly={readOnly} />
						<Field
							name='valor_diaria'
							component={LabelAndInputNumber}
							label='R$ Diária' placeholder='0,00'
							cols='6 6 3 3'
							casas={2}
							readOnly={readOnly} />
						<Field
							name='valor_unitario'
							component={LabelAndInputNumber}
							label='R$ Unitário' placeholder='0,00'
							cols='6 6 3 3'
							casas={2}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

ServicoForm = reduxForm({form: 'servicoForm', destroyOnUnmount: false})(ServicoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.servico.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ServicoForm);
