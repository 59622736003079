import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	filtro: {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataInicioMesAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null,
		id_equipamento: null
	},
	lista: [],
	listaTanque: [],
	listaUsuario: [],
	listaCombustivel: [],
	listaBomba: [],
	listaFornecedor: [],
	listaEquipamento: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'COMBUSTIVEL_SAIDA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		case 'COMBUSTIVEL_SAIDA_FILTRO':
			return { ...state, filtro: action.payload };
        case 'COMBUSTIVEL_SAIDA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'COMBUSTIVEL_SAIDA_TANQUE_SELECT_LISTADO':
            return { ...state, listaTanque: action.payload.data };
		case 'COMBUSTIVEL_SAIDA_COMBUSTIVEL_SELECT_LISTADO':
            return { ...state, listaCombustivel: action.payload.data };
		case 'COMBUSTIVEL_SAIDA_USUARIO_SELECT_LISTADO':
            return { ...state, listaUsuario: action.payload.data };
		case 'COMBUSTIVEL_SAIDA_BOMBA_SELECT_LISTADO':
            return { ...state, listaBomba: action.payload.data };
		case 'COMBUSTIVEL_SAIDA_FORNECEDOR_SELECT_LISTADO':
            return { ...state, listaFornecedor: action.payload.data };
		case 'COMBUSTIVEL_SAIDA_EQUIPAMENTO_SELECT_LISTADO':
            return { ...state, listaEquipamento: action.payload.data };
        default:
            return state;
    }
}
