import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
	return (dispatch, getState) => {
		dispatch({
			type: 'COMBUSTIVEL_SAIDA_MODO_TELA',
			payload: {
				modo: modo,
				registro: {
					...registro,
					id_operador: registro.id_operador || getState().auth.usuario.id,
					id_responsavel: registro.id_responsavel || getState().auth.usuario.id
				}
			}
		});
	}
}

export function setFiltro(filtro) {
    return {
        type: 'COMBUSTIVEL_SAIDA_FILTRO',
        payload: filtro
    };
}

export function initForm(registro = { datahora_saida: DateFormat.getDataHoraAtual() }, formatarData = true) {
	return (dispatch, getState) => {
		dispatch([
	        initialize('combustivelSaidaForm', {
				...registro,
				interno: registro.interno == null ? (registro.id_fornecedor ? false : true) : registro.interno,
				datahora_saida: formatarData ? (registro.datahora_saida ? DateFormat.formatarDataHoraSqlParaTela(registro.datahora_saida) : null) : registro.datahora_saida,
				id_operador: registro.id_operador || getState().auth.usuario.id,
				id_responsavel: registro.id_responsavel || getState().auth.usuario.id
			})
	    ]);
	}
}

export function getLista(id_empresa) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/combustivelSaida?id_empresa=${id_empresa}
			&data_inicial=${DateFormat.formatarDataTelaParaParametro(getState().combustivelSaida.filtro.dataInicial)}
			&data_final=${DateFormat.formatarDataTelaParaParametro(getState().combustivelSaida.filtro.dataFinal)}
			&id_usuario=${getState().combustivelSaida.filtro.id_usuario}
			&id_equipamento=${getState().combustivelSaida.filtro.id_equipamento}`)
		.then(resp => {
            dispatch({
				type: 'COMBUSTIVEL_SAIDA_LISTADO',
		        payload: resp
			});
        }).catch(ex => {

		});
    };
}

export function getListaTanque(id_empresa) {
    const request = axios.get(`${consts.API_URL}/tanque/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_SAIDA_TANQUE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaBomba(id_empresa) {
    const request = axios.get(`${consts.API_URL}/bomba/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_SAIDA_BOMBA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCombustivel(id_empresa) {
    const request = axios.get(`${consts.API_URL}/combustivel/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_SAIDA_COMBUSTIVEL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEquipamento(id_empresa) {
    const request = axios.get(`${consts.API_URL}/equipamento/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_SAIDA_EQUIPAMENTO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/fornecedor/listarselect?id_empresa=${id_empresa}&combustivel=true`);
    return {
        type: 'COMBUSTIVEL_SAIDA_FORNECEDOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUsuario(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMBUSTIVEL_SAIDA_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (registro.id_equipamento && registro.id_equipamento != 0) {
			let equipamento = getState().combustivelSaida.listaEquipamento.filter(equipamento => equipamento.id == registro.id_equipamento)[0];
			if (equipamento && equipamento.capacidade_tanque > 0 && equipamento.capacidade_tanque < parseFloat(registro.quantidade.replace(',', '.'))) {
				setError('A QUANTIDADE excedeu a capacidade do tanque do veículo.');
				return;
			}
		}

        axios.post(`${consts.API_URL}/combustivelSaida`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_saida: registro.datahora_saida ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_saida) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/combustivelSaida?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_empresa));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
