import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioTipoReducer from '../usuarioTipo/usuarioTipoReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import FornecedorReducer from '../fornecedor/fornecedorReducer';
import ClienteReducer from '../cliente/clienteReducer';
import ProdutoReducer from '../produto/produtoReducer';
import ProdutoCategoriaReducer from '../produtoCategoria/produtoCategoriaReducer';
import ProdutoMarcaReducer from '../produtoMarca/produtoMarcaReducer';
import UnidadeMedidaReducer from '../unidadeMedida/unidadeMedidaReducer';
import EspecieReducer from '../especie/especieReducer';
import EquipamentoReducer from '../equipamento/equipamentoReducer';
import ServicoReducer from '../servico/servicoReducer';
import CombustivelReducer from '../combustivel/combustivelReducer';
import PneuReducer from '../pneu/pneuReducer';
import PneuDimensaoReducer from '../pneuDimensao/pneuDimensaoReducer';
import PneuDesenhoReducer from '../pneuDesenho/pneuDesenhoReducer';
import PneuTipoReducer from '../pneuTipo/pneuTipoReducer';
import PneuMarcaReducer from '../pneuMarca/pneuMarcaReducer';
import PneuControleReducer from '../pneuControle/pneuControleReducer';
import ManutencaoReducer from '../manutencao/manutencaoReducer';
import TanqueReducer from '../tanque/tanqueReducer';
import CombustivelEntradaReducer from '../combustivelEntrada/combustivelEntradaReducer';
import CombustivelAjusteReducer from '../combustivelAjuste/combustivelAjusteReducer';
import CombustivelTransferenciaReducer from '../combustivelTransferencia/combustivelTransferenciaReducer';
import CombustivelSaidaReducer from '../combustivelSaida/combustivelSaidaReducer';
import EntradaReducer from '../entrada/entradaReducer';
import OrdemServicoReducer from '../ordemServico/ordemServicoReducer';
import OrdemServicoFechamentoReducer from '../ordemServicoFechamento/ordemServicoFechamentoReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuarioTipo: UsuarioTipoReducer,
    usuario: UsuarioReducer,
	fornecedor: FornecedorReducer,
	cliente: ClienteReducer,
	produto: ProdutoReducer,
	produtoCategoria: ProdutoCategoriaReducer,
	produtoMarca: ProdutoMarcaReducer,
	unidadeMedida: UnidadeMedidaReducer,
	especie: EspecieReducer,
	equipamento: EquipamentoReducer,
	servico: ServicoReducer,
	combustivel: CombustivelReducer,
	pneu: PneuReducer,
	pneuDimensao: PneuDimensaoReducer,
	pneuDesenho: PneuDesenhoReducer,
	pneuTipo: PneuTipoReducer,
	pneuMarca: PneuMarcaReducer,
	pneuControle: PneuControleReducer,
	tanque: TanqueReducer,
	manutencao: ManutencaoReducer,
	combustivelEntrada: CombustivelEntradaReducer,
	combustivelAjuste: CombustivelAjusteReducer,
	combustivelTransferencia: CombustivelTransferenciaReducer,
	combustivelSaida: CombustivelSaidaReducer,
	entrada: EntradaReducer,
	ordemServico: OrdemServicoReducer,
	ordemServicoFechamento: OrdemServicoFechamentoReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
