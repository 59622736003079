import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './fornecedorForm';

import { setModoTela, initForm, salvar, excluir, getLista, getListaEstado, getListaCidade } from './fornecedorActions';

class Fornecedor extends Component {

	state = {
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista(this.props.sessao.id_empresa);
		this.props.getListaEstado();
		this.props.getListaCidade();
    }

    render() {
        return (
            <div>
                <ContentHeader title='Fornecedor' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>

				<input className='form-control'
	                 placeholder='Pesquisar'
	                 type='text'
					 value={this.state.pesquisar}
					 onChange={e => {
						 this.setState({ ...this.state, pesquisar: e.target.value });
					 }} />

				<Table>
					<THead>
						<Tr>
							<Th>Razão Social</Th>
							<Th>Nome Fantasia</Th>
							<Th>CPF / CNPJ</Th>
							<Th>IE</Th>
							<Th>Cidade</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.lista.filter(item => {
							return !this.state.pesquisar
								|| (`${item.razao_social} ${item.nome_fantasia} ${item.cnpj} ${item.cpf} ${item.inscricao_estadual} ${item.nome_cidade}`).toUpperCase().includes(this.state.pesquisar.toUpperCase());
						}).map(item => (
							<Tr key={item.id}>
								<Td>{item.razao_social}</Td>
								<Td>{item.nome_fantasia}</Td>
								<Td>{
									item.cnpj && item.cnpj.length == 14 ? (
										item.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5')
									) : item.cpf && item.cpf.length == 11 ? (
										item.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
									) : item.cnpj || item.cpf}
								</Td>
								<Td>{item.inscricao_estadual}</Td>
								<Td>{item.nome_cidade}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', item);
											this.props.initForm(item);
										}} />
									<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', item);
											this.props.initForm(item);
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.fornecedor.modoTela,
	lista: state.fornecedor.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaEstado, getListaCidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Fornecedor);
