import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PNEU_CONTROLE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setModoTelaEquipamento(modo) {
    return {
        type: 'PNEU_CONTROLE_MODO_TELA_EQUIPAMENTO',
        payload: {
			modo: modo
		}
    };
}

export function initForm(registro = { hodometro: false, horimetro: false }) {
    return [
        initialize('pneuControleForm', registro)
    ];
}

export function getLista(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/pneuControle?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'PNEU_CONTROLE_LISTADO',
        payload: request
    };
}

export function getListaEquipamento(id_empresa) {
    const request = axios.get(`${consts.API_URL}/equipamento?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_CONTROLE_EQUIPAMENTO_LISTADO',
        payload: request
    };
}

export function getListaPneu(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneu?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_CONTROLE_PNEU_LISTADO',
        payload: request
    };
}

export function getListaEquipamentoEixo(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/equipamentoEixo?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'PNEU_CONTROLE_EIXO_LISTADO',
        payload: request
    };
}

export function getListaEquipamentoEixoPneu(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/equipamentoEixoPneu?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'PNEU_CONTROLE_EIXO_PNEU_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuControle`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa, registro.id_equipamento));
			dispatch(getListaPneu(getState().auth.usuario.id_empresa));
			dispatch(setModoTelaEquipamento('estrutura'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/pneuControle?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa, registro.id_equipamento));
			dispatch(getListaPneu(getState().auth.usuario.id_empresa));
			dispatch(setModoTelaEquipamento('estrutura'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/fornecedor/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_CONTROLE_FORNECEDOR_SELECT_LISTADO',
        payload: request
    };
}

export function salvarFornecedor(registro, formulario, tipo = 'esteira') {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/fornecedor`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaFornecedor(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				if (tipo == 'esteira') {
					dispatch(initFormEsteira({
						...formulario,
						id_fornecedor: resp.data
					}));
				} else if (tipo == 'esteira-manutencao') {
					// dispatch(initFormEsteiraManutencao({
					// 	...formulario,
					// 	id_marca: resp.data
					// }));
				} else if (tipo == 'rolete'){
					// dispatch(initFormRolete({
					// 	...formulario,
					// 	id_marca: resp.data
					// }));
				}
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaMarca(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pneuMarca/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PNEU_CONTROLE_MARCA_SELECT_LISTADO',
        payload: request
    };
}

export function salvarMarca(registro, formulario, tipo = 'esteira') {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pneuMarca`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaMarca(getState().auth.usuario.id_empresa));
			if (!registro.id) {
				if (tipo == 'esteira') {
					dispatch(initFormEsteira({
						...formulario,
						id_marca: resp.data
					}));
				} else if (tipo == 'esteira-manutencao') {
					// dispatch(initFormEsteiraManutencao({
					// 	...formulario,
					// 	id_marca: resp.data
					// }));
				} else if (tipo == 'rolete'){
					// dispatch(initFormRolete({
					// 	...formulario,
					// 	id_marca: resp.data
					// }));
				}
			}
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Esteira
export function setModoEsteira(modo, registro = {}) {
    return {
        type: 'PNEU_CONTROLE_ESTEIRA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormEsteira(registro = {}) {
    return [
        initialize('pneuControleEsteiraForm', {
			...registro,
			datahora_inicial: registro.datahora_inicial ? DateFormat.formatarDataSqlParaTela(registro.datahora_inicial) : null,
			datahora_final: registro.datahora_final ? DateFormat.formatarDataSqlParaTela(registro.datahora_final) : null,
			valor: String(registro.valor || 0).replace('.', ',')
		})
    ];
}

export function salvarEsteira(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/esteira`, {
			...registro,
			lado: registro.lado == '0' ? null : registro.lado,
			id_fornecedor: registro.id_fornecedor == '0' ? null : registro.id_fornecedor,
			id_marca: registro.id_marca == '0' ? null : registro.id_marca,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEsteira(getState().auth.usuario.id_empresa, registro.id_equipamento));
			dispatch(setModoEsteira('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirEsteira(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/esteira?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEsteira(registro.id_empresa, registro.id_equipamento));
			dispatch(setModoEsteira('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEsteira(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/esteira?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'PNEU_CONTROLE_ESTEIRA_LISTADO',
        payload: request
    };
}

//Esteira manutencao
export function setModoEsteiraManutencao(modo, registro = {}) {
    return {
        type: 'PNEU_CONTROLE_ESTEIRA_MANUTENCAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormEsteiraManutencao(registro = {}) {
    return [
        initialize('pneuControleEsteiraManutencaoForm', {
			...registro,
			datahora_inicial: registro.datahora_inicial ? DateFormat.formatarDataSqlParaTela(registro.datahora_inicial) : null,
			datahora_final: registro.datahora_final ? DateFormat.formatarDataSqlParaTela(registro.datahora_final) : null,
			valor: String(registro.valor || 0).replace('.', ',')
		})
    ];
}

export function salvarEsteiraManutencao(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/esteiraManutencao`, {
			...registro,
			id_esteira: registro.id_esteira == '0' ? null : registro.id_esteira,
			id_fornecedor: registro.id_fornecedor == '0' ? null : registro.id_fornecedor,
			id_marca: registro.id_marca == '0' ? null : registro.id_marca,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEsteiraManutencao(getState().auth.usuario.id_empresa, registro.id_equipamento));
			dispatch(setModoEsteiraManutencao('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirEsteiraManutencao(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/esteiraManutencao?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEsteiraManutencao(registro.id_empresa, registro.id_equipamento));
			dispatch(setModoEsteiraManutencao('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEsteiraManutencao(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/esteiraManutencao?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'PNEU_CONTROLE_ESTEIRA_MANUTENCAO_LISTADO',
        payload: request
    };
}

//Rolete
export function setModoRolete(modo, registro = {}) {
    return {
        type: 'PNEU_CONTROLE_ROLETE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function initFormRolete(registro = {}) {
    return [
        initialize('pneuControleRoleteForm', {
			...registro,
			datahora_inicial: registro.datahora_inicial ? DateFormat.formatarDataSqlParaTela(registro.datahora_inicial) : null,
			datahora_final: registro.datahora_final ? DateFormat.formatarDataSqlParaTela(registro.datahora_final) : null,
			valor: String(registro.valor || 0).replace('.', ',')
		})
    ];
}

export function salvarRolete(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/rolete`, {
			...registro,
			lado: registro.lado == '0' ? null : registro.lado,
			posicao: registro.posicao == '0' ? null : registro.posicao,
			ordem: registro.ordem == '0' ? null : registro.ordem,
			id_fornecedor: registro.id_fornecedor == '0' ? null : registro.id_fornecedor,
			id_marca: registro.id_marca == '0' ? null : registro.id_marca,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaRolete(getState().auth.usuario.id_empresa, registro.id_equipamento));
			dispatch(setModoRolete('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluirRolete(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/rolete?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaRolete(registro.id_empresa, registro.id_equipamento));
			dispatch(setModoRolete('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaRolete(id_empresa, id_equipamento) {
    const request = axios.get(`${consts.API_URL}/rolete?id_empresa=${id_empresa}&id_equipamento=${id_equipamento}`);
    return {
        type: 'PNEU_CONTROLE_ROLETE_LISTADO',
        payload: request
    };
}
