import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './ordemServicoForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';

import { setModoTela, initForm, salvar, excluir, getLista, setFiltro, getListaUsuario, getListaEquipamento, getListaServico, getListaCliente } from './ordemServicoActions';

class OrdemServico extends Component {

    componentWillMount() {
		this.props.getLista(this.props.sessao.id_empresa);
		this.props.getListaUsuario(this.props.sessao.id_empresa);
		this.props.getListaEquipamento(this.props.sessao.id_empresa);
		this.props.getListaServico(this.props.sessao.id_empresa);
		this.props.getListaCliente(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Ordem de Serviço' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						style={{ position: 'fixed' }}
						event={() => {
							this.props.setModoTela('cadastro', {});
							this.props.initForm();
						}} />
				</BoxButtonHeader>
				<Row>
					<LabelAndInputMask
						label='Data Inicial'
						placeholder='Informe a Data Inicial'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataInicial}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataInicial: data.target.value
							});
							console.log(this.props.filtro);
							this.props.getLista(this.props.sessao.id_empresa);
						}} />
					<LabelAndInputMask
						label='Data Final'
						placeholder='Informe a Data Final'
						mask={'99/99/9999'}
						cols='12 4 3'
						value={this.props.filtro.dataFinal}
						onChange={data => {
							this.props.setFiltro({
								...this.props.filtro,
								dataFinal: data.target.value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />

					<Select
						name='id_cliente'
						options={this.props.listaCliente}
						label='Cliente *'
						cols='12 4 3'
						placeholder='Selecione o Cliente'
						value={this.props.filtro.id_cliente}
						onChange={value => {
							this.props.setFiltro({
								...this.props.filtro,
								id_cliente: value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />

					<Select
						name='id_usuario'
						options={this.props.listaUsuario}
						label='Usuário *'
						cols='12 4 3'
						placeholder='Selecione o Usuário'
						value={this.props.filtro.id_usuario}
						onChange={value => {
							this.props.setFiltro({
								...this.props.filtro,
								id_usuario: value
							});
							this.props.getLista(this.props.sessao.id_empresa);
						}} />
				</Row>
				<Table>
					<THead>
						<Tr>
							<Th>Agendamento</Th>
							<Th>Status</Th>
							<Th>Cliente</Th>
							<Th>Operador</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{(this.props.lista).map(item => (
							<Tr key={item.id}>
								<Td>{DateFormat.formatarDataSqlParaTela(item.data_agendamento)}</Td>
								<Td>{
									item.datahora_cancelamento ? 'CANCELADA' : (
										item.id_fechamento ? 'FECHAMENTO CLIENTE' : (
											item.datahora_fechamento ? 'ENCERRADO' : (
												item.datahora_fim ? 'SERVIÇO FINALIZADO' : (
													item.datahora_inicio ? 'EXECUTANDO' : 'AGENDADO'
												)
											)
										)
									)
								}</Td>
								<Td>{item.cliente_nome}</Td>
								<Td>{item.nome_usuario_operador}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', {
												...item
											});
											this.props.initForm({
												...item
											});
										}} />
									{this.props.usuarioCarregado && this.props.usuarioCarregado.permissao_cadastro ? (
										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													hodometro: String(item.hodometro || 0).replace('.', ','),
													horimetro: String(item.horimetro || 0).replace('.', ',')
												});
												this.props.initForm({
													...item,
													hodometro: String(item.hodometro || 0).replace('.', ','),
													horimetro: String(item.horimetro || 0).replace('.', ',')
												});
											}} />
									) : null}
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.ordemServico.modoTela,
	lista: state.ordemServico.lista,
	listaUsuario: state.ordemServico.listaUsuario,
	listaCliente: state.ordemServico.listaCliente,
	filtro: state.ordemServico.filtro,
	usuarioCarregado: (state.usuario.lista || []).filter(item => item.id == state.auth.usuario.id)[0]
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, setFiltro, getListaUsuario, getListaEquipamento, getListaServico, getListaCliente }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(OrdemServico);
